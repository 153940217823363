import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { Table, TableBody, TableHead, Tooltip } from '@mui/material';

import { PlanDetails } from '../../auth/presentation/upgrade-plans/upgrade-plans/plans-list';
import {
  EmptyCell,
  MobileModuleNameCell,
  MobilePlanFunctionalityCell,
  MobilePlanTableRow,
  ModuleNameCell,
  NotAvaliableIcon,
  PlanFunctionalityCell,
  PlanHeaderNameCell,
  PlanTableCell,
  PlanTableContainer,
  PlanTableRow,
  PlanTooltipContainer,
  PlanTooltipIcon,
  SpacerCell,
  TableHeaderRow,
} from './plan-details-table-styles';

type PlansTableProps = {
  isLoading?: boolean;
  plansData: PlanDetails[];
};

const getFunctionalityStatus = (plan: PlanDetails, functionalityName: string) => {
  const functionality = plan.functionalities.find((func) => func.name === functionalityName);
  if (functionality?.description) return functionality.description;
  return functionality?.active ? <CheckIcon /> : <NotAvaliableIcon />;
};

const getModuleFunctionalities = (plan: PlanDetails, functionalityName: string, index: number) => {
  const module = plan.module && plan.module[index];
  if (module) {
    const functionality = module.functionalities.find((func) => func.name === functionalityName);
    if (functionality?.description) return functionality.description;
    return functionality?.active ? <CheckIcon /> : <NotAvaliableIcon />;
  }
  return '';
};

const getModuleFunctionalitiesSubFunction = (
  plan: PlanDetails,
  functionalityName: string,
  index: number,
  subFuncName: string
) => {
  const module = plan.module && plan.module[index];
  if (module) {
    const item = module.functionalities.find((func) => func.name === functionalityName);
    const functionality = item?.options?.items.find((func) => func.name === subFuncName);
    if (functionality?.description) return functionality.description;
    return functionality?.active ? <CheckIcon /> : <NotAvaliableIcon />;
  }
  return '';
};

const findPlanWithMostFunctionalities = (plans: PlanDetails[]): number => {
  let maxFunctionalityCount = 0;
  let planIndex = 0;

  for (let i = 0; i < plans.length; i++) {
    const functionalityCount = plans[i].functionalities.length;
    if (functionalityCount > maxFunctionalityCount) {
      maxFunctionalityCount = functionalityCount;
      planIndex = i;
    }
  }

  return planIndex;
};

export default function PlanDetailsTable({ isLoading, plansData }: PlansTableProps) {
  const { t } = useTranslation('components');
  const plans = plansData.filter((plan) => !plan.disableColumn);
  const planIndex = findPlanWithMostFunctionalities(plans);
  let rowColorCounter = 0;

  return (
    <PlanTableContainer sx={{ display: isLoading ? 'none' : 'flex' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableHeaderRow>
            <EmptyCell />
            {plans.map((plan, index) => (
              <React.Fragment key={plan.name}>
                <PlanHeaderNameCell backgroundColor={plan.currentPlan ? '#6A6A6A' : plan.color}>
                  {plan.name}
                </PlanHeaderNameCell>
                {index !== plans.length - 1 && <SpacerCell />}
              </React.Fragment>
            ))}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {plans[0].module.map((module, moduleIndex) => (
            <React.Fragment key={`module-${moduleIndex}`}>
              <PlanTableRow>
                <ModuleNameCell component="th" scope="row">
                  {module.name}
                </ModuleNameCell>
                <MobileModuleNameCell component="th" scope="row" colSpan={3}>
                  {module.name}
                </MobileModuleNameCell>
                {plans.map((plan, index) => (
                  <React.Fragment key={plan.name}>
                    <PlanTableCell borderColor={plan.color}></PlanTableCell>
                    {index !== plans.length - 1 && <SpacerCell />}
                  </React.Fragment>
                ))}
              </PlanTableRow>
              {plans[planIndex].module[moduleIndex].functionalities.map(
                (moduleFunc, moduleFuncIndex) => (
                  <React.Fragment key={moduleFunc.name}>
                    <MobilePlanTableRow>
                      <MobilePlanFunctionalityCell colSpan={3} component="th" scope="row">
                        <PlanTooltipContainer>
                          {moduleFunc.name}
                          {moduleFunc.helperText && !moduleFunc.options?.subsection && (
                            <Tooltip enterTouchDelay={50} title={moduleFunc.helperText} placement="bottom">
                              <PlanTooltipIcon />
                            </Tooltip>
                          )}
                        </PlanTooltipContainer>
                      </MobilePlanFunctionalityCell>
                    </MobilePlanTableRow>

                    {moduleFunc.options?.subsection ? (
                      <>
                        <PlanTableRow>
                          <PlanFunctionalityCell
                            component="th"
                            scope="row"
                            backgroundColor={rowColorCounter++ % 2 === 0 ? '#FFFFFF' : '#EFEFEF'}
                          >
                            <PlanTooltipContainer>
                              {moduleFunc.name}
                              {moduleFunc.helperText && (
                                <Tooltip enterTouchDelay={50} title={moduleFunc.helperText} placement="right-start">
                                  <PlanTooltipIcon />
                                </Tooltip>
                              )}
                            </PlanTooltipContainer>
                          </PlanFunctionalityCell>
                          {plans.map((plan, index) => (
                            <React.Fragment key={plan.name}>
                              <PlanTableCell 
                                backgroundColor={rowColorCounter % 2 === 0 ? '#EFEFEF' : '#FFFFFF' }
                                borderColor={plan.color}
                              ></PlanTableCell>
                              {index !== plans.length - 1 && <SpacerCell />}
                            </React.Fragment>
                          ))}
                        </PlanTableRow>

                        {moduleFunc.options?.items.map((subFunc, subFuncIndex) => (
                          <PlanTableRow key={subFunc.name}>
                            <PlanFunctionalityCell
                              backgroundColor={rowColorCounter++ % 2 === 0 ? '#FFFFFF' : '#EFEFEF'}
                              component="th"
                              scope="row"
                              paddingLeft={32}
                            >
                              <PlanTooltipContainer>
                                - {subFunc.name}
                                {subFunc.helperText && (
                                  <Tooltip enterTouchDelay={50} title={subFunc.helperText} placement="right-start">
                                    <PlanTooltipIcon />
                                  </Tooltip>
                                )}
                              </PlanTooltipContainer>
                            </PlanFunctionalityCell>
                            {plans.map((plan, index) => (
                              <React.Fragment key={plan.name}>
                                <PlanTableCell
                                  backgroundColor={rowColorCounter % 2 === 0 ? '#EFEFEF' : '#FFFFFF'}
                                  borderColor={plan.color}
                                >
                                  {getModuleFunctionalitiesSubFunction(
                                    plan,
                                    moduleFunc.name,
                                    moduleIndex,
                                    subFunc.name
                                  )}
                                </PlanTableCell>
                                {index !== plans.length - 1 && <SpacerCell />}
                              </React.Fragment>
                            ))}
                          </PlanTableRow>
                        ))}
                      </>
                    ) : (
                      <PlanTableRow key={moduleFunc.name}>
                        <PlanFunctionalityCell
                          backgroundColor={rowColorCounter++ % 2 === 0 ? '#FFFFFF' : '#EFEFEF'}
                          component="th"
                          scope="row"
                        >
                        <PlanTooltipContainer>
                            {moduleFunc.name}
                            {moduleFunc.helperText && (
                              <Tooltip enterTouchDelay={50} title={moduleFunc.helperText} placement="right-start">
                                <PlanTooltipIcon />
                              </Tooltip>
                            )}
                          </PlanTooltipContainer>
                        </PlanFunctionalityCell>
                        {plans.map((plan, index) => (
                          <React.Fragment key={plan.name}>
                            <PlanTableCell
                              backgroundColor={rowColorCounter % 2 === 0 ? '#EFEFEF' : '#FFFFFF'}
                              borderColor={plan.color}
                              isLastItem={plans[planIndex].module[plans[planIndex].module.length - 1].functionalities.length - 1 === moduleFuncIndex}
                            >
                              {getModuleFunctionalities(plan, moduleFunc.name, moduleIndex)}
                            </PlanTableCell>
                            {index !== plans.length - 1 && <SpacerCell />}
                          </React.Fragment>
                        ))}
                      </PlanTableRow>
                    )}
                  </React.Fragment>
                )
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </PlanTableContainer>
  );
}
