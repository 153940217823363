import { useFlag } from '@unleash/proxy-client-react';

interface FeatureFlagsHook {
  isFlagEnabled: (flagName: string) => boolean;
}

export const useFeatureFlags = (): FeatureFlagsHook => {
  return {
    isFlagEnabled: (flagName: string) => {
      return useFlag(flagName);
    }
  };
};
