import { parse } from 'papaparse';

export const csvParser = <T>(data: string): T[] => {
  let rows = [];
  parse<T>(data, {
    header: true,
    complete: (results) => {
      rows = results.data;
    }
  });
  return rows;
};
