import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import PresentationMenu from '~/app/application/features/components/presentation-menu/presentation-menu';

import {
  Container,
  ContainerText,
  Container404,
  TextItalic,
  TextMessage,
  TextTitle,
  ImageContainer,
  AlignCenterBox
} from './error-404-styles';

const Images: { [k: number]: { image: string; translation: string } } = {
  1: {
    image: '/assets/images/image-404-error-confused-man.svg',
    translation: 'wrongRoad'
  },
  2: {
    image: '/assets/images/image-404-error-person-fined.svg',
    translation: 'brokenHeadlight'
  },
  3: {
    image: '/assets/images/image-404-error-tire.svg',
    translation: 'flatTire'
  }
};

const Error404 = () => {
  const { t } = useTranslation(['error-404']);
  const imageIndex = useMemo(() => Math.floor(Math.random() * 3) + 1, []);
  return (
    <Container>
      <PresentationMenu />
      <AlignCenterBox>
        <Container404>
          <ContainerText>
            <TextTitle>{t('oops')}</TextTitle>
            <TextMessage>
              {t(Images[imageIndex].translation)}
              <Trans i18nKey='homePageOrBlog'>
                {t('return')}
                <TextItalic to={'/login'}>{t('homePage')}</TextItalic>
                {t('orCheck')}
                <TextItalic to={'/support'}>{t('support')}</TextItalic>.
              </Trans>
            </TextMessage>
          </ContainerText>
          <ImageContainer>
            <img src={Images[imageIndex].image} />
          </ImageContainer>
        </Container404>
      </AlignCenterBox>
    </Container>
  );
};

export default Error404;
