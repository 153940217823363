import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { CompanyModel } from '../../domain/models/company-model';
import { UserModel } from '../../domain/models/user/user-model';

export class GetOwners implements ServiceCommand<GetOwners.Response> {
  constructor(
    private readonly httpClient: HttpClient<GetOwners.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: GetOwners.Params
  ): Promise<ServiceCommand.Response<GetOwners.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${this.url}/${params.id}/owners`
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace GetOwners {
  export type Params = CompanyModel & { id: string };
  export type Response = UserModel[];
}
