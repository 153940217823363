import { Box, styled } from '@mui/material';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';

export const AddPlatesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  margin: theme.spacing(5, 5, 4)
}));

export const VehicleLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5)
}));

export const CleanVehicleButton = styled(BaseButton)(({ theme }) => ({
  color: '#525252',
  width: theme.typography.pxToRem(100),
  textDecoration: 'underline',
  '&:hover': {
    background: 'none'
  }
}));

export const AddMoreVehiclesButton = styled(BaseButton)(() => ({
  width: 'fit-content',
  '&:hover': {
    background: 'none'
  }
}));

export const AddPlatesButton = styled(BaseButton)(() => ({
  maxWidth: '600px'
}));
