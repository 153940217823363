import { useEffect, useRef } from 'react';
import { redirect, useSearchParams } from 'react-router-dom';

import { GoogleLoginForm } from '../../domain/models/google-models';
import { AuthRoutesPath } from '../../routes/routes';
import { useGoogleLoginMutation } from '../../store/hooks';
import { useLoginLogic } from '../login/login';

const OAuth = () => {
  const [googleLoginMutation, { isLoading }] = useGoogleLoginMutation();
  const called = useRef(false);

  const { signIn } = useLoginLogic<GoogleLoginForm>(
    googleLoginMutation,
    isLoading
  );

  const googleLogin = (values: GoogleLoginForm) => {
    if (called.current) return;
    called.current = true;
    signIn(values);
  };

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  if (!code) redirect(AuthRoutesPath.LOGIN);

  useEffect(() => {
    googleLogin({ code });
  }, [code]);
  return <></>;
};

export default OAuth;
