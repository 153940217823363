import { Box, Typography, styled } from '@mui/material';

export const SuccessBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: theme.spacing(4)
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '80vh',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(4)
}));

export const BackAndLogoBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 20,
  width: '100%',
  justifyContent: 'flex-start'
}));

export const LogoBox = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center'
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  textAlign: 'center',
  fontWeight: 500,
  whiteSpace: 'pre-wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

export const PlatesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `${theme.typography.pxToRem(1)} solid #A3A3A3`,
  borderRadius: theme.typography.pxToRem(8),
  [theme.breakpoints.up('md')]: {
    width: theme.typography.pxToRem(699)
  }
}));

export const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: (theme.typography.fontSize = 20),
  justifyContent: 'center',
  gap: theme.spacing(2),
  maxWidth: '800px',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '90%'
  },
  '& a': {
    color: '#F9401B',
    fontWeight: 'bold'
  }
}));
