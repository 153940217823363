import React, { useEffect } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { TextFieldProps } from '@mui/material';
import BaseTextField from '~/app/core/presentation/components/base-text-field/base-text-field';

export type ExpirationDateTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;

  errorText?: string;
  isLoading?: boolean;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const ExpirationDateMask = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, _) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        mask='00/00'
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        {...other}
      />
    );
  }
);

const ExpirationDateTextField = (props: ExpirationDateTextFieldProps) => {
  const { t } = useTranslation(['components', 'errors']);

  const expiryMask = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const code = (event as any).keyCode;
    const allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event as any).target.value = (event as any).target.value
      .replace(/^([1-9]\/|[2-9])$/g, '0$1/')
      .replace(/^(0[1-9]|1[0-2])$/g, '$1/')
      .replace(/^([0-1])([3-9])$/g, '0$1/$2')
      .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2')
      .replace(/^([0]+)\/|[0]+$/g, '0')
      .replace(/[^\d/]|^[/]*$/g, '')
      .replace(/\/\//g, '/');
  };

  const splitDate = (domobj, value) => {
    const regExp = /(1[0-2]|0[1-9]|\d)\/(20\d{2}|19\d{2}|0(?!0)\d|[1-9]\d)/;
    const matches = regExp.exec(value);
    domobj.siblings('input[name$="expiryMonth"]').val(matches[1]);
    domobj.siblings('input[name$="expiryYear"]').val(matches[2]);
  };

  useEffect(() => {
    const inputElement = document.getElementById('expirationDate');
    inputElement.addEventListener('keyup', expiryMask);
    inputElement.addEventListener('focusout', () =>
      splitDate(inputElement, inputElement['value'])
    );

    return () => {
      inputElement.removeEventListener('keyup', expiryMask);
      inputElement.removeEventListener('focusout', () =>
        splitDate(inputElement, inputElement['value'])
      );
    };
  }, []);

  return (
    <Controller
      name='expirationDate'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseTextField
          onChange={onChange}
          value={value || ''}
          error={!!error}
          helperText={
            error
              ? props.errorText ?? t('errors:expirationDateInvalid')
              : undefined
          }
          id='expirationDate'
          name='expirationDate'
          label={t('expirationDate')}
          variant='filled'
          type='expirationDate'
          required
          inputProps={{ maxLength: 5 }} // Format MM/YY
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: ExpirationDateMask as any
          }}
          {...props}
        />
      )}
    />
  );
};

export default ExpirationDateTextField;
