import { Box, Typography, styled } from '@mui/material';

export const VehicleAddManualBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4)
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(4)
}));

export const BackAndLogoBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'flex-start'
}));

export const LogoBox = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center'
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  fontWeight: 500
}));

export const PlatesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '600px',
  border: `${theme.typography.pxToRem(1)} solid #A3A3A3`,
  borderRadius: theme.typography.pxToRem(8),
  // height: theme.typography.pxToRem(300),
  [theme.breakpoints.up('md')]: {
    width: theme.typography.pxToRem(699)
  }
}));
