import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addToast } from '~/app/application/shared/toast/actions/add-toast';
import { ToastType } from '~/app/core/presentation/common/types/toast-types';
import Logo from '~/app/core/presentation/components/logo/logo';

import { AuthRoutesPath } from '../../routes/routes';
import { getStoreEmail } from '../../services/email-login/email-login';
import { useEmailSender } from '../../store/actions/email-sender';
import {
  BoxAlign,
  BoxTop,
  Button,
  Container,
  LeftContent,
  ResendEmail,
  RightContent,
  Text,
  TextEmailSend,
  TextResend
} from './send-email-styles';

const SendEmail = () => {
  const { t } = useTranslation(['send-email']);
  const { sendEmail } = useEmailSender();
  const navigate = useNavigate();

  const redirect = () => {
    navigate(AuthRoutesPath.LOGIN);
  };

  const returnToPreviousPage = () => {
    navigate(AuthRoutesPath.RECOVER_PASSWORD);
  };

  const email = getStoreEmail();

  const handleResend = () => {
    if (email) {
      sendEmail({ email: email }).catch((error) => {
        addToast({
          type: ToastType.ERROR,
          text: error.message
        });
      });
    }
  };

  return (
    <Container>
      <LeftContent>
        <BoxTop onClick={returnToPreviousPage}>
          <ArrowBackIcon />
          <Logo />
        </BoxTop>
        <BoxAlign>
          <TextEmailSend>{t('emailSent')}</TextEmailSend>
          <Text
            dangerouslySetInnerHTML={{ __html: t('sendEmailText', { email }) }}
          />
          <Button onClick={redirect}>{t('backToLogin')}</Button>
        </BoxAlign>
        <ResendEmail>
          <Text>{t('emailNotReceived')}</Text>
          <TextResend onClick={handleResend} to={''}>
            {t('resendEmail')}
          </TextResend>
        </ResendEmail>
      </LeftContent>
      <RightContent>
        <img src='/assets/images/forgot-password.svg' />
      </RightContent>
    </Container>
  );
};

export default SendEmail;
