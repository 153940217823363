import { TextField, styled } from '@mui/material';

export const BaseTextFieldStyle = styled(TextField)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  '&.MuiInputLabel-formControl.MuiInputLabel-animated': {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.primary.main
  },
  '& .MuiInputBase-root.Mui-disabled': {
    color: '#a3a3a3'
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: '#a3a3a3'
  }
}));
