import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextFieldProps } from '@mui/material';
import BaseTextField from '~/app/core/presentation/components/base-text-field/base-text-field';

export type HolderNameTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
};

const HolderNameTextField = (props: HolderNameTextFieldProps) => {
  const { t } = useTranslation(['components']);
  return (
    <Controller
      name='holderName'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const handleInputChange = (event) => {
          const uppercaseValue = event.target.value.toUpperCase();
          onChange(uppercaseValue);
        };

        return (
          <BaseTextField
            onChange={handleInputChange}
            value={value || ''}
            error={!!error}
            helperText={
              error ? props.errorText ?? t('holderNameInvalid') : undefined
            }
            id='holderName'
            name='holderName'
            label={t('holderName')}
            variant='filled'
            type='text'
            inputProps={{ maxLength: 19 }}
            required
          />
        );
      }}
    />
  );
};

export default HolderNameTextField;
