export const sectorValues = [
  {
    value: 'Logística de Distribuição',
    label: 'Logística de Distribuição'
  },
  {
    value: 'Logística de Produção',
    label: 'Logística de Produção'
  },
  {
    value: 'Logística Reversa',
    label: 'Logística Reversa'
  },
  {
    value: 'Logística de Suprimentos',
    label: 'Logística de Suprimentos'
  },
  {
    value: 'Transportadora',
    label: 'Transportadora'
  },
  {
    value: 'Outros',
    label: 'Outros'
  }
];
