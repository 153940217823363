import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip } from '@mui/material';
import { RegisterRoutes } from '~/app/application/features/auth/routes/routes';
import { useRegisterUserDataStore } from '~/app/application/features/auth/store/slice/register-user-data-slice';
import { useSuccessSetting } from '~/app/application/features/auth/store/slice/success-setting-slice';
import { logEvent } from '~/app/application/shared/lib/tag';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';
import { APP_ENV } from '~/env';

import { SignUpFormDataProps } from '../../form-register';
import {
  ManualAddVehiclesButton,
  PlateText,
  PlatesBox,
  ResendVehiclesBox,
  ResendVehiclesButton,
  StatusPlateBox,
  Title,
  VehicleConfirmationBox
} from './vehicle-confirmation-styles';

export type VehicleData = {
  plate: string;
  renavam: string;
  chassis?: string;
  status: 'success' | 'error';
};

type VehicleConfirmationProps = {
  vehicles: VehicleData[];
  resendCallback: () => void;
  manualAddCallback: () => void;
  // handleNextStep: () => void;
  formData: SignUpFormDataProps;
};

const VehicleConfirmation = ({
  vehicles,
  formData,
  resendCallback,
  manualAddCallback
}: VehicleConfirmationProps) => {
  const { registerState, setRegisterState } = useRegisterUserDataStore();
  const { setSuccessSetting } = useSuccessSetting();
  const { t } = useTranslation('register-vehicle-step');
  const { t: tRegister } = useTranslation('register-plan');

  const navigate = useNavigate();

  const handleSubmit = () => {
    const vehiclesData = {
      companyId: formData?.company?._id || registerState.companies[0]._id,
      vehicleList: vehicles
        .map((vehicle) => {
          if (vehicle.status === 'success')
            return {
              carPlate: { main: vehicle.plate },
              renavam: vehicle.renavam,
              chassis: vehicle.chassis
            };
        })
        .filter((vehicle) => vehicle)
    };

    setRegisterState({
      vehiclesCount: vehicles.length,
      vehicles: vehiclesData
    });

    logEvent({
      category: 'confirma_veiculos/trigger',
      action: 'OnSubmit',
      label: 'step05_confirma_veiculos'
    });

    if (registerState.isEnterpriseContact) {
      return setSuccessSetting({
        status: 'loading',
        title: tRegister('success_title'),
        description: tRegister('success_description'),
        redirectLink: APP_ENV.FRONTEND_V1_REDIRECT_URL,
        redirectToAfterTimeout: 3
      });
    }

    return navigate(RegisterRoutes.SELECT_PLAN);
  };

  return (
    <VehicleConfirmationBox>
      <Title>{t('confirmationScreen.title')}</Title>
      <div>{t('confirmationScreen.description')}</div>
      <PlatesBox>
        {vehicles.map((vehicle, index) => {
          return (
            <StatusPlateBox key={index}>
              <span>
                {vehicle.status === 'success' ? (
                  <CheckCircleOutlineIcon color='success' />
                ) : (
                  <ErrorOutlineIcon color='error' />
                )}
              </span>
              {vehicle.status === 'success' ? (
                <PlateText>{vehicle.plate}</PlateText>
              ) : (
                <Tooltip
                  title={t('confirmationScreen.tooltipMessageError')}
                  placement='right'
                >
                  <PlateText>{vehicle.plate}</PlateText>
                </Tooltip>
              )}
            </StatusPlateBox>
          );
        })}
      </PlatesBox>
      <ResendVehiclesBox>
        <ManualAddVehiclesButton
          onClick={manualAddCallback}
          disableFocusRipple
          variant='text'
        >
          {t('confirmationScreen.manualAddVehicles')}
        </ManualAddVehiclesButton>
        <ResendVehiclesButton
          onClick={resendCallback}
          disableFocusRipple
          variant='text'
        >
          {t('confirmationScreen.resendFile')}
        </ResendVehiclesButton>
      </ResendVehiclesBox>
      <BaseButton
        id='confirm-vehicles'
        onClick={handleSubmit}
        disabled={vehicles.some((vehicle) => vehicle.status === 'error')}
      >
        {t('confirmationScreen.next')}
      </BaseButton>
    </VehicleConfirmationBox>
  );
};

export default VehicleConfirmation;
