import { Control, Controller } from 'react-hook-form';

import { FormControl, InputLabel, SelectProps } from '@mui/material';

import { BaseMenuItem, BaseSelectInputStyle } from './base-select-input-styles';

export type BaseSelectInputProps = SelectProps & {
  label: string;
  values: { value: string; label: string }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
};

const BaseSelectInput = (props: BaseSelectInputProps) => {
  return (
    <FormControl variant='filled' fullWidth>
      <InputLabel id={props.labelId}>{`${props.label}${
        props.required ? ' *' : ''
      }`}</InputLabel>
      <Controller
        render={({
          field: { onChange, value, ref },
          fieldState: { error }
        }) => (
          <BaseSelectInputStyle
            onChange={onChange}
            value={value ?? ''}
            error={!!error}
            label={props.label}
            variant='filled'
            type='select'
            inputProps={{ maxLength: 256 }}
            required
            {...props}
          >
            {props.values.map((value, index) => (
              <BaseMenuItem
                ref={ref}
                selected
                key={`${value}-${index}`}
                value={value.value}
              >
                {value.label}
              </BaseMenuItem>
            ))}
          </BaseSelectInputStyle>
        )}
        name={props.name}
        control={props.control}
      />
    </FormControl>
  );
};

export default BaseSelectInput;
