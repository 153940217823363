import { AlertProps } from '@mui/material';

import { BaseAlertStyles } from './base-alert-styles';

type BaseAlertProps = AlertProps;

const BaseAlert = ({ children, ...props }: BaseAlertProps) => {
  return <BaseAlertStyles {...props}>{children}</BaseAlertStyles>;
};

export default BaseAlert;
