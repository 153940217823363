import { Box, Typography, styled } from '@mui/material';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';

export const FormBox = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  background: 'white',
  justifyContent: 'center',
  padding: '20px'
}));

export const BoxTop = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3)
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  width: '100%',
  alignItems: 'center'
}));

export const ContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  maxWidth: theme.spacing(60),
  width: '100%'
}));

export const TextHeader = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 'bold'
}));

export const Button = styled(BaseButton)(({ theme }) => ({
  textTransform: 'none',
  maxWidth: theme.spacing(60),
  marginTop: theme.spacing(15),
  marginBottom: theme.spacing(8)
}));
