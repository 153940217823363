import { Control, Controller } from 'react-hook-form';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps
} from '@mui/material';

import { BaseFormLabel } from './base-radio-button-styles';

export type BaseRadioButtonProps = RadioGroupProps & {
  values: { value: string; label: string | React.ReactNode }[];
  labelid: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  required?: boolean;
  label?: string;
  row?: boolean;
  alignItems?: 'flex-start' | 'center' | 'flex-end';
};

const BaseRadioButton = (props: BaseRadioButtonProps) => {
  return (
    <FormControl>
      {props.label && (
        <BaseFormLabel id={props.labelid}>
          {`${props.label}${props.required ? ' *' : ''}`}
        </BaseFormLabel>
      )}
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            onChange={onChange}
            value={value ?? ''}
            label={props.label}
            {...props}
          >
            {props.values.map((value, index) => (
              <FormControlLabel
                sx={{
                  alignItems: props.alignItems
                }}
                key={`${value}-${index}`}
                value={value.value}
                control={<Radio />}
                label={value.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export default BaseRadioButton;
