import { useTranslation } from 'react-i18next';

import { AuthRoutesPath } from '../../../../routes/routes';
import { Container, TextAccount, TextRegister } from './forms-of-access-styles';

const FormsOfAccess = () => {
  const { t } = useTranslation('login');

  return (
    <Container>
      <TextAccount>
        {t('notHaveAnAccountYet')}
        <TextRegister to={AuthRoutesPath.REGISTER}>
          {t('register')}
        </TextRegister>
      </TextAccount>
    </Container>
  );
};

export default FormsOfAccess;
