import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { isCNPJ } from '~/app/core/validator/cnpj-validator/cnpj-validator';
import { isCPF } from '~/app/core/validator/cpf-validator/cpf-validator';

const cardsBrandsList = ['visa', 'mastercard', 'american_express'];

const schema = z.object({
  cardBrand: z.string().refine((val) => cardsBrandsList.includes(val)),
  cardNumber: z.string().min(19).max(19),
  holderName: z.string().min(2).max(19),
  holderDocument: z.string().min(14).max(18).refine((value) => value.replace(/\D/g, '').length <= 11 ? isCPF(value) : isCNPJ(value)),
  expirationDate: z.string().min(5).max(5),
  price: z.string().min(1),
  cvv: z.string().min(3).max(3)
});

export const PaymentPlanValidator = zodResolver(schema);
