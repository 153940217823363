import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { UpdateMeModel } from '../../domain/models/update-me-model';
import { UpdateMeResponseModel } from '../../domain/models/update-me-response-model';

export class UpdateMe implements ServiceCommand<UpdateMe.Response> {
  constructor(
    private readonly httpClient: HttpClient<UpdateMe.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: UpdateMe.Params
  ): Promise<ServiceCommand.Response<UpdateMe.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.PATCH,
      url: this.url,
      body: params
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace UpdateMe {
  export type Params = UpdateMeModel;

  export type Response = UpdateMeResponseModel;
}
