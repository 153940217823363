import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { isCNPJ } from '~/app/core/validator/cnpj-validator/cnpj-validator';

const schema = z.object({
  cnpj: z.string().refine((value) => isCNPJ(value)),
  companyName: z.string().min(10),
  sector: z.string(),
  address: z.string().min(10),
  phone: z.string().refine(
    (value) => {
      const cleanNumber = value.replace(/\D/g, '');

      if (cleanNumber.length < 10) return false;

      const ddd = cleanNumber.slice(0, 2);

      if (ddd.length === 2 && (Number(ddd[0]) === 0 || Number(ddd[1]) === 0))
        return false;

      if (cleanNumber.length === 11 && Number(cleanNumber[2]) !== 9)
        return false;

      return true;
    },
    { message: 'Invalid phone number' }
  ),
  email: z.string().refine(
    (value) => {
      if (value === '') return true;

      if (value.length > 0) {
        return /\S+@\S+\.\S+/.test(value);
      }
    },
    { message: 'Invalid email' }
  )
});

export const CompanyDataValidator = zodResolver(schema);
