import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthRoutesPath } from '../../../../../routes/routes';
import ModalTermsOfService from '../modal-term-of-service/modal-terms-of-service';
import {
  PersonalDataStepFooterContainer,
  SignInLink,
  TermsOfServiceLink
} from './personal-data-step-footer-styles';

const PersonalDataStepFooter = () => {
  const { t } = useTranslation(['register-personal-step']);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const modalProps = {
    open: modalOpen,
    onClose: closeModal
  };

  return (
    <>
      <ModalTermsOfService {...modalProps} />
      <PersonalDataStepFooterContainer>
        <div>
          {`${t('onClickYouAccept')} `} <br />
          <TermsOfServiceLink onClick={openModal}>
            {t('ourTerms')}
          </TermsOfServiceLink>
        </div>
        <div>
          {`${t('alreadyHaveAccount')} `}
          <SignInLink to={AuthRoutesPath.LOGIN}>{t('login')}</SignInLink>
        </div>
      </PersonalDataStepFooterContainer>
    </>
  );
};

export default PersonalDataStepFooter;
