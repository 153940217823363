import { ServiceCommand } from '../../domain/command/service-command';
import { success, error } from '../../domain/either/either';
import { BlockedUserException } from '../../domain/exceptions/blocked-user-exception';
import { InvalidCredentialsException } from '../../domain/exceptions/invalid-credentials-exception';
import { UnexpectedError } from '../../domain/exceptions/unexpected-error';
import { UserAlreadyExistsException } from '../../domain/exceptions/user-already-exists-exception';
import {
  HttpErrorsType,
  HttpResponse,
  HttpStatusCode
} from '../protocols/http-client';
import { CombinedPredicated, combinedPredicates } from './combined-predicates';

export class RequestResponse<R> {
  private constructor(private readonly _response: R) {
    Object.freeze(this);
  }

  public static handle<R>(
    httpResponse: HttpResponse<R>
  ): ServiceCommand.Response<RequestResponse<R>> {
    const { statusCode, error: httpError } = httpResponse;

    if (this.isSuccess(statusCode)) {
      return success(new RequestResponse(httpResponse.body));
    }

    const predicates: CombinedPredicated<ServiceCommand.ResponseError> = [
      [this.errorTypeInvalidCredentials, new InvalidCredentialsException()],
      [this.errorUserAlreadyExists, new UserAlreadyExistsException()],
      [this.errorBlockedUser, new BlockedUserException()]
    ];

    const errors = combinedPredicates({
      value: Array.isArray(httpError) ? httpError[0].message : httpError,
      predicatePairs: predicates
    });

    if (errors.isError()) {
      return error(errors.value);
    }

    return error(new UnexpectedError());
  }

  private static isSuccess(statusCode: HttpStatusCode): boolean {
    return statusCode >= 200 && statusCode <= 299;
  }

  get response(): R {
    return this._response;
  }

  private static errorTypeInvalidCredentials({
    error
  }: {
    error: HttpErrorsType;
  }): boolean {
    return error === HttpErrorsType.INVALID_CREDENTIALS_EXCEPTION;
  }

  private static errorUserAlreadyExists({
    error
  }: {
    error: HttpErrorsType;
  }): boolean {
    return error === HttpErrorsType.USER_ALREADY_EXISTS_EXCEPTION;
  }

  private static errorBlockedUser({
    error
  }: {
    error: HttpErrorsType;
  }): boolean {
    return error === HttpErrorsType.BLOCKED_USER_EXCEPTION;
  }
}
