export const isCNPJ = (cnpj: string): boolean => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) {
    return false;
  }

  let sum = 0;
  let weight = 5;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj.charAt(i)) * weight;
    weight = weight === 2 ? 9 : weight - 1;
  }
  const digit1 = 11 - (sum % 11);
  const calculatedDigit1 = digit1 >= 10 ? 0 : digit1;

  sum = 0;
  weight = 6;
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj.charAt(i)) * weight;
    weight = weight === 2 ? 9 : weight - 1;
  }
  const digit2 = 11 - (sum % 11);
  const calculatedDigit2 = digit2 >= 10 ? 0 : digit2;

  return (
    parseInt(cnpj.charAt(12)) === calculatedDigit1 &&
    parseInt(cnpj.charAt(13)) === calculatedDigit2
  );
};
