import { create } from 'zustand';

import { TokenModel } from '../../domain/models/token-model';

interface AuthStore {
  authState: TokenModel;
  setToken: (token: TokenModel) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  authState: {} as TokenModel,
  setToken: (token) => set({ authState: token })
}));
