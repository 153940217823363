import { Link } from 'react-router-dom';

const LinkWhatsapp = ({
  countryCode,
  phoneNumber,
  label,
  message
}: {
  countryCode: string;
  phoneNumber: string;
  label: string;
  message?: string;
}) => {
  return (
    <Link
      to='#'
      onClick={(e) => {
        if (message) {
          window.open(
            `https://wa.me/${countryCode}${phoneNumber}?text=${encodeURI(
              message
            )}`,
            '_blank'
          );
          e.preventDefault();
        }
        window.open(`https://wa.me/${countryCode}${phoneNumber}`, '_blank');
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export default LinkWhatsapp;
