import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { CNPJLookupResponse } from '../../domain/models/cnpj-lookup-response.model';
import { CompanyModel } from '../../domain/models/company-model';

export class CNPJLookup implements ServiceCommand<CNPJLookup.Response> {
  constructor(
    private readonly httpClient: HttpClient<CNPJLookup.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: CNPJLookup.Params
  ): Promise<ServiceCommand.Response<CNPJLookup.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${this.url}/${params.cnpj}`
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace CNPJLookup {
  export type Params = CompanyModel;

  export type Response = CNPJLookupResponse;
}
