import { Box, Dialog, styled } from '@mui/material';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';

export const PlansCardsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(4)
  }
}));

export const SelectLaterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2)
}));

export const SelectLaterButton = styled(BaseButton)(({ theme }) => ({
  color: theme.palette.common.black,
  textDecoration: 'underline',
  marginTop: theme.spacing(5),
  maxWidth: theme.spacing(30)
}));

export const PlansDialog = styled(Dialog)(({ theme }) => ({
  backgroundColor: '#fff',
  '& .MuiDialog-paper': {
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    overflowY: 'auto'
  }
}));

export const SecondTitle = styled('h1')(({ theme }) => ({
  marginTop: theme.spacing(7),
  marginBottom: theme.spacing(2)
}));
