import loadable from '@loadable/component';
import { IRoute } from '~/app/main/router/types/router';

import LoginByLink from '../presentation/access-code/login-by-code/login-by-code';
import OAuth from '../presentation/access-google/oauth';
import ForgotPassword from '../presentation/forgot-password/forgot-password';
import ContactSales from '../presentation/register/form-register/select-plan-step/contact-sales/contact-sales';
import PaymentPlan from '../presentation/register/form-register/select-plan-step/payment-plan/payment-plan';
import Register from '../presentation/register/register';
import ResetPassword from '../presentation/reset-password/reset-password';
import SendEmail from '../presentation/send-email/send-email';
import UpgradeContactSales from '../presentation/upgrade-plans/contact-sales/contact-sales';
import UpgradePaymentPlan from '../presentation/upgrade-plans/payment-plan/upgrade-payment-plan';
import UpgradePlan from '../presentation/upgrade-plans/upgrade-plans/upgrade-plan';
import PlansInfoSales from '../presentation/plans-infos-sales/plan-info-sales';

// TODO: need improve it
const Login = loadable(async () => import('../presentation/login/login'), {
  resolveComponenet: (components) => components.Home
});

export enum AuthRoutesPath {
  LOGIN = '/login',
  RECOVER_PASSWORD = '/recover-password',
  RESET_PASSWORD = '/reset-password',
  SEND_EMAIL = '/send-email',
  OAUTH = '/oauth',
  LOGIN_BY_LINK = '/login-by-link',
  REGISTER = '/register'
}

export enum RegisterRoutes {
  PERSONAL_DATA = '/register',
  COMPANY_DATA = '/register/company',
  VEHICLES_DATA = '/register/vehicles',
  SELECT_PLAN = '/register/plans',
  PLAN_PRO = '/register/plans/pro',
  PLAN_ENTERPRISE = '/register/plans/enterprise'
}

export enum UpgradePlanRoutes {
  PLAN_UPGRADE = '/upgrade/plans',
  PLAN_UPGRADE_PRO = '/upgrade/plans/pro',
  PLAN_UPGRADE_ENTERPRISE = '/upgrade/plans/enterprise',
  PLAN_UPGRADE_REDIRECT = '/upgrade/plans/redirect'
}

export enum PlansRoutes {
  PLAN_INFO = '/plans/info',
}

export const authRoutes: IRoute[] = [
  {
    element: <Login />,
    name: 'login',
    path: AuthRoutesPath.LOGIN,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <ForgotPassword />,
    name: 'recoverpassword',
    path: AuthRoutesPath.RECOVER_PASSWORD,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <ResetPassword />,
    name: 'resetpassword',
    path: AuthRoutesPath.RESET_PASSWORD,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <SendEmail />,
    name: 'sendemail',
    path: AuthRoutesPath.SEND_EMAIL,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <OAuth />,
    name: 'oauth',
    path: AuthRoutesPath.OAUTH,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <LoginByLink />,
    name: 'login-by-link',
    path: AuthRoutesPath.LOGIN_BY_LINK,
    layout: 'DefaultLayout',
    private: false
  }
];

export const registerRoutes: IRoute[] = [
  {
    element: <Register pathImage={'/assets/images/computer-register.svg'} />,
    name: 'registerPersonalData',
    path: RegisterRoutes.PERSONAL_DATA,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <Register pathImage={'/assets/images/company-data-step.svg'} />,
    name: 'registerCompanyData',
    path: RegisterRoutes.COMPANY_DATA,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <Register pathImage={'/assets/images/vehicle-step.svg'} />,
    name: 'registerVehiclesData',
    path: RegisterRoutes.VEHICLES_DATA,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <Register pathImage={undefined} />,
    name: 'registerPlansData',
    path: RegisterRoutes.SELECT_PLAN,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <ContactSales />,
    name: 'registerPlansData',
    path: RegisterRoutes.PLAN_ENTERPRISE,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <PaymentPlan />,
    name: 'registerPlansData',
    path: RegisterRoutes.PLAN_PRO,
    layout: 'DefaultLayout',
    private: false
  }
];

export const upgradePlanRoutes: IRoute[] = [
  {
    element: <UpgradePlan />,
    name: 'registerUpgradePlan',
    path: UpgradePlanRoutes.PLAN_UPGRADE,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <UpgradePaymentPlan />,
    name: 'registerUpgradePlan',
    path: UpgradePlanRoutes.PLAN_UPGRADE_PRO,
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <UpgradeContactSales />,
    name: 'registerUpgradePlan',
    path: UpgradePlanRoutes.PLAN_UPGRADE_ENTERPRISE,
    layout: 'DefaultLayout',
    private: false
  }
];

export const plansInfosRoutes: IRoute[] = [
  {
    element: <PlansInfoSales />,
    name: 'plansInfoSales',  
    path: PlansRoutes.PLAN_INFO,
    layout: 'DefaultLayout',
    private: false
  }
];
