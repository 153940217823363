import { ServiceCommand } from '~/app/core/domain/command/service-command';
import { httpClient } from '~/app/core/infra/http/axios-http-client-adapter';

import { AUTH_API_ROUTES } from '../../api/routes';
import { GetSubscriptionByCompanyId } from './get-subscription-by-company-id';

export const getSubscriptionByCompanyIdService: ServiceCommand<GetSubscriptionByCompanyId.Response> =
  new GetSubscriptionByCompanyId(
    httpClient,
    AUTH_API_ROUTES.GET_SUBSCRIPTION_BY_COMPANY_ID
  );

export type { GetSubscriptionByCompanyId };
