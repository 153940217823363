import { Box, styled } from '@mui/material';

export const PaymentResumeContainer = styled(Box)<{
  primary?: boolean;
}>(({ theme, primary }) => ({
  backgroundColor: primary ? 'transparent' : '#f9f9f9',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.typography.pxToRem(24),
  height: '100%',
  gap: theme.typography.pxToRem(10),
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}));

export const PaymentResumeHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 400,
  lineHeight: '150%',
  color: '#525252'
}));

export const PaymentResumePlanDescription = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 400,
  gap: theme.typography.pxToRem(70),
  whiteSpace: 'nowrap',
  lineHeight: '150%'
}));

export const PaymentResumePlanExtra = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 400,
  letterSpacing: theme.typography.pxToRem(-0.42),
  lineHeight: '150%',
  marginBottom: theme.typography.pxToRem(30),
  color: '#525252'
}));

export const PaymentResumeTotalBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 700,
  gap: theme.typography.pxToRem(185),
  marginTop: theme.typography.pxToRem(14),
  whiteSpace: 'nowrap',
  lineHeight: '150%'
}));
