import { useEffect } from 'react';
import { redirect, useSearchParams } from 'react-router-dom';

import { LoginByLinkForm } from '../../../domain/models/login-by-link.model';
import { AuthRoutesPath } from '../../../routes/routes';
import { useLoginByLinkMutation } from '../../../store/hooks';
import { useLoginLogic } from '../../login/login';

const LoginByLink = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  if (!token) redirect(AuthRoutesPath.LOGIN);
  const [loginByLinkMutation, { isLoading }] = useLoginByLinkMutation();

  const { signIn } = useLoginLogic<LoginByLinkForm>(
    loginByLinkMutation,
    isLoading
  );

  useEffect(() => {
    signIn({ token });
  }, [token]);
  return <></>;
};

export default LoginByLink;
