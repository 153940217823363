import { ServiceCommand } from '~/app/core/domain/command/service-command';
import { authHttpClient } from '~/app/core/infra/http/axios-auth-http-client-adapter';

import { AUTH_API_ROUTES } from '../../api/routes';
import { UpdateMe } from './update-me';

export const updateMeService: ServiceCommand<UpdateMe.Response> = new UpdateMe(
  authHttpClient,
  AUTH_API_ROUTES.UPDATE_ME
);

export type { UpdateMe };
