import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';
import EmailTextField from '~/app/application/features/components/email-text-field/email-text-field';
import WhatsappTextField from '~/app/application/features/components/whatsapp-text-field/whatsapp-text-field';

import { useSendEmailLinkFlowMutation } from '../../../../store/hooks';
import { useRegisterUserDataStore } from '../../../../store/slice/register-user-data-slice';
import {
  ContainerBox,
  FormBox,
  Button,
  TextHeader,
  HeaderBox
} from './send-whatsapp-or-email-styles';

interface Props {
  goBack: () => void;
  close: () => void;
  sendMethod: 'email' | 'whatsapp';
}

interface PropsForm {
  email?: string;
  whatsapp?: string;
}

const SendWhatsappOrEmail: React.FC<Props> = ({
  goBack,
  sendMethod,
  close
}: Props) => {
  const { registerState } = useRegisterUserDataStore();
  const [sendEmailLinkFlow] = useSendEmailLinkFlowMutation();
  const { t } = useTranslation(['modal-detect-mobile']);
  const { control, handleSubmit } = useForm<PropsForm>({
    defaultValues: {
      email: registerState?.user?.email
    }
  });

  const onSubmit = async (data: PropsForm) => {
    try {
      await sendEmailLinkFlow({ email: data.email }).then(() => close());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormBox component='form' onSubmit={handleSubmit(onSubmit)}>
      <ContainerBox>
        <HeaderBox>
          <ArrowBackIcon onClick={goBack} />
          <TextHeader>
            {t('typeWhatsappOrEmail', {
              whatsappOrEmail: sendMethod === 'whatsapp' ? 'Whatsapp' : 'E-mail'
            })}
          </TextHeader>
        </HeaderBox>
        {sendMethod === 'whatsapp' ? (
          <WhatsappTextField name='whatsapp' {...{ control }} />
        ) : (
          <EmailTextField name='email' {...{ control }} />
        )}
        <Typography>
          {t('receiveLink', {
            whatsappOrEmail: sendMethod === 'whatsapp' ? 'Whatsapp' : 'E-mail'
          })}
        </Typography>
        <Button type='submit' variant='contained' disableElevation>
          {t('sendLink')}
        </Button>
      </ContainerBox>
    </FormBox>
  );
};

export default SendWhatsappOrEmail;
