import { authHttpClient } from '~/app/core/infra/http/axios-auth-http-client-adapter';

import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { AUTH_API_ROUTES } from '../../api/routes';
import { CreateCompany } from './create-company';

export const createCompanyService: ServiceCommand<CreateCompany.Response> =
  new CreateCompany(authHttpClient, AUTH_API_ROUTES.CREATE_COMPANY);

export type { CreateCompany };
