import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { AddVehiclesModel } from '../../domain/models/add-vehicles-model';
import { AddVehiclesResponseModel } from '../../domain/models/add-vehicles-response-model';

export class AddVehicles implements ServiceCommand<AddVehicles.Response> {
  constructor(
    private readonly httpClient: HttpClient<AddVehicles.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: AddVehicles.Params
  ): Promise<ServiceCommand.Response<AddVehicles.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: { ...params, isSelfOnboarding: true }
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace AddVehicles {
  export type Params = AddVehiclesModel;

  export type Response = AddVehiclesResponseModel;
}
