import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ConsumablesForm } from '../../domain/models/consumable-model';

export const consumablesTypesSlice = createSlice({
  name: 'consumables',
  initialState: {
    consumablesTypes: []
  },
  reducers: {
    addConsumablesType: (state, action: PayloadAction<ConsumablesForm>) => {
      state.consumablesTypes.push(action.payload);
    },
    editConsumablesType: (state, action: PayloadAction<ConsumablesForm>) => {
      const updatedType = action.payload;
      state.consumablesTypes = state.consumablesTypes.map((type) =>
        type._id === updatedType._id ? updatedType : type
      );
    },
    deleteConsumablesType: (state, action: PayloadAction<string>) => {
      state.consumablesTypes = state.consumablesTypes.filter(
        (type) => type._id !== action.payload
      );
    }
  }
});
