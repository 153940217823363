import loadable from '@loadable/component';

import { IRoute } from '../../../../main/router/types/router';

// TODO: need improve it
const Home = loadable(
  async () =>
    import('~/app/application/features/general/presentation/pages/home/home'),
  {
    resolveComponenet: (components) => components.Home
  }
);

const Error404 = loadable(
  async () =>
    import('~/app/application/features/general/error/error-404/error-404'),
  {
    resolveComponenet: (components) => components.Error404
  }
);

const Consumables = loadable(
  async () =>
    import('~/app/application/features/components/consumables/consumables'),
  {
    resolveComponenet: (components) => components.Consumables
  }
);

export const generalRoutes: IRoute[] = [
  {
    element: <Home />,
    name: 'home',
    path: '/',
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <Error404 />,
    name: 'error404',
    path: '/error-404',
    layout: 'DefaultLayout',
    private: false
  },
  {
    element: <Consumables />,
    name: 'consumables',
    path: '/consumables',
    layout: 'DefaultLayout',
    private: false
  }
];
