import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import {
  CheckIconStyle,
  CloseIconStyle,
  Container,
  Itens
} from './password-requeriments-styles';

const PasswordRequirements = ({ password }: { password: string }) => {
  const { t } = useTranslation(['reset-password']);
  const requirements = {
    length: password.length >= 8
    // uppercase: /[A-Z]/.test(password),
    // lowercase: /[a-z]/.test(password),
    // number: /[0-9]/.test(password),
    // special: /[!@#$%^&*())_+=-]/.test(password)
  };

  return (
    <Container>
      <Itens>
        {t('characters')}
        {requirements.length ? (
          <CheckIconStyle>
            <CheckIcon />
          </CheckIconStyle>
        ) : (
          <CloseIconStyle>
            <CloseIcon />
          </CloseIconStyle>
        )}
      </Itens>
      {/* <Itens>
        {t('uppercaseAndLowercase')}
        {requirements.uppercase && requirements.lowercase ? (
          <CheckIconStyle>
            <CheckIcon />
          </CheckIconStyle>
        ) : (
          <CloseIconStyle>
            <CloseIcon />
          </CloseIconStyle>
        )}
      </Itens>
      <Itens>
        {t('numbers')}
        {requirements.number ? (
          <CheckIconStyle>
            <CheckIcon />
          </CheckIconStyle>
        ) : (
          <CloseIconStyle>
            <CloseIcon />
          </CloseIconStyle>
        )}
      </Itens>
      <Itens>
        {t('specialCharacters')}
        {requirements.special ? (
          <CheckIconStyle>
            <CheckIcon />
          </CheckIconStyle>
        ) : (
          <CloseIconStyle>
            <CloseIcon />
          </CloseIconStyle>
        )}
      </Itens> */}
    </Container>
  );
};

export default PasswordRequirements;
