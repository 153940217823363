import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { SignUpModel } from '../../domain/models/sign-up-model';
import { SignUpResponseModel } from '../../domain/models/sign-up-response-model';

export class SignUp implements ServiceCommand<SignUp.Response> {
  constructor(
    private readonly httpClient: HttpClient<SignUp.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: SignUp.Params
  ): Promise<ServiceCommand.Response<SignUp.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export const storeEmail = (email: string) =>
  sessionStorage.setItem('forgot-password-email', email);

export const getStoreEmail = () =>
  sessionStorage.getItem('forgot-password-email');

export namespace SignUp {
  export type Params = SignUpModel;

  export type Response = SignUpResponseModel;
}
