import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { httpClient } from '../../../../../core/infra/http/apolo-http-client-adapter';
import { AUTH_API_ROUTES } from '../../api/routes';
import { SignUp } from './sign-up';

export const signUpService: ServiceCommand<SignUp.Response> = new SignUp(
  httpClient,
  AUTH_API_ROUTES.SIGN_UP
);

export type { SignUp };
