import { Link } from 'react-router-dom';

import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  // alignItems: 'center',
  gap: theme.spacing(4)
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  minWidth: '480px',
  [theme.breakpoints.down('md')]: {
    minWidth: '480px'
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '320px'
  }
}));

export const AlignCenterBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

export const Container404 = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '70vh',
  maxWidth: theme.spacing(150),
  marginLeft: theme.spacing(10),
  marginRight: theme.spacing(10),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8)
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  }
}));

export const ContainerText = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

export const TextTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(80),
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(40)
  }
}));

export const TextMessage = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(18)
  }
}));

export const TextItalic = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(18)
  }
}));
