import { styled } from '@mui/material';

export const CurrentPlanContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  padding: '20px 10px 10px 15px'
}));

export const CurrentPlanTitle = styled('h1')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(35),
  fontWeight: 700,
  margin: 0,
  gap: 0
}));

export const CurrentPlanDescription = styled('p')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  marginTop: 0,
  marginBottom: 24
}));
