import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#F9401B'
    },
    text: {
      primary: '#141414',
      secondary: '#525252',
      disabled: '#FFFFFF'
    },
    divider: '#A3A3A3',
    background: {
      default: '#FFFFFF',
      paper: 'rgba(0, 0, 0, 0.06)'
    },
    warning: {
      main: '#E63737'
    },
    success: {
      main: '#188F00'
    }
  }
});
