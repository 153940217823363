import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Skeleton, TextFieldProps } from '@mui/material';

import BaseTextField from '../../../../core/presentation/components/base-text-field/base-text-field';

export type CompanyNameTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
  isLoading?: boolean;
};

const CompanyNameTextField = (props: CompanyNameTextFieldProps) => {
  const { t } = useTranslation(['components']);
  return (
    <Controller
      name='companyName'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {props.isLoading ? (
            <Skeleton variant='rectangular' height={56} />
          ) : (
            <BaseTextField
              onChange={onChange}
              value={value || ''}
              error={!!error}
              helperText={
                error ? props.errorText ?? t('companyNameInvalid') : undefined
              }
              id='companyName'
              name='companyName'
              label={t('companyName')}
              variant='filled'
              type='companyName'
              inputProps={{ maxLength: 256, shrink: props.value }}
              required
              {...props}
            />
          )}
        </>
      )}
    />
  );
};

export default CompanyNameTextField;
