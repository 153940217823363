import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { cacheStorage } from '../../../../../core/infra/cache';
import { httpClient } from '../../../../../core/infra/http/apolo-http-client-adapter';
import { AUTH_API_ROUTES } from '../../api/routes';
import { AUTH_STORAGE_TOKENS } from '../../domain/entities/auth-tokens';
import { AuthorizationUrl } from './authorization-url';
import { GoogleLogin } from './google-login';

export const authorizationUrlLoginService: ServiceCommand<AuthorizationUrl.Response> =
  new AuthorizationUrl(
    httpClient,
    cacheStorage,
    AUTH_STORAGE_TOKENS.AUTH,
    AUTH_API_ROUTES.AUTHORIZATION_URL
  );

export const googlelLoginService: ServiceCommand<GoogleLogin.Response> =
  new GoogleLogin(
    httpClient,
    cacheStorage,
    AUTH_STORAGE_TOKENS.AUTH,
    AUTH_API_ROUTES.GOOGLE_LOGIN
  );

export type { AuthorizationUrl, GoogleLogin };
