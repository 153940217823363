import { ServiceCommand } from '~/app/core/domain/command/service-command';
import { authHttpClient } from '~/app/core/infra/http/axios-auth-http-client-adapter';

import { AUTH_API_ROUTES } from '../../api/routes';
import { SendEmailLinkFlow } from './send-email-link-flow';

export const sendEmailLinkFlowService: ServiceCommand<SendEmailLinkFlow.Response> =
  new SendEmailLinkFlow(authHttpClient, AUTH_API_ROUTES.SEND_EMAIL_LINK_FLOW);

export type { SendEmailLinkFlow };
