import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { ChangePasswordForm } from '../../domain/models/change-password-model';
import { ChangePasswordResponse } from '../../domain/models/change-password-response-model';

export class ChangePassword implements ServiceCommand<ChangePassword.Response> {
  constructor(
    private readonly httpClient: HttpClient<ChangePassword.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: ChangePassword.Params
  ): Promise<ServiceCommand.Response<ChangePassword.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params,
      queryParams: []
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace ChangePassword {
  export type Params = ChangePasswordForm;

  export type Response = ChangePasswordResponse;
}
