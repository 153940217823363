import { ServiceCommand } from '~/app/core/domain/command/service-command';
import { authHttpClient } from '~/app/core/infra/http/axios-auth-http-client-adapter';

import { AUTH_API_ROUTES } from '../../api/routes';
import { AddVehicles } from './add-vehicles';

export const addVehiclesService: ServiceCommand<AddVehicles.Response> =
  new AddVehicles(authHttpClient, AUTH_API_ROUTES.ADD_VEHICLES);

export type { AddVehicles };
