import { Link } from 'react-router-dom';

import { Box, Divider, Typography, styled } from '@mui/material';
import BaseAlert from '~/app/core/presentation/components/base-alert/base-alert';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';

export const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  maxWidth: '500px',
  flexDirection: 'column',
  gap: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    minWidth: theme.spacing(60)
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1)
  }
}));

export const LoginAlertBox = styled(BaseAlert)(({ theme }) => ({
  maxWidth: theme.spacing(60)
}));

export const LogoContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%'
}));

export const AlignBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    width: '100%'
  },
  '@media (max-height: 700px)': {
    gap: theme.spacing(1)
  }
}));

export const ForgotPassword = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  width: '100%',
  textAlign: 'end',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 15
  }
}));

export const TextAccessAccount = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  margin: theme.spacing(3, 0, 1, 0),
  fontWeight: 'bold'
}));

export const ButtonLogin = styled(BaseButton)(({ theme }) => ({
  maxWidth: theme.spacing(60),
  width: '100%',
  textTransform: 'none'
}));

export const DividerStyle = styled(Divider)(({ theme }) => ({
  maxWidth: theme.spacing(60),
  width: '100%',
  color: theme.palette.divider
}));

export const TextDivider = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  color: 'black'
}));
