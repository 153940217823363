import { Route, Routes } from 'react-router-dom';

import Error404 from '../../../application/features/general/error/error-404/error-404';
import { IRoute } from '../types/router';

export const makeRoutes = (routes: IRoute[]) => {
  return (
    <Routes>
      {routes.map((route) => {
        return (
          <Route key={route.path} path={route.path} element={route.element} />
        );
      })}
      <Route key='404' path='*' element={<Error404 />} />
    </Routes>
  );
};
