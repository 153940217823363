import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import { CacheStorage } from '../../../../../core/application/protocols/cache-storage';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { LoginByLinkResponse } from '../../domain/models/login-by-link-response.model';
import { LoginByLinkForm } from '../../domain/models/login-by-link.model';

export class LoginByLink implements ServiceCommand<LoginByLink.Response> {
  constructor(
    private readonly httpClient: HttpClient<LoginByLink.Response>,
    private readonly cacheStorage: CacheStorage,
    private readonly tokenKey: string,
    private readonly url: string
  ) {}

  async execute(
    params: LoginByLink.Params
  ): Promise<ServiceCommand.Response<LoginByLink.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    const { accessToken } = response;
    this.cacheStorage.set(this.tokenKey, accessToken);

    return success(response);
  }
}

export namespace LoginByLink {
  export type Params = LoginByLinkForm;

  export type Response = LoginByLinkResponse;
}
