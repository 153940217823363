import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { ChangePasswordResponse } from '../../domain/models/change-password-response-model';
import { ValidateAccessCodeForm } from '../../domain/models/validade-access-code-model';

export class ValidateAccessCode
  implements ServiceCommand<ValidateAccessCode.Response>
{
  constructor(
    private readonly httpClient: HttpClient<ValidateAccessCode.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: ValidateAccessCode.Params
  ): Promise<ServiceCommand.Response<ValidateAccessCode.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params,
      queryParams: []
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace ValidateAccessCode {
  export type Params = ValidateAccessCodeForm;

  export type Response = ChangePasswordResponse;
}
