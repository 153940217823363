import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';
import FullNameTextField from '~/app/application/features/components/full-name-text-field/full-name-text-field';
import { logEvent } from '~/app/application/shared/lib/tag';
import BaseRadioButton from '~/app/core/presentation/components/base-radio-button/base-radio-button';
import { useAuth } from '~/app/core/presentation/hooks/use-auth';
import { useFeatureFlags } from '~/app/core/presentation/hooks/use-feature-flags';

import BaseAlert from '../../../../../../../core/presentation/components/base-alert/base-alert';
import BaseButton from '../../../../../../../core/presentation/components/base-button/base-button';
import EmailTextField from '../../../../../components/email-text-field/email-text-field';
import { SignUpModel } from '../../../../domain/models/sign-up-model';
import { UpdateMeModel } from '../../../../domain/models/update-me-model';
import { RegisterRoutes } from '../../../../routes/routes';
import {
  useSignUpMutation,
  useUpdateMeMutation
} from '../../../../store/hooks';
import { useRegisterUserDataStore } from '../../../../store/slice/register-user-data-slice';
import LoginOrRegisterWithGoogle from '../../../access-google/access-google';
import { carQuantity } from '../company-data-step/car-quantity';
import { SignUpFormDataProps } from '../form-register';
import { RegisterFormWizardTitle } from '../form-register-styles';
import ContactEnterpriseStep from './contact-enterprise-step/contact-enterprise-step';
import PersonalDataStepFooter from './personal-data-step-footer/personal-data-step-footer';
import { PersonalDataValidator } from './personal-data-step-footer/personal-data.validator';
import {
  NameContainer,
  PersonalDataStepContainer,
  QuantityCarContainer
} from './personal-data-step-styles';
import PasswordTextField from '~/app/application/features/components/password-text-field/password-text-field';

type PersonalDataProps = {
  handleFormData: (values: SignUpFormDataProps) => void;
  formData: SignUpFormDataProps;
  comeBack: (data: { onClick: () => void }) => void;
};

const PersonalDataStep = ({
  handleFormData,
  formData,
  comeBack
}: PersonalDataProps) => {
  const [displayError, setDisplayError] = useState(false);
  const navigate = useNavigate();
  const { setRegisterState } = useRegisterUserDataStore();
  const { getLogin } = useAuth();
  const { t } = useTranslation('register-personal-step');
  const { useLogin } = useAuth();
  const [signUp, { isLoading }] = useSignUpMutation();
  const [updateMe] = useUpdateMeMutation();
  const { isFlagEnabled } = useFeatureFlags();
  const useFlowB = isFlagEnabled('use_signup_flow_b');
  const useGoogleLogin = isFlagEnabled('use_google_auth');
  const [showButtonLoginGoogle, setShowButtonLoginGoogle] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { registerState } = useRegisterUserDataStore();
  const [openContactEnterprise, setOpenContactEnterprise] = useState(false);

  const signUpRequest = useCallback(
    (values: SignUpModel) => {
      signUp({ ...values, fullName: undefined })
        .unwrap()
        .then((response) => {
          useLogin({
            accessToken: response.accessToken,
            refreshToken: response.refreshToken
          });
          setDisplayError(false);
          handleFormData({ ...formData, user: values ,isAuthenticated: true});
          setRegisterState({
            isEnterpriseContact: false,
            user: {
              active: true,
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName
            },
            carQuantity: values.carQuantity,
            isAuthenticated: true
          });
          logEvent({
            action: 'OnSubmit',
            label: 'step01_cadastro',
            category: 'Nome do evento/trigger'
          });
          if (values.carQuantity === 'Acima de 40') {
            comeBack({ onClick: () => setOpenContactEnterprise(false) });
            return setOpenContactEnterprise(true);
          }
          return navigate(RegisterRoutes.COMPANY_DATA);
        })
        .catch(() => {
          setDisplayError(true);
        });
    },
    [formData, handleFormData, useLogin, setRegisterState, navigate]
  );

  const updateMeRequest = useCallback(
    (values: UpdateMeModel) => {
      updateMe({ ...values })
        .unwrap()
        .then((response) => {
          handleFormData({ ...formData, user: values });
          if (response.carQuantity && response.carQuantity === 'Acima de 40') {
            comeBack({ onClick: () => setOpenContactEnterprise(false) });
            return setOpenContactEnterprise(true);
          }
          return navigate(RegisterRoutes.COMPANY_DATA);
        });
    },
    [formData, handleFormData, navigate, updateMe]
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid }
  } = useForm<SignUpModel>({
    resolver: PersonalDataValidator,
    values: formData.user,
    mode: 'onChange'
  });

  const handleSubmitForm = useCallback(
    (data: SignUpModel) => {
      const tokens = getLogin();

      if (data.fullName) {
        const fullName = data.fullName.split(' ');
        data.firstName = fullName[0].trim();
        data.lastName = fullName.slice(1).join(' ').trim();
      }

      if (
        tokens &&
        registerState?.user &&
        registerState?.user.email === data.email
      ) {
        updateMeRequest({
          firstName: data.firstName,
          lastName: data.lastName
        });

        return;
      }

      signUpRequest({
        ...data,
        metaData: [{ key: 'flow', value: useFlowB ? 'B' : 'A' }]
      });
    },
    [updateMeRequest, signUpRequest, t, useFlowB, registerState]
  );

  const displayFields = useMemo(() => {
    return (
      !useFlowB ||
      (registerState?.user?.firstName === '' &&
        registerState?.user?.lastName === '')
    );
  }, [registerState, useFlowB]);

  useEffect(() => {
    setShowButtonLoginGoogle(useGoogleLogin);
  }, [useGoogleLogin]);

  useEffect(() => {
    if (registerState?.user.firstName && registerState?.user.lastName) {
      setValue(
        'fullName',
        `${registerState.user.firstName} ${registerState.user.lastName}`
      );
      setValue('firstName', registerState.user.firstName);
      setValue('lastName', registerState.user.lastName);
      setValue('email', registerState.user.email);
      setValue('carQuantity', registerState.carQuantity);
    }
  }, []);

  return (
    <>
      {openContactEnterprise ? (
        <ContactEnterpriseStep comeBack={comeBack} />
      ) : (
        <PersonalDataStepContainer
          component='form'
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <RegisterFormWizardTitle>
            {t('titleRegister')}
          </RegisterFormWizardTitle>
          <LoginOrRegisterWithGoogle
            isRegister
            showButton={showButtonLoginGoogle}
          />

          {displayError && (
            <BaseAlert sx={{ width: '100%' }} severity='info'>
              {t('textEmailAlreadyExists')} <a href='/login'>entrar</a>?
            </BaseAlert>
          )}
          {displayFields && (
            <NameContainer>
              {/*
             <FirstNameTextField
               errorText={t('firstNameInvalid')}
               control={control}
             />
             <LastNameTextField
               errorText={t('lastNameInvalid')}
               control={control}
             />
           */}

              <FullNameTextField control={control} />
            </NameContainer>
          )}
          {registerState?.user?.firstName !== '' &&
            registerState?.user?.lastName !== '' && (
              <EmailTextField errorText={t('emailInvalid')} control={control} />
            )}
          { displayFields && (
            <PasswordTextField errorText={t('passwordInvalid')} control={control} />
          ) }
          <QuantityCarContainer>
            <BaseRadioButton
              required
              row={!isMobile}
              control={control}
              name='carQuantity'
              labelid='carQuantity'
              label={t('carQuantity')}
              values={carQuantity}
            />
          </QuantityCarContainer>
          <BaseButton
            disableElevation
            type='submit'
            variant='contained'
            color='primary'
            disabled={!isDirty || !isValid}
            isLoading={isLoading}
          >
            {t('register:next')}
          </BaseButton>
          <PersonalDataStepFooter />
        </PersonalDataStepContainer>
      )}
    </>
  );
};

export default PersonalDataStep;
