import BaseAuthLayout from '../../../components/base-auth-layout/base-auth-layout';
import SuccessSetting from '../../../components/success-setting/success-setting';
import { useSuccessSetting } from '../../store/slice/success-setting-slice';
import FormRegister from './form-register/form-register';

export type CallbackProps = {
  pathImage?: string | undefined;
};

const Register = ({ pathImage }: CallbackProps) => {
  const { dataSuccessSetting } = useSuccessSetting();

  return !dataSuccessSetting ? (
    <BaseAuthLayout
      leftChildren={<FormRegister />}
      rightChildren={pathImage ? <img src={pathImage} /> : undefined}
    />
  ) : (
    <SuccessSetting {...dataSuccessSetting} />
  );
};

export default Register;
