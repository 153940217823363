import TagManager, { DataLayerArgs } from 'react-gtm-module';

interface EventParams {
  action: string;
  category?: string;
  label?: string;
  value?: string;
}

export function logPageView(url: string) {
  const dataLayer: DataLayerArgs = {
    dataLayer: {
      event: 'pageview',
      page: url
    }
  };
  TagManager.dataLayer(dataLayer);
}

// Function to log a custom event
export function logEvent({ action, category = '', label = '' }: EventParams) {
  const dataLayer: DataLayerArgs = {
    dataLayer: {
      event: action,
      event_category: category,
      event_label: label
    }
  };
  TagManager.dataLayer(dataLayer);
}
