import {
  CurrentPlanContainer,
  CurrentPlanDescription,
  CurrentPlanTitle
} from './upgrade-plan-header-styles';

interface Props {
  title: string;
  description: string;
}

const UpgradePlanHeader = ({ title, description }: Props) => {
  return (
    <CurrentPlanContainer>
      <CurrentPlanTitle>{title}</CurrentPlanTitle>

      <CurrentPlanDescription>{description}</CurrentPlanDescription>
    </CurrentPlanContainer>
  );
};
export default UpgradePlanHeader;
