import { create } from 'zustand';

import { SettingPlanProps } from '../../../components/success-setting/success-setting';

interface SuccessSettingState {
  dataSuccessSetting: SettingPlanProps | null;
  setSuccessSetting: (data: SettingPlanProps) => void;
  clearSuccessSetting: () => void;
}

export const useSuccessSetting = create<SuccessSettingState>((set) => ({
  dataSuccessSetting: null,
  setSuccessSetting: (data: SettingPlanProps) =>
    set({ dataSuccessSetting: data }),
  clearSuccessSetting: () => set({ dataSuccessSetting: null })
}));
