import { ServiceCommand } from '~/app/core/domain/command/service-command';
import { authHttpClient } from '~/app/core/infra/http/axios-auth-http-client-adapter';

import { AUTH_API_ROUTES } from '../../api/routes';
import { GetVehicles } from './get-vehicles';

export const getVehiclesService: ServiceCommand<GetVehicles.Response> =
  new GetVehicles(authHttpClient, AUTH_API_ROUTES.GET_VEHICLES);

export type { GetVehicles };
