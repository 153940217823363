import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { ContactSalesModel } from '../../domain/models/contact-sales-model';

export class ContactSales implements ServiceCommand<ContactSales.Response> {
  constructor(
    private readonly httpClient: HttpClient<ContactSales.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: ContactSales.Params
  ): Promise<ServiceCommand.Response<ContactSales.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace ContactSales {
  export type Params = ContactSalesModel;

  export type Response = ContactSalesModel;
}
