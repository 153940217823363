import { storeEmail } from '../../services/email-login/email-login';
import { usePasswordMutation } from '../../store/hooks';

export const useEmailSender = () => {
  const [password, { isLoading }] = usePasswordMutation();

  const sendEmail = (values) => {
    return password(values)
      .unwrap()
      .then(() => {
        storeEmail(values.email);
      })
      .catch((error) => {
        throw error;
      });
  };

  return {
    sendEmail,
    isLoading
  };
};
