import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { CompanyResponseModel } from '../../domain/models/companies/company-response-model';
import { CreateCompanyRequestModel } from '../../domain/models/companies/create-company-request-model';

export class CreateCompany implements ServiceCommand<CreateCompany.Response> {
  constructor(
    private readonly httpClient: HttpClient<CreateCompany.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: CreateCompany.Params
  ): Promise<ServiceCommand.Response<CreateCompany.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace CreateCompany {
  export type Params = CreateCompanyRequestModel;

  export type Response = CompanyResponseModel;
}
