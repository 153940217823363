import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { debounce } from '@mui/material';
import { logEvent } from '~/app/application/shared/lib/tag';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';
import LinkMailTo from '~/app/core/presentation/components/link-mail-to/link-mail-to';
import LinkWhatsapp from '~/app/core/presentation/components/link-whatsapp/link-whatsapp';
import useJwtInfo from '~/app/core/presentation/hooks/use-jwt-info';
import { APP_ENV } from '~/env';

import BaseSelectInput from '../../../../../../../core/presentation/components/base-select-input/base-select-input';
import AddressTextField from '../../../../../components/address-text-field/address-text-field';
import CNPJTextField from '../../../../../components/cnpj-text-field/cnpj-text-field';
import CompanyNameTextField from '../../../../../components/company-name-text-field/company-name-text-field';
import EmailTextField from '../../../../../components/email-text-field/email-text-field';
import PhoneTextField from '../../../../../components/phone-text-field/phone-text-field';
import { CreateCompanyRequestModel } from '../../../../domain/models/companies/create-company-request-model';
import { CompanyModel } from '../../../../domain/models/company-model';
import { RegisterRoutes } from '../../../../routes/routes';
import {
  useCnpjLookupMutation,
  useCnpjValidateMutation,
  useCreateCompanyMutation,
  useCreateSubscriptionMutation,
  useGetOwnersMutation
} from '../../../../store/hooks';
import { useRegisterUserDataStore } from '../../../../store/slice/register-user-data-slice';
import { SignUpFormDataProps } from '../form-register';
import {
  CNPJAndSectorContainer,
  CompanyDataContainer,
  CompanyDetailsContainer,
  InfoAlert
} from './company-data-step-styles';
import { CompanyDataValidator } from './company-data.validator';
import { sectorValues } from './sector-values';

export type CompanyDataStepProps = {
  handleFormData: (values: SignUpFormDataProps) => void;
  formData: SignUpFormDataProps;
  isLoading?: boolean;
};

type CompanyDataForm = {
  cnpj: string;
  companyName: string;
  sector: string;
  address: string;
  phone: string;
  email: string;
};

const SUPPORT_EMAIL = 'suporte@frota162.com.br';

const maskEmail = (email) => {
  const [localPart, domain] = email.split('@');
  
  const getMaskedLocalPart = (local) => {
    const visibleChars = Math.min(local.length, 3);
    const maskedChars = '*'.repeat(local.length - visibleChars);
    return `${local.slice(0, visibleChars)}${maskedChars}`;
  };

  const getMaskedDomainPart = (domain) => {
    const domainParts = domain.split('.');
    if (domainParts.length <= 1) {
      return domain;
    }
    const maskedSubdomain = '*'.repeat(domainParts[domainParts.length - 2].length);
    const tld = domainParts[domainParts.length - 1];
    return `${maskedSubdomain}.${tld}`;
  };

  const maskedLocal = getMaskedLocalPart(localPart);
  const maskedDomain = getMaskedDomainPart(domain);

  return `${maskedLocal}@${maskedDomain}`;
};

const CompanyDataStep = ({
  handleFormData,
  formData
}: CompanyDataStepProps) => {
  const navigate = useNavigate();
  const planId = APP_ENV.FREE_PLAN_ID;

  const { t } = useTranslation(['register-company-step', 'register']);
  const [cnpjError, setCnpjError] = useState(false);
  const [ existingCompanyEmail, setExistingCompanyEmail ] = useState<string>('');

  const { control, handleSubmit, formState, reset, setError } =
    useForm<CompanyModel>({
      resolver: CompanyDataValidator,
      mode: 'onChange'
    });
  const { registerState, setRegisterState } = useRegisterUserDataStore();
  const [cnpjLookup, { isLoading }] = useCnpjLookupMutation();
  const [cnpjValidate] = useCnpjValidateMutation();
  const [getOwners] = useGetOwnersMutation();
  const [createCompany, { isLoading: isCreationLoading }] =
    useCreateCompanyMutation();
  const jwtInfo = useJwtInfo();
  const [companyData, setCompanyData] = useState<CreateCompanyRequestModel>();
  const [displayCompanyData, setDisplayCompanyData] = useState(false);
  const [createSubscription, { isLoading: isLoadingSubscription }] =
    useCreateSubscriptionMutation();
  const plans = registerState.plans;
  const cnpjValidateRequest = (values: CompanyModel) => {
    cnpjValidate(values)
      .unwrap()
      .then(async (response) => {
        await handleOwners(response);
        setDisplayCompanyData(false);
        setCnpjError(true);
      })
      .catch(() => {
        setCnpjError(false);
        cnpjLookupRequest(values);
      });
  };

  const handleOwners = async (response) => {
    if (response.owners.length <= 0) setExistingCompanyEmail('');

    getOwners({ id: response._id }).unwrap().then((owners) => {
      setExistingCompanyEmail(owners.length > 0 ? maskEmail(owners[0].email) : '');
    }).catch((error) => {
      setExistingCompanyEmail(''); 
    });
  };

  const cnpjLookupRequest = (values: CompanyModel) => {
    setCnpjError(false);
    reset(
      (formValues) => ({
        ...formValues,
        address: '',
        companyName: '',
        phone: '',
        email: ''
      }),
      { keepIsValid: true }
    );
    cnpjLookup(values)
      .unwrap()
      .then((response) => {
        const phone =
          registerState.companies[0] &&
          registerState.companies[0].phones &&
          registerState.companies[0].phones.length > 0 &&
          `${registerState.companies[0].phones[0].regionCode}${registerState.companies[0].phones[0].phone}`;

        reset(
          (formValues) => ({
            ...formValues,
            address: `${response.address.street}, ${response.address.number}, ${response.city.name}, ${response.state.name}, ${response.address.postalCode}`,
            companyName: response.companyName,
            phone: phone
              ? phone
              : response.phone.regionCode
                ? `${response.phone.regionCode}${response.phone.phone}`
                : undefined,
            email: response.email ?? formValues.email
          }),
          { keepIsValid: true }
        );
        setCompanyData({
          name: response.companyName,
          fantasyName: response.companyName,
          phones:
            registerState.companies[0] &&
            registerState.companies[0].phones &&
            registerState.companies[0].phones.length > 0
              ? registerState.companies[0].phones
              : [{ ...response.phone, countryCode: '55' }],
          owners: [jwtInfo._id],
          taxId: response.taxId,
          email: response.email ?? registerState.user.email,
          address: {
            ...response.address,
            state: response.state,
            city: response.city
          }
        });
        setDisplayCompanyData(true);
      })
      .catch(() => {
        setCnpjError(true);
        reset({
          address: '',
          companyName: '',
          phone: '',
          email: ''
        });
        setDisplayCompanyData(false);
        setError('cnpj', { type: 'custom', message: 'error in fetch cnpj' });
      });
  };

  const createCompanyRequest = (values: CreateCompanyRequestModel) => {
    createCompany({ ...values, isSelfOnboarding: true })
      .unwrap()
      .then(async (company) => {
        setRegisterState({
          ...formData,
          companies: [
            {
              ...company,
              subscriptions: []
            }
          ]
        });

        const freePlan = plans.find((plan) => plan.type === 'free');
        if (!freePlan) console.error('free plan not found');

        await createSubscription({
          companyId: company._id,
          planId: freePlan?._id ?? planId
        })
          .unwrap()
          .then((subscription) => {
            setRegisterState({
              ...formData,
              companies: [
                {
                  ...company,
                  subscriptions: [subscription._id.toString()]
                }
              ]
            });
          })
          .catch(() => {
            console.error('error in create subscription');
          });

        handleFormData({
          ...formData,
          company
        });

        navigate(RegisterRoutes.VEHICLES_DATA);

        logEvent({
          category: 'Dados da empresa/trigger',
          action: 'OnSubmit',
          label: 'step03_dados_empresa'
        });
      })
      .catch(() => {
        console.error('error in create company');
      });
  };

  const cnpjWatch = useWatch({ control, name: 'cnpj' });

  const handleCNPJLookup = useMemo(
    () =>
      debounce(() => {
        setDisplayCompanyData(true);
        cnpjValidateRequest({ cnpj: cnpjWatch.replace(/\D/g, '') });
      }, 500),
    [cnpjWatch]
  );

  useEffect(() => {
    setCnpjError(false);
    if (!formState.errors.cnpj && cnpjWatch?.length === 18) handleCNPJLookup();
  }, [cnpjWatch, formState.errors.cnpj, formState.dirtyFields.cnpj]);

  return (
    <CompanyDataContainer
      component='form'
      onSubmit={handleSubmit((data: CompanyDataForm) => {
        createCompanyRequest({
          ...companyData,
          email: data?.email !== '' ? data?.email : registerState.user.email,
          phones: data.phone
            ? [
                {
                  countryCode: '55',
                  regionCode: data?.phone?.replace(/\D/g, '').substring(0, 2),
                  phone: data?.phone?.replace(/\D/g, '').substring(2)
                }
              ]
            : companyData.phones,
          metaData: {
            carQuantity: registerState.carQuantity,
            sector: data.sector
          }
        });
      })}
    >
      {cnpjError && (
        <InfoAlert severity='info'>
        {existingCompanyEmail
          ? t('cnpjError.message', { maskedEmail: existingCompanyEmail })
          : t('cnpjError.messageNoEmail')}
        {' '}
        <LinkMailTo label={SUPPORT_EMAIL} mailto={SUPPORT_EMAIL} />{' '}
        ou pelo WhatsApp:{' '}
        <LinkWhatsapp
          label='(11) 95480-1937'
          countryCode='55'
          phoneNumber='11954801937'
          message={t('cnpjError.whatsappMessage', { cnpjWatch })}
        />
        </InfoAlert>
      )}
      <CNPJAndSectorContainer>
        <CNPJTextField control={control} />
        <BaseSelectInput
          required
          control={control}
          name='sector'
          labelId='sector'
          label={t('register-company-step:sector')}
          values={sectorValues}
        />
      </CNPJAndSectorContainer>

      {displayCompanyData && (
        <CompanyDetailsContainer>
          <CompanyNameTextField
            disabled
            control={control}
            isLoading={isLoading}
          />
          <AddressTextField disabled control={control} isLoading={isLoading} />
          <PhoneTextField control={control} isLoading={isLoading} />
          <EmailTextField
            disabled={!companyData?.email && true}
            control={control}
            isLoading={isLoading}
            optional
          />
        </CompanyDetailsContainer>
      )}
      <BaseButton
        disableElevation
        type='submit'
        variant='contained'
        color='primary'
        isLoading={isLoading || isCreationLoading || isLoadingSubscription}
        disabled={!formState.isDirty || !formState.isValid}
      >
        {t('register:next')}
      </BaseButton>
    </CompanyDataContainer>
  );
};

export default CompanyDataStep;
