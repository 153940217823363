import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Skeleton, TextFieldProps } from '@mui/material';

import BaseTextField from '../../../../core/presentation/components/base-text-field/base-text-field';

export type IdentifierTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
  isLoading?: boolean;
};

const IdentifierTextField = (props: IdentifierTextFieldProps) => {
  const { t } = useTranslation(['login']);

  return (
    <Controller
      name={props.name ?? 'identifier'}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {props.isLoading ? (
            <Skeleton variant='rectangular' height={56} />
          ) : (
            <BaseTextField
              onChange={onChange}
              value={value || ''}
              error={!!error}
              helperText={
                error ? props.errorText ?? t('emailInvalid') : undefined
              }
              id='identifier'
              name={props.name ?? 'identifier'}
              label={t('identifier')}
              variant='filled'
              type='text'
              inputProps={{ shrink: value }}
              required
              {...props}
            />
          )}
        </>
      )}
    />
  );
};

export default IdentifierTextField;
