import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const schema = z.object({
  companyName: z.string().min(10),
  phone: z.string().refine(
    (value) => {
      const cleanNumber = value.replace(/\D/g, '');

      if (cleanNumber.length < 10) return false;

      const ddd = cleanNumber.slice(0, 2);

      if (ddd.length === 2 && (Number(ddd[0]) === 0 || Number(ddd[1]) === 0))
        return false;

      if (cleanNumber.length === 11 && Number(cleanNumber[2]) !== 9)
        return false;

      return true;
    },
    { message: 'Invalid phone number' }
  )
});

export const ContactEnterpriseValidator = zodResolver(schema);
