import { Box, Dialog, Link, styled } from '@mui/material';

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4)
}));

export const VehicleStepContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5)
}));

export const FullScreenManualInputDialog = styled(Dialog)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  '& .MuiDialog-paper': {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    overflowY: 'auto'
  }
}));

export const SpreadsheetModeLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main
}));
