import { httpClient } from '~/app/core/infra/http/axios-http-client-adapter';

import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { AUTH_API_ROUTES } from '../../api/routes';
import { ContactSales } from './contact-sales';

export const contactSalesService: ServiceCommand<ContactSales.Response> =
  new ContactSales(httpClient, AUTH_API_ROUTES.CONTACT_SALES);

export type { ContactSales };
