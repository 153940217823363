import React, { useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { TextFieldProps } from '@mui/material';
import BaseTextField from '~/app/core/presentation/components/base-text-field/base-text-field';

export type CVVTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
  isLoading?: boolean;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CVVMask = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, _) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        mask='000'
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        {...other}
      />
    );
  }
);

const CVVTextField = (props: CVVTextFieldProps) => {
  const { t } = useTranslation(['components', 'errors']);
  const inputElementRef = useRef<HTMLInputElement | null>(null);

  return (
    <Controller
      name='cvv'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseTextField
          onChange={onChange}
          value={value || ''}
          error={!!error}
          helperText={
            error ? props.errorText ?? t('errors:cvvInvalid') : undefined
          }
          id='cvv'
          name='cvv'
          label={t('cvv')}
          variant='filled'
          type='text'
          required
          inputProps={{ maxLength: 3 }}
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: CVVMask as any
          }}
          inputRef={inputElementRef}
          {...props}
        />
      )}
    />
  );
};

export default CVVTextField;
