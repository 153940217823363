import { Box, styled } from '@mui/material';

export const PersonalDataStepContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(5)
}));

export const NameContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(5),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

export const QuantityCarContainer = styled(Box)(() => ({
  width: '100%'
}));
