import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { cacheStorage } from '../../../../../core/infra/cache';
import { httpClient } from '../../../../../core/infra/http/axios-http-client-adapter';
import { AUTH_API_ROUTES } from '../../api/routes';
import { AUTH_STORAGE_TOKENS } from '../../domain/entities/auth-tokens';
import { LoginByLink } from './login-by-link';

export const loginByLinkService: ServiceCommand<LoginByLink.Response> =
  new LoginByLink(
    httpClient,
    cacheStorage,
    AUTH_STORAGE_TOKENS.AUTH,
    AUTH_API_ROUTES.LOGIN_BY_LINK
  );

export type { LoginByLink };
