import { useNavigate } from 'react-router';

import { ToastType } from '~/app/core/presentation/common/types/toast-types';
import { useToast } from '~/app/core/presentation/hooks/use-toast';
import FormProvider from '~/app/core/presentation/providers/form-provider';

import BaseAuthLayout from '../../../components/base-auth-layout/base-auth-layout';
import { ForgotPasswordForm } from '../../domain/models/forgot-password-model';
import { AuthRoutesPath } from '../../routes/routes';
import { useEmailSender } from '../../store/actions/email-sender';
import FormForgotPassword from './forgot-password-form/forgot-password-form';

const ForgotPassword = () => {
  const { sendEmail, isLoading } = useEmailSender();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const handleSendEmail = (values: ForgotPasswordForm) => {
    sendEmail(values)
      .then(() => {
        navigate(AuthRoutesPath.SEND_EMAIL);
      })
      .catch((error) => {
        addToast({
          type: ToastType.ERROR,
          text: error.message
        });
      });
  };

  return (
    <BaseAuthLayout
      leftChildren={
        <FormProvider mode='onSubmit'>
          <FormForgotPassword onSubmit={handleSendEmail} {...{ isLoading }} />
        </FormProvider>
      }
      rightChildren={<img src='/assets/images/forgot-password.svg' />}
    />
  );
};

export default ForgotPassword;
