import { Box, TextField, Typography, styled } from '@mui/material';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';

export const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'normal',
  textAlign: 'left',
  justifyContent: 'initial',
  maxWidth: theme.spacing(60),
  width: '100%',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    alignItems: 'center'
  }
}));

export const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  width: '100%'
}));

export const TextFieldStyle = styled(TextField)(({ theme }) => ({
  maxWidth: theme.spacing(60),
  width: '100%',
  borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  background: theme.palette.background.paper,
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated':
    {
      marginLeft: theme.spacing(1),
      color: theme.palette.text.primary
    },
  '& .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd': {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1.5)
  },
  '& .MuiIconButton-root.MuiIconButton-sizeMedium': {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export const TextResetPassword = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  margin: theme.spacing(3, 0, 1, 0),
  fontWeight: 'bold',
  maxWidth: '480px'
}));

export const Button = styled(BaseButton)(({ theme }) => ({
  maxWidth: theme.spacing(60),
  width: '100%',
  textTransform: 'none',
  marginBottom: '40%',
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(0)
  }
}));

export const BoxRequeriments = styled(Box)(() => ({
  width: '100%'
}));
