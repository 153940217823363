import { Link } from 'react-router-dom';

import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  '@media (max-height: 700px)': {
    gap: theme.spacing(1)
  }
}));

export const TextAccount = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  [theme.breakpoints.down('sm')]: {
    marginTop: 15
  }
}));

export const TextRegister = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(1)
}));
