import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const schema = z.object({
  name: z.string().min(1).max(250),
  email: z.string().email(),
  phone: z.string().min(1).max(20),
  companyName: z.string().min(1).max(250),
  message: z.string().min(1).max(250)
});

export const ContactSalesValidator = zodResolver(schema);
