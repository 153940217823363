import { Box, Button, Modal, styled } from '@mui/material';

export const ModalStyled = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const ContainerButton = styled('div')(() => ({
  display: 'flex',
  gap: 5,
  flexDirection: 'row-reverse',
  '@media (max-width: 900px)': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'right'
  }
}));

export const BoxModalApertPlan = styled(Box)(() => ({
  top: '50%',
  left: '50%',
  background: '#ffffff',
  width: '525px',
  borderRadius: '2px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  padding: '20px',
  '@media (max-width: 900px)': {
    width: '100%',
    margin: '0 10px'
  }
}));

export const ButtonModalAertPlan = styled(Button)(() => ({
  fontSize: '16px',
  color: '#1976d2'
}));
