import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { licensePlateValidation } from '../../../validations/license-plate-validation';
import { renavamValidation } from '../../../validations/renavam-validation';

const vehicleSchema = z.object({
  plate: z.string().min(7).max(7).refine(licensePlateValidation),
  renavam: z.string().min(1).max(11).refine(renavamValidation)
});
const schema = z.object({
  vehicles: z.array(vehicleSchema).min(5)
});

export const PlateListValidator = zodResolver(schema);
