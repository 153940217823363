import React from 'react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import { Table, TableBody, TableHead, Tooltip } from '@mui/material';

import { PlanDetails } from '../../auth/presentation/upgrade-plans/upgrade-plans/plans-list';
import {
  ButtonTableCell,
  BuyButton,
  EmptyCell,
  MobileModuleNameCell,
  MobilePlanFunctionalityCell,
  MobilePlanTableRow,
  ModuleNameCell,
  NotAvaliableIcon,
  PlanFunctionalityCell,
  PlanHeaderNameCell,
  PlanTableCell,
  PlanTableContainer,
  PlanTableRow,
  PlanTooltipContainer,
  PlanTooltipIcon,
  SpacerCell,
  TableHeaderRow
} from './plan-details-table-styles';

type PlansTableProps = {
  isLoading?: boolean;
  plansData: PlanDetails[];
};

type PlanNameAndHelper = {
  name: string;
  helperText?: string;
};

const getFunctionalityStatus = (
  plan: PlanDetails,
  functionalityName: string
) => {
  const functionality = plan.functionalities.find(
    (func) => func.name === functionalityName
  );
  if (functionality?.description) return functionality.description;
  return functionality?.active ? <CheckIcon /> : <NotAvaliableIcon />;
};

const getModuleFunctionalities = (
  plan: PlanDetails,
  functionalityName: string,
  index: number
) => {
  const module = plan.module && plan.module[index];
  if (module) {
    const functionality = module.functionalities.find(
      (func) => func.name === functionalityName
    );
    if (functionality?.description) return functionality.description;
    return functionality?.active ? <CheckIcon /> : <NotAvaliableIcon />;
  }
  return '';
};

const findPlanWithMostFunctionalities = (plans: PlanDetails[]): number => {
  let maxFunctionalityCount = 0;
  let planIndex = -1;

  for (let i = 0; i < plans.length; i++) {
    const functionalityCount = plans[i].functionalities.length;
    if (functionalityCount > maxFunctionalityCount) {
      maxFunctionalityCount = functionalityCount;
      planIndex = i;
    }
  }

  return planIndex;
};

export default function PlanDetailsTable({
  isLoading,
  plansData
}: PlansTableProps) {
  const { t } = useTranslation('components');
  const plans = plansData.filter((plan) => !plan.disableColumn);
  const planIndex = findPlanWithMostFunctionalities(plans);
  const functionalityNameAndHelpers: PlanNameAndHelper[] = plans[
    planIndex
  ].functionalities.map((func) => {
    return { ...func };
  });

  return (
    <PlanTableContainer sx={{ display: isLoading ? 'none' : 'flex' }}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableHeaderRow>
            <EmptyCell />
            {plans.map((plan, index) => (
              <React.Fragment key={plan.name}>
                <PlanHeaderNameCell
                  backgroundColor={plan.currentPlan ? '#6A6A6A' : plan.color}
                >
                  {plan.name}
                </PlanHeaderNameCell>
                {index !== plans.length - 1 && <SpacerCell />}
              </React.Fragment>
            ))}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {functionalityNameAndHelpers.map((functionality, indexPlans) => (
            <React.Fragment key={`${indexPlans}.${functionality.name}.root`}>
              <MobilePlanTableRow key={`${functionality.name}.mobile`}>
                <MobilePlanFunctionalityCell
                  colSpan={3}
                  component='th'
                  scope='row'
                >
                  <PlanTooltipContainer>
                    {functionality.name}
                    {functionality.helperText && (
                      <Tooltip
                        enterTouchDelay={50}
                        title={functionality.helperText}
                        placement='bottom'
                      >
                        <PlanTooltipIcon />
                      </Tooltip>
                    )}
                  </PlanTooltipContainer>
                </MobilePlanFunctionalityCell>
              </MobilePlanTableRow>

              <PlanTableRow key={`${functionality.name}.desktop`}>
                <PlanFunctionalityCell
                  backgroundColor={indexPlans % 2 === 0 ? '#FFFFFF' : '#EFEFEF'}
                  component='th'
                  scope='row'
                >
                  <PlanTooltipContainer>
                    {functionality.name}
                    {functionality.helperText && (
                      <Tooltip
                        enterTouchDelay={50}
                        title={functionality.helperText}
                        placement='right-start'
                      >
                        <PlanTooltipIcon />
                      </Tooltip>
                    )}
                  </PlanTooltipContainer>
                </PlanFunctionalityCell>
                {plans.map((plan, index) => (
                  <React.Fragment key={plan.name}>
                    <PlanTableCell
                      backgroundColor={
                        indexPlans % 2 === 0 ? '#FFFFFF' : '#EFEFEF'
                      }
                      borderColor={plan.currentPlan ? '#6A6A6A' : plan.color}
                    >
                      {getFunctionalityStatus(plan, functionality.name)}
                    </PlanTableCell>
                    {index !== plans.length - 1 && <SpacerCell />}
                  </React.Fragment>
                ))}
              </PlanTableRow>
            </React.Fragment>
          ))}
          {plans[0].module.map((module, moduleIndex) => (
            <>
              <PlanTableRow key='module-row'>
                <ModuleNameCell component='th' scope='row'>
                  {module.name}
                </ModuleNameCell>
                <MobileModuleNameCell component='th' scope='row' colSpan={3}>
                  {module.name}
                </MobileModuleNameCell>
                {plans.map((plan, index) => (
                  <React.Fragment key={plan.name}>
                    <PlanTableCell borderColor={plan.color}></PlanTableCell>
                    {index !== plans.length - 1 && <SpacerCell />}
                  </React.Fragment>
                ))}
              </PlanTableRow>
              {plans[planIndex].module[moduleIndex].functionalities.map(
                (moduleFunc, moduleFuncIndex) => (
                  <>
                    <MobilePlanTableRow key={`${moduleFunc.name}.mobile`}>
                      <MobilePlanFunctionalityCell
                        colSpan={3}
                        component='th'
                        scope='row'
                      >
                        <PlanTooltipContainer>
                          {moduleFunc.name}
                          {moduleFunc.helperText && (
                            <Tooltip
                              enterTouchDelay={50}
                              title={moduleFunc.helperText}
                              placement='bottom'
                            >
                              <PlanTooltipIcon />
                            </Tooltip>
                          )}
                        </PlanTooltipContainer>
                      </MobilePlanFunctionalityCell>
                    </MobilePlanTableRow>

                    <PlanTableRow key={moduleFunc.name}>
                      <PlanFunctionalityCell
                        backgroundColor={
                          moduleFuncIndex % 2 === 0 ? '#FFFFFF' : '#EFEFEF'
                        }
                        component='th'
                        scope='row'
                      >
                        <PlanTooltipContainer>
                          {moduleFunc.name}
                          {moduleFunc.helperText && (
                            <Tooltip
                              enterTouchDelay={50}
                              title={moduleFunc.helperText}
                              placement='right-start'
                            >
                              <PlanTooltipIcon />
                            </Tooltip>
                          )}
                        </PlanTooltipContainer>
                      </PlanFunctionalityCell>
                      {plans.map((plan, index) => (
                        <React.Fragment key={plan.name}>
                          <PlanTableCell
                            backgroundColor={
                              moduleFuncIndex % 2 === 0 ? '#FFFFFF' : '#EFEFEF'
                            }
                            borderColor={plan.color}
                          >
                            {getModuleFunctionalities(
                              plan,
                              moduleFunc.name,
                              moduleIndex
                            )}
                          </PlanTableCell>
                          {index !== plans.length - 1 && <SpacerCell />}
                        </React.Fragment>
                      ))}
                    </PlanTableRow>
                  </>
                )
              )}
            </>
          ))}
          <PlanTableRow key='button-row'>
            <PlanFunctionalityCell
              component='th'
              scope='row'
            ></PlanFunctionalityCell>
            {plans.map((plan, index) => (
              <React.Fragment key={plan.name}>
                <ButtonTableCell
                  borderColor={plan.currentPlan ? '#6A6A6A' : plan.color}
                >
                  <BuyButton
                    backgroundColor={plan.currentPlan && '#6A6A6A'}
                    variant='text'
                    disabled={plan.currentPlan}
                    onClick={plan.buttonAction}
                  >
                    {plan.currentPlan
                      ? t('buttonLabel.currentPlan')
                      : plan.name === 'Enterprise'
                        ? t('buttonLabel.planEnterprise')
                        : t('buttonLabel.selectPlan')}
                  </BuyButton>
                </ButtonTableCell>
                {index !== plans.length - 1 && <SpacerCell />}
              </React.Fragment>
            ))}
          </PlanTableRow>
        </TableBody>
      </Table>
    </PlanTableContainer>
  );
}
