import { Box, LinearProgress, Typography, styled } from '@mui/material';

export const RegisterFormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingLeft: theme.typography.pxToRem(20),
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.typography.pxToRem(0)
  }
}));

export const RegisterFormWizard = styled(Box)<{ fullscreen: string }>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(6),
    marginTop: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
      padding: 20,
      marginTop: theme.spacing(1)
    }
  })
);

export const RegisterFormFooter = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const RegisterFormHeaderNav = styled(Box)<{ fullscreen: string }>(
  ({ theme, fullscreen }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      width: fullscreen === 'true' ? '90vw' : '50vw'
    },
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    }
  })
);

export const LogoContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

export const RegisterFormWizardHeader = styled(Box)(() => ({}));

export const RegisterFormWizardBody = styled(Box)<{ fullscreen: string }>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  })
);

export const RegisterFormWizardTitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.8),
  fontSize: theme.typography.pxToRem(33),
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(24),
    paddingBottom: theme.spacing(2)
  }
}));

export const RegisterProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%'
}));
