import { ServiceCommand } from '~/app/core/domain/command/service-command';
import { httpClient } from '~/app/core/infra/http/axios-http-client-adapter';

import { AUTH_API_ROUTES } from '../../api/routes';
import { GetSubscriptionById, GetSubscription } from './get-subscription-by-id';

export const getSubscriptionService: ServiceCommand<GetSubscription.Response> =
  new GetSubscriptionById(httpClient, AUTH_API_ROUTES.GET_SUBSCRIPTION);

export type { GetSubscriptionById };
