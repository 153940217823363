import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { httpClient } from '../../../../../core/infra/http/axios-http-client-adapter';
import { AUTH_API_ROUTES } from '../../api/routes';
import { ChangePassword } from './change-password';
import { Password } from './password';

export const passwordService: ServiceCommand<Password.Response> = new Password(
  httpClient,
  AUTH_API_ROUTES.FORGOT_PASSWORD
);

export const changePasswordService: ServiceCommand<ChangePassword.Response> =
  new ChangePassword(httpClient, AUTH_API_ROUTES.RESET_PASSWORD);

export type { Password, ChangePassword };
