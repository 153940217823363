import { Link } from 'react-router-dom';

import { Box, Typography, styled } from '@mui/material';
import MuiButton from '@mui/material/Button';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';

export const Container = styled(Box)(() => ({
  display: 'flex',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden'
}));

export const RightContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const LeftContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'normal',
  textAlign: 'left',
  // justifyContent: 'center',
  gap: theme.spacing(2),
  width: '100%',
  // height: '100%',
  marginLeft: '3%',
  [theme.breakpoints.down('md')]: {
    // marginTop: '10%',
    gap: theme.spacing(2),
    alignItems: 'center',
    padding: '0 10% 0 10%',
    marginLeft: 'unset'
  }
}));

export const BoxAlign = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  maxWidth: theme.spacing(60),
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(12)
  }
}));

export const TextEmailSend = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  margin: theme.spacing(3, 0, 1, 0),
  maxWidth: theme.spacing(60),
  width: '100%',
  fontWeight: 'bold'
}));

export const TextResend = styled(Link)(({ theme }) => ({
  top: theme.spacing(3),
  whiteSpace: 'nowrap',
  maxWidth: theme.spacing(48),
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    top: theme.spacing(0)
  }
}));

export const ResendEmail = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginLeft: '3%',
  marginBottom: '40%',
  [theme.breakpoints.down('md')]: {
    display: 'inline'
  }
}));

export const Text = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.text.primary,
  wordBreak: 'normal',
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(0),
    wordBreak: 'unset'
  }
}));

export const BoxTop = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.grey[800],
  width: '100%',
  justifyContent: 'left',
  marginTop: theme.spacing(7),
  fontSize: theme.typography.pxToRem(13),
  gap: theme.spacing(3),
  padding: theme.spacing(0),
  textTransform: 'none',
  ':hover': {
    background: 'none'
  },
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    marginTop: '-1%',
    marginRight: '5%'
  }
}));

export const Button = styled(BaseButton)(({ theme }) => ({
  maxWidth: theme.spacing(60),
  width: '100%',
  textTransform: 'none'
}));
