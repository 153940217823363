import { Link } from 'react-router-dom';

import { Box, Button, Drawer, IconButton, styled } from '@mui/material';
import MuiButton from '@mui/material/Button';

export const Container = styled(Box)(() => ({}));

export const ToolbarStyle = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(8),
  marginRight: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  }
}));

export const DesktopMenu = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const LogoLink = styled(Link)(() => ({
  flex: 1,
  justifyContent: 'flex-start'
}));

export const CenterLinks = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: theme.spacing(3),
  // minWidth: '400px',
  // width: '4000px',
  // marginLeft: theme.spacing(30),
  paddingRight: theme.spacing(4),
  gap: theme.spacing(4)
}));

export const AuthButtonsContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  justifyContent: 'flex-end'
}));

export const MobileMenu = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export const LinkStyled = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
  textDecoration: 'none'
}));

export const SigninLink = styled(LinkStyled)(({ theme }) => ({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  marginLeft: theme.spacing(0)
}));

export const InicialLink = styled(LinkStyled)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
  textUnderlineOffset: theme.spacing(0.6),
  textDecorationThickness: theme.spacing(0.2)
}));

export const ButtonStyled = styled(MuiButton)(({ theme }) => ({
  border: 'none',
  borderRadius: theme.spacing(2),
  width: theme.spacing(23),
  textTransform: 'none',
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.common.white,
  background: theme.palette.primary.main
}));

export const IconButtonMobile = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main
}));

export const DrawerMobile = styled(Drawer)(() => ({
  marginTop: '60%'
}));

export const ListItemMenu = styled(Button)(() => ({
  textTransform: 'none'
}));
