import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { MeResponseModel } from '../../domain/models/me-response-model';

export class Me implements ServiceCommand<Me.Response> {
  constructor(
    private readonly httpClient: HttpClient<Me.Response>,
    private readonly url: string
  ) {}

  async execute(): Promise<ServiceCommand.Response<Me.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.GET,
      url: this.url
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace Me {
  export type Response = MeResponseModel;
}
