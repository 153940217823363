import { useNavigate, useSearchParams } from 'react-router-dom';

import { ToastType } from '~/app/core/presentation/common/types/toast-types';
import { useAuth } from '~/app/core/presentation/hooks/use-auth';
import { useToast } from '~/app/core/presentation/hooks/use-toast';
import FormProvider from '~/app/core/presentation/providers/form-provider';

import BaseAuthLayout from '../../../components/base-auth-layout/base-auth-layout';
import { ChangePasswordForm } from '../../domain/models/change-password-model';
import { AuthRoutesPath } from '../../routes/routes';
import {
  useChangePasswordMutation,
  useUpdateMeMutation
} from '../../store/hooks';
import { useLoginLogic } from '../login/login';
import FormResetPassword from './form-reset-password/form-reset-password';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [updateMe] = useUpdateMeMutation();

  const { meRequest } = useLoginLogic();

  const navigate = useNavigate();
  const { addToast } = useToast();

  const redirect = () => {
    navigate(AuthRoutesPath.LOGIN);
  };

  const requestChangePassword = (values: ChangePasswordForm) => {
    changePassword(values)
      .unwrap()
      .then(() => {
        redirect();
      })
      .catch((error) => {
        addToast({
          type: ToastType.ERROR,
          text: error.message
        });
      });
  };

  const { getLogin } = useAuth();

  const tokens = getLogin();

  const updatePassword = async (newPassword: string) => {
    return updateMe({ password: newPassword })
      .unwrap()
      .then(() => {
        meRequest({
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken
        });
      })
      .catch((error) => {
        addToast({
          type: ToastType.ERROR,
          text: error.message
        });
      });
  };

  const handleSubmit = async (values: { password: string }) => {
    if (token) {
      requestChangePassword({
        password: values.password,
        token: token
      });
    } else {
      updatePassword(values.password);
    }
  };

  return (
    <BaseAuthLayout
      leftChildren={
        <FormProvider mode='onSubmit'>
          <FormResetPassword onSubmit={handleSubmit} {...{ isLoading }} />
        </FormProvider>
      }
      rightChildren={<img src='/assets/images/forgot-password.svg' />}
    />
  );
};

export default ResetPassword;
