import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RegisterRoutes } from '~/app/application/features/auth/routes/routes';
import { useContactSalesMutation } from '~/app/application/features/auth/store/hooks';
import { useRegisterUserDataStore } from '~/app/application/features/auth/store/slice/register-user-data-slice';
import { useSuccessSetting } from '~/app/application/features/auth/store/slice/success-setting-slice';
import CompanyNameTextField from '~/app/application/features/components/company-name-text-field/company-name-text-field';
import PhoneTextField from '~/app/application/features/components/phone-text-field/phone-text-field';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';

import {
  FormContainer,
  ContactEnterpriseContainer,
  ContactDescriptionText,
  ContactTitle
} from './contact-enterprise-step.stypes';
import { ContactEnterpriseValidator } from './contact-enterprise.validator';

type ContactEnterpriseStepProps = {
  comeBack: (data: { onClick: () => void }) => void;
};

const ContactEnterpriseStep = ({ comeBack }: ContactEnterpriseStepProps) => {
  const { registerState, setRegisterState } = useRegisterUserDataStore();
  const { t } = useTranslation(['register']);
  const navigate = useNavigate();
  const [contactSales, { isLoading }] = useContactSalesMutation();
  const { setSuccessSetting } = useSuccessSetting();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid }
  } = useForm({
    resolver: ContactEnterpriseValidator,
    mode: 'onChange'
  });

  interface ContactEnterpriseProps {
    companyName?: string;
    phone?: string;
  }

  const handleSubmitForm = (values: ContactEnterpriseProps) => {
    contactSales({
      companyName: values.companyName,
      email: registerState.user.email,
      personalPhone: values.phone.replace(/\D/g, ''),
      campaignName: 'novo_self_onboarding',
      carQuantityText: registerState.carQuantity
    })
      .unwrap()
      .then(() => {
        setRegisterState({
          isEnterpriseContact: true,
          companies: [
            {
              name: values.companyName,
              phones: [
                {
                  countryCode: '55',
                  regionCode: values?.phone?.replace(/\D/g, '').substring(0, 2),
                  phone: values?.phone?.replace(/\D/g, '').substring(2)
                }
              ]
            }
          ]
        });
        setSuccessSetting({
          status: 'success',
          title: t('titleSetting'),
          description: t('descriptionSetting')
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onContinueRegister = () => {
    comeBack(null);
    navigate(RegisterRoutes.COMPANY_DATA);
  };

  return (
    <ContactEnterpriseContainer
      component='form'
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <ContactTitle>{t('title')}</ContactTitle>
      <div>
        <ContactDescriptionText
          dangerouslySetInnerHTML={{
            __html: t('textContactParagraphOne')
          }}
        />
        <ContactDescriptionText
          dangerouslySetInnerHTML={{
            __html: t('textContactParagraphTwo')
          }}
        />
      </div>
      <FormContainer>
        <CompanyNameTextField control={control} />
        <PhoneTextField control={control} />
      </FormContainer>
      <BaseButton
        disableElevation
        id='send-contact-enterprise'
        isLoading={isLoading}
        type='submit'
        variant='contained'
        color='primary'
        disabled={!isDirty || !isValid}
      >
        {t('textButtonSchedule')}
      </BaseButton>
      <BaseButton
        id='continue-registration'
        variant='outlined'
        onClick={onContinueRegister}
        style={{ border: 0 }}
      >
        {t('textButtonComeback')}
      </BaseButton>
    </ContactEnterpriseContainer>
  );
};

export default ContactEnterpriseStep;
