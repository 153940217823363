import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { TextFieldProps } from '@mui/material';

import BaseTextField from '../../../../core/presentation/components/base-text-field/base-text-field';

export type RenavamTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const RenavamMask = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, _ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='00000000000'
        max={11}
        min={1}
        definitions={{
          '0': { mask: /[0-9]/, prepare: (char) => char.padStart(11, '0') }
        }}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

const RenavamTextField = (props: RenavamTextFieldProps) => {
  const { t } = useTranslation(['components', 'errors']);
  return (
    <Controller
      name={props.name}
      control={props.control}
      key={props.key}
      render={({ field: { onChange, value } }) => (
        <BaseTextField
          onChange={onChange}
          value={value || ''}
          id='renavam'
          name='renavam'
          label={t('renavam')}
          variant='filled'
          type='text'
          required
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: RenavamMask as any
          }}
          {...props}
        />
      )}
    />
  );
};

export default RenavamTextField;
