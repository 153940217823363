import { useTranslation } from 'react-i18next';

import { ToastType } from '~/app/core/presentation/common/types/toast-types';
import { useToast } from '~/app/core/presentation/hooks/use-toast';

import { useAuthorizationUrlMutation } from '../../store/hooks';
import { ButtonAccess } from './access-google-styles';

interface Props {
  isRegister?: boolean;
  showButton?: boolean;
}

const LoginOrRegisterWithGoogle = ({ isRegister, showButton }: Props) => {
  const { t } = useTranslation(['login']);
  const [authorization] = useAuthorizationUrlMutation();
  const { addToast } = useToast();

  const getAuthorizationUrl = () => {
    authorization('')
      .unwrap()
      .then((response) => {
        window.location.assign(response.authorizationUrl);
      })
      .catch((error) => {
        addToast({
          type: ToastType.ERROR,
          text: error.message
        });
      });
  };

  return (
    showButton && (
      <ButtonAccess variant='text' onClick={getAuthorizationUrl}>
        <img src='/assets/images/google.png' />
        {isRegister ? t('registerGoogle') : t('loginGoogle')}
      </ButtonAccess>
    )
  );
};

export default LoginOrRegisterWithGoogle;
