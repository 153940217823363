import { Box, IconButton, Typography, styled } from '@mui/material';

export const PaymentPlanBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: theme.typography.pxToRem(24),
  gap: theme.spacing(4)
}));

export const PaymentPlanBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    height: '300px'
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(2)
  }
}));

export const IconButtonStyled = styled(IconButton)(() => ({
  position: 'absolute',
  top: 20,
  left: 20
}));

export const PaymentPlanContainer = styled(Box)(({ theme }) => ({
  padding: 20,
  display: 'flex',
  width: '100%',
  alignContent: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    paddingTop: 40
  }
}));

export const CarQuantityContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '70%',
  maxWidth: '70%',
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    maxWidth: 'initial',
    width: '100%',
    padding: '0 20px'
  }
}));

export const RootBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '1440px',
  width: '100%',
  justifyContent: 'center',
  gap: theme.spacing(1),
  marginBottom: 80,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column'
  }
}));

export const CarQuantityInputContainer = styled(Box)(() => ({
  width: 150
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(4)
}));

export const BackAndLogoBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'flex-start'
}));

export const LogoBox = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center'
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  fontWeight: 500,
  minWidth: '70%',
  maxWidth: '70%',
  whiteSpace: 'pre-wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    minWidth: '90%',
    maxWidth: '90%'
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: 'initial',
    width: '100%',
    padding: '0 20px'
  }
}));

export const PlatesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `${theme.typography.pxToRem(1)} solid #A3A3A3`,
  borderRadius: theme.typography.pxToRem(8),
  width: '90%',
  [theme.breakpoints.up('md')]: {
    width: theme.typography.pxToRem(699)
  }
}));

export const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minWidth: '70%',
  maxWidth: '70%',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    minWidth: '90%',
    maxWidth: '90%'
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: 'initial',
    width: '100%',
    padding: '0 20px'
  }
}));

export const CreditCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minWidth: '70%',
  maxWidth: '70%',
  boder: `${theme.typography.pxToRem(1)} solid #A3A3A3`,
  borderRadius: theme.typography.pxToRem(8),
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    minWidth: '90%',
    maxWidth: '90%'
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: 'initial',
    padding: '0 20px'
  }
}));
