import { RequestResponse } from '../../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../../core/domain/either/either';
import { PlanResponseModel } from '../../../domain/models/plans/plan-response-model';

export class GetPlans implements ServiceCommand<GetPlans.Response> {
  constructor(
    private readonly httpClient: HttpClient<GetPlans.Response>,
    private readonly url: string
  ) {}

  async execute(
    _: GetPlans.Params
  ): Promise<ServiceCommand.Response<GetPlans.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.GET,
      url: this.url
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace GetPlans {
  export type Params = object;

  export type Response = PlanResponseModel[];
}
