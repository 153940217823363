import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMediaQuery } from '@mui/material';
import { APP_ENV } from '~/env';

import Logo from '../../../../../../core/presentation/components/logo/logo';
import FormProvider from '../../../../../../core/presentation/providers/form-provider';
import {
  CompanyResponseModel,
  UserCompanyResponseModel
} from '../../../domain/models/companies/company-response-model';
import { SignUpModel } from '../../../domain/models/sign-up-model';
import { AuthRoutesPath, RegisterRoutes } from '../../../routes/routes';
import { useGetPlansMutation } from '../../../store/hooks';
import { useRegisterUserDataStore } from '../../../store/slice/register-user-data-slice';
import ModalDetectMobile from '../../login/modal-detect-mobile/modal-detect-mobile';
import CompanyDataStep from './company-data-step/company-data-step';
import {
  LogoContainer,
  RegisterFormContainer,
  RegisterFormHeaderNav,
  RegisterFormWizard,
  RegisterFormWizardBody,
  RegisterFormWizardHeader,
  RegisterFormWizardTitle,
  RegisterProgress
} from './form-register-styles';
import PersonalDataStep from './personal-data-step/personal-data-step';
import SelectPlanStep from './select-plan-step/select-plan-step';
import VehicleStep from './vehicle-step/vehicle-step';

export type SignUpFormDataProps = {
  user: SignUpModel;
  company?: CompanyResponseModel;
  isAuthenticated: boolean;
};

export type RegisterState = {
  user?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    active?: boolean;
  };
  companies: UserCompanyResponseModel[];
  vehiclesCount: number;
  isAuthenticated : boolean;
};

export type VehicleData = {
  placa: string;
  renavam: string;
  chassis?: string;
};

const FormRegister = () => {
  const location = useLocation();
  const { registerState, setRegisterState } = useRegisterUserDataStore();
  const [getPlans] = useGetPlansMutation();
  const [comeBack, setComeBack] = useState(null);

  const handleComeBack = (data: { onClick: () => void }) => {
    setComeBack(data);
  };

  const isMobile = useMediaQuery('(max-width: 920px)');

  const [formData, setFormData] = useState<SignUpFormDataProps>({
    user: {
      firstName: registerState?.user?.firstName || '',
      lastName: registerState?.user?.lastName || '',
      email: registerState?.user?.email || ''
    },
    isAuthenticated: registerState.isAuthenticated || false
  });

  const { t } = useTranslation(['register-personal-step', 'register']);
  const navigate = useNavigate();

  const returnToPreviousPage = () => {
    navigate(-1);
  };

  const handleFormData = (values: SignUpFormDataProps) => {
    setFormData(values);
  };

  // TODO: remover essa gambiarra
  useEffect(() => {
    if ( location.pathname !== RegisterRoutes.PERSONAL_DATA && !formData.isAuthenticated ) {
      navigate(AuthRoutesPath.LOGIN);
    }
  }, [formData, navigate, location.pathname]);

  useEffect(() => {
    getPlans({})
      .unwrap()
      .then((response) => {
        setRegisterState({ plans: response });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setOpen(isMobile);
  }, [isMobile]);

  const [stateTimer, setStateTimer] = useState<string>('Active');
  const [count, setCount] = useState<number>(0);
  const [, setRemaining] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [firstTimeOpen, setFirstTimeOpen] = useState(true);

  const onIdle = () => {
    isMobile && setOpen(true);
    setStateTimer('Idle');
  };

  const onActive = () => {
    setStateTimer('Active');
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const timeInactivity = parseInt(APP_ENV.TIME_INACTIVITY || '25000');

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: timeInactivity,
    throttle: 500,
    stopOnIdle: true
  });

  const steps = [
    {
      path: RegisterRoutes.PERSONAL_DATA,
      component: (
        <PersonalDataStep
          formData={formData}
          handleFormData={handleFormData}
          comeBack={handleComeBack}
        />
      ),
      isFullScreen: 'false'
    },
    // Vou deixar isso aqui comentado para caso precise voltar como era antigamente.
    // {
    //   title: t('register:validateCodeTitle'),
    //   component: (
    //     <ValidateCodeStep handleNextStep={handleNextStep} formData={formData} />
    //   ),
    //   imagePath: '/assets/images/code-verification-step.svg'
    // },
    {
      title: t('register:companyDataTitle'),
      path: RegisterRoutes.COMPANY_DATA,
      component: (
        <CompanyDataStep handleFormData={handleFormData} formData={formData} />
      ),
      isFullScreen: 'false'
    },
    {
      path: RegisterRoutes.VEHICLES_DATA,
      component: <VehicleStep formData={formData} />,
      isFullScreen: 'false'
    },
    {
      path: RegisterRoutes.SELECT_PLAN,
      component: <SelectPlanStep />,
      isFullScreen: 'true'
    }
  ];

  const getCurrentStep = () => {
    return steps.find(
      (step) =>
        location.pathname.replace(/\/$/, '') === step.path.replace(/\/$/, '')
    );
  };

  const currentStep = getCurrentStep();

  const stepsNumber = steps.length;
  const fullScreen = currentStep?.isFullScreen || 'false';

  useEffect(() => {
    if (steps.indexOf(currentStep) > 0) {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [currentStep]);

  return (
    <>
      <ModalDetectMobile
        open={
          isMobile &&
          (firstTimeOpen || (open && stateTimer !== 'Active')) &&
          steps.indexOf(currentStep) > 0
        }
        onClose={() => {
          if (!firstTimeOpen) setOpen(false);

          setFirstTimeOpen(false);
        }}
      />
      <FormProvider mode='onBlur'>
        <RegisterFormContainer>
          <RegisterFormWizard fullscreen={fullScreen}>
            <RegisterFormWizardHeader>
              <RegisterFormHeaderNav fullscreen={fullScreen}>
                {currentStep &&
                  currentStep.path !== RegisterRoutes.SELECT_PLAN &&
                  (comeBack ? (
                    <ArrowBackIcon
                      onClick={() => {
                        comeBack.onClick();
                        setComeBack(null);
                      }}
                    />
                  ) : (
                    <ArrowBackIcon onClick={returnToPreviousPage} />
                  ))}
                <LogoContainer>
                  <Logo />
                </LogoContainer>
                <div>
                  {currentStep
                    ? `${steps.indexOf(currentStep) + 1}/${stepsNumber}`
                    : ''}
                </div>
              </RegisterFormHeaderNav>
              <RegisterProgress
                color='success'
                variant='determinate'
                value={
                  currentStep
                    ? ((steps.indexOf(currentStep) + 1) / stepsNumber) * 100
                    : 0
                }
              />
            </RegisterFormWizardHeader>
            <RegisterFormWizardBody fullscreen={fullScreen}>
              {currentStep && (
                <>
                  <RegisterFormWizardTitle>
                    {currentStep.title}
                  </RegisterFormWizardTitle>
                  {currentStep.component}
                </>
              )}
            </RegisterFormWizardBody>
          </RegisterFormWizard>
        </RegisterFormContainer>
      </FormProvider>
    </>
  );
};

export default FormRegister;
