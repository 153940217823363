import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextFieldProps } from '@mui/material';

import BaseTextField from '../../../../core/presentation/components/base-text-field/base-text-field';

export type FullNameTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
};

const FullNameTextField = (props: FullNameTextFieldProps) => {
  const { t } = useTranslation(['components', 'errors']);
  return (
    <Controller
      name='fullName'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseTextField
          onChange={onChange}
          value={value || ''}
          error={!!error}
          helperText={
            error ? props.errorText ?? t('errors:fullNameInvalid') : undefined
          }
          id='fullName'
          name='fullName'
          label={t('fullName')}
          variant='filled'
          type='fullName'
          inputProps={{ maxLength: 256 }}
          required
        />
      )}
    />
  );
};

export default FullNameTextField;
