import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { SubscriptionModel } from '../../domain/models/subscriptions/create-subscription-model';
import { SubscriptionResponseModel } from '../../domain/models/subscriptions/create-subscription-response-model';

export class CreateSubscription
  implements ServiceCommand<CreateSubscription.Response>
{
  constructor(
    private readonly httpClient: HttpClient<CreateSubscription.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: CreateSubscription.Params
  ): Promise<ServiceCommand.Response<CreateSubscription.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export const storeEmail = (email: string) =>
  sessionStorage.setItem('forgot-password-email', email);

export const getStoreEmail = () =>
  sessionStorage.getItem('forgot-password-email');

export namespace CreateSubscription {
  export type Params = SubscriptionModel;

  export type Response = SubscriptionResponseModel;
}
