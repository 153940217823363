import { ServiceCommand } from '~/app/core/domain/command/service-command';
import { authHttpClient } from '~/app/core/infra/http/axios-auth-http-client-adapter';

import { AUTH_API_ROUTES } from '../../api/routes';
import { Me } from './me';

export const meService: ServiceCommand<Me.Response> = new Me(
  authHttpClient,
  AUTH_API_ROUTES.UPDATE_ME
);

export type { Me };
