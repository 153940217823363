import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import {
  BoxModalTermsOfService,
  ContainerHeader,
  DialogTitleStyled
} from './modal-terms-of-service-styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const terms = {
  en: {
    title: 'FROTA 162 Tecnologia e Intermediação Ltda Terms of service',
    terms: [
      {
        title: 'Acceptance of Terms of Service',
        description:
          'By accessing, using, or subscribing to Frota 162, you affirmatively accept and agree to comply with and be legally bound by these Terms of Service, including all modifications and future revisions. This acceptance is effective from the moment of your first access or use of the service. If, at any time, you do not agree with any part of the terms set forth here, you must immediately cease all use and access to Frota 162.'
      },
      {
        title: 'Detailed Service Description',
        description:
          'Frota 162 is designed as a comprehensive Software as a Service (SaaS) solution specialized in efficient fleet management. This solution encompasses a broad spectrum of features, including advanced fleet-related document management, proactive monitoring, and administration of traffic fines and violations, as well as driver data control and management. The platform aims to optimize fleet operability, ensuring regulatory compliance and maximizing administrative efficiency. It is important to note that while this description covers the main components of the service, the offered functionalities may expand beyond those mentioned, adapting to emerging market needs and technological innovations.'
      },
      {
        title: 'User Registration and Associated Responsibilities',
        description:
          'When you register on Frota 162, you commit to providing authentic, accurate, and current information about yourself, as required in the registration process. The maintenance of the accuracy and timeliness of this information is your continuous responsibility. Additionally, you are fully responsible for all activities conducted under your account, including the protection and confidentiality of your password and access. Any unauthorized or suspicious use of your account must be immediately reported to Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA. Negligence in maintaining the security and confidentiality of your account may result in direct liability for any consequences or damages that may arise.'
      },
      {
        title: 'Commitment to Privacy and Data Security',
        description:
          'Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, the entity responsible for Frota 162, is firmly committed to the protection of the privacy and security of personal data of its users. This commitment is in full compliance with the guidelines and requirements stipulated by the General Data Protection Law (LGPD) of Brazil. Our Privacy Policy, available for consultation, provides a detailed insight into how we collect, use, process, and protect your personal data. We encourage all users to review this policy to fully understand the adopted privacy practices and their respective rights regarding the processing of their personal data.'
      },
      {
        title: 'Guidelines for Service Use',
        description:
          'The use of Frota 162 must be conducted ethically, responsibly, and strictly within the bounds of legality. By using this service, you commit not to employ it for any purpose that is illegal, fraudulent, or in any way infringes or violates the rights of third parties, including but not limited to intellectual property, privacy, and copyright rights. This obligation includes the prohibition of using the service for the dissemination of malicious, deceptive, or harmful content of any kind. Non-compliance with these guidelines may result in suspension or termination of your access to the service, as well as possible legal actions.'
      },
      {
        title: 'Protection of Intellectual Property Rights',
        description:
          'The content, including but not limited to texts, graphics, user interfaces, visual trademarks, logos, photographs, trademarks, sounds, music, art, interface expressions, and software, as well as all functionality and underlying code associated with Frota 162, are the exclusive property of Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA. These elements are protected by national and international copyright, patent, and intellectual property laws. The use of this service does not grant you any ownership or license rights to any content, code, data, or materials you may access or use through Frota 162. Any reproduction, distribution, modification, adaptation, translation, creation of derivative works, public display, transmission, or commercial exploitation of these assets without the prior and express permission of Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA is strictly prohibited and may result in legal action.'
      },
      {
        title: 'Structure and Payment Conditions',
        description:
          'The services provided by Frota 162 are priced according to a transparent and clearly outlined pricing structure, which is available for consultation on our website. By choosing to use the service, you agree to make payments according to the specified terms and conditions. This includes compliance with payment deadlines, adherence to accepted payment methods, and adherence to any additional financial terms that may apply. Failure to comply with these payment terms may result in the interruption or cancellation of access to the service. It is important to periodically check the website to stay updated on possible changes to the pricing structure.'
      },
      {
        title: 'Changes in Terms of Service',
        description:
          'Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, the holder and operator of Frota 162, reserves the right to, at its sole discretion, revise, alter, or modify these Terms of Service at any time. Any change or modification will be effective immediately upon its publication on the Frota 162 website. It is your responsibility to regularly review this section to stay informed about any updates. The continued use of the service after the publication of such changes will constitute your acceptance and agreement to the changes. If you do not agree with the new Terms, you should cease using the service.'
      },
      {
        title: 'Disclaimer',
        description:
          'Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA will not assume responsibility for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access or use, or inability to access or use Frota 162; (ii) any conduct or content of third parties in the service; or (iii) unauthorized access, use, or alteration of your transmissions or content, even if the possibility of such damages has been communicated to Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA. This limitation of liability applies to the maximum extent permitted by applicable law and does not exclude or limit the mandatory liability of Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA for fraud or gross negligence, nor any other liability that cannot be excluded or limited by law.'
      },
      {
        title: 'Conditions for Termination of Use',
        description:
          'Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA reserves the right to terminate or suspend your account and access to Frota 162 with immediate effect, without the need for prior notice, if any violation of the Terms of Service set forth here is identified. This termination or suspension action may be taken without prejudice to any other rights or legal remedies available to Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA under the law. The suspension or termination of the account will imply the cessation of access to the service and, in certain circumstances, may include the deletion of your profile and associated content. It is important to understand that the violation of the Terms of Service may also result in additional legal consequences. Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA commits to act fairly and reasonably in such circumstances, considering the nature of the violation.'
      },
      {
        title: 'Jurisdiction and Governing Law',
        description:
          'These Terms of Service and the relationship between you and Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, regarding Frota 162, will be governed and interpreted in accordance with the laws of the Federative Republic of Brazil. You unequivocally agree that any legal dispute or claim arising or related to these Terms or the use of Frota 162 will be exclusively resolved by the courts of Brazil. This provision establishes the exclusive jurisdiction of Brazilian courts for such matters, and you waive any objection to such jurisdiction or venue. The adoption of this clause aims to ensure a coherent and predictable legal resolution for both parties.'
      },
      {
        title: 'Integrity and Severability of the Agreement',
        description:
          'This document represents the complete and exclusive agreement between you, the user, and Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, regarding the use of Frota 162, and replaces all previous communications, proposals, and agreements, both written and oral, related to the subject matter hereof. If any provision of these Terms of Service is found to be invalid, illegal, or unenforceable by a competent court of jurisdiction, the validity and enforceability of the other provisions will remain in full force and effect. The invalid or unenforceable provision will be replaced by a valid provision that most closely matches the original intent of the unenforceable provision, thus maintaining the overall intent and integrity of these Terms.'
      }
    ]
  },
  es: {
    title: 'FROTA 162 Tecnologia e Intermediação Ltda Términos de servicio',
    terms: [
      {
        title: 'Aceptación de los Términos de Servicio',
        description:
          'Al acceder, utilizar o suscribirte a Frota 162, aceptas de manera afirmativa y te comprometes a cumplir y estar legalmente vinculado a estos Términos de Servicio, incluyendo todas las modificaciones y revisiones futuras. Esta aceptación es efectiva desde el momento de tu primer acceso o uso del servicio. Si, en cualquier momento, no estás de acuerdo con alguna parte de los términos establecidos aquí, debes cesar inmediatamente todo uso y acceso a Frota 162.'
      },
      {
        title: 'Descripción Detallada del Servicio',
        description:
          'Frota 162 está diseñado como una solución integral de Software como Servicio (SaaS) especializada en la gestión eficiente de flotas. Esta solución abarca un amplio espectro de funciones, que incluyen: gestión avanzada de documentos relacionados con la flota, monitoreo proactivo y administración de multas e infracciones de tránsito, así como control y gestión de datos de los conductores. La plataforma busca optimizar la operatividad de la flota, asegurando el cumplimiento normativo y maximizando la eficiencia administrativa. Es importante señalar que, aunque esta descripción abarca los principales componentes del servicio, las funcionalidades ofrecidas pueden expandirse más allá de las mencionadas, adaptándose a las necesidades emergentes del mercado y las innovaciones tecnológicas.'
      },
      {
        title: 'Registro de Usuario y Responsabilidades Asociadas',
        description:
          'Al registrarte en Frota 162, te comprometes a proporcionar información auténtica, precisa y actualizada sobre ti mismo, según lo requerido en el proceso de registro. El mantenimiento de la precisión y actualidad de esta información es tu responsabilidad continua. Además, eres completamente responsable de todas las actividades realizadas bajo tu cuenta, incluida la protección y confidencialidad de tu contraseña y acceso. Cualquier uso no autorizado o sospechoso de tu cuenta debe ser notificado inmediatamente a Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA. La negligencia en mantener la seguridad y confidencialidad de tu cuenta puede resultar en responsabilidad directa por cualquier consecuencia o daño que pueda surgir.'
      },
      {
        title: 'Compromiso con la Privacidad y Seguridad de Datos',
        description:
          'Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, responsable de Frota 162, se compromete firmemente a la protección de la privacidad y seguridad de los datos personales de sus usuarios. Este compromiso está en total conformidad con las pautas y requisitos establecidos por la Ley General de Protección de Datos (LGPD) de Brasil. Nuestra Política de Privacidad, disponible para su consulta, proporciona una visión detallada sobre cómo recopilamos, utilizamos, procesamos y protegemos tus datos personales. Alentamos a todos los usuarios a revisar esta política para comprender completamente las prácticas de privacidad adoptadas y sus respectivos derechos con respecto al tratamiento de sus datos personales.'
      },
      {
        title: 'Directrices para el Uso del Servicio',
        description:
          'El uso de Frota 162 debe realizarse de manera ética, responsable y estrictamente dentro de los límites de la legalidad. Al utilizar este servicio, te comprometes a no utilizarlo con fines ilegales, fraudulentos o que de alguna manera infrinjan o violen los derechos de terceros, incluidos, entre otros, los derechos de propiedad intelectual, privacidad y derechos de autor. Esta obligación incluye la prohibición del uso del servicio para la difusión de contenido malicioso, engañoso o perjudicial de cualquier índole. El incumplimiento de estas directrices puede resultar en la suspensión o terminación de tu acceso al servicio, así como posibles acciones legales.'
      },
      {
        title: 'Protección de los Derechos de Propiedad Intelectual',
        description:
          'El contenido, incluidos, entre otros, textos, gráficos, interfaces de usuario, marcas visuales, logotipos, fotografías, marcas comerciales, sonidos, música, arte, expresiones de interfaz y software, así como toda la funcionalidad y código subyacente asociado a Frota 162, son propiedad exclusiva de Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA. Estos elementos están protegidos por leyes nacionales e internacionales de derechos de autor, patentes y propiedad intelectual. El uso de este servicio no te otorga ningún derecho de propiedad ni licencia sobre ningún contenido, código, datos o materiales a los que puedas acceder o utilizar a través de Frota 162. Cualquier reproducción, distribución, modificación, adaptación, traducción, creación de obras derivadas, exhibición pública, transmisión o explotación comercial de estos activos sin el permiso previo y expreso de Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA está estrictamente prohibida y puede dar lugar a acciones legales.'
      },
      {
        title: 'Estructura y Condiciones de Pago',
        description:
          'Los servicios proporcionados por Frota 162 se tarifican de acuerdo con una estructura de precios transparente y claramente delineada, que está disponible para su consulta en nuestro sitio web. Al optar por utilizar el servicio, aceptas realizar los pagos según los términos y condiciones especificados. Esto incluye el cumplimiento de los plazos de pago, el respeto a las modalidades de pago aceptadas y la adhesión a cualquier término financiero adicional que pueda ser aplicable. El incumplimiento de estos términos de pago puede dar lugar a la interrupción o cancelación del acceso al servicio. Es importante verificar periódicamente el sitio web para mantenerse actualizado sobre posibles cambios en la estructura de precios.'
      },
      {
        title: 'Cambios en los Términos de Servicio',
        description:
          'Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, titular y operador de Frota 162, se reserva el derecho, a su exclusivo criterio, de revisar, alterar o modificar estos Términos de Servicio en cualquier momento. Cualquier cambio o modificación será efectivo inmediatamente después de su publicación en el sitio web de Frota 162. Es tu responsabilidad revisar regularmente esta sección para mantenerte informado sobre cualquier actualización. El uso continuado del servicio después de la publicación de tales cambios constituirá tu aceptación y acuerdo con las modificaciones. Si no estás de acuerdo con las nuevas condiciones de los Términos, debes dejar de utilizar el servicio.'
      },
      {
        title: 'Exoneración de Responsabilidad',
        description:
          'Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA no asumirá responsabilidad por daños indirectos, incidentales, especiales, consecuentes o punitivos, incluyendo, entre otros, pérdida de beneficios, datos, uso, buena voluntad u otras pérdidas intangibles, resultantes de (i) tu acceso o uso, o incapacidad para acceder o usar Frota 162; (ii) cualquier conducta o contenido de terceros en el servicio; o (iii) acceso no autorizado, uso o alteración de tus transmisiones o contenido, incluso si se ha comunicado la posibilidad de tales daños a Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA. Esta limitación de responsabilidad se aplica en la máxima medida permitida por la ley aplicable y no excluye ni limita la responsabilidad obligatoria de Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA por fraude o negligencia grave, ni cualquier otra responsabilidad que no pueda ser excluida o limitada por ley.'
      },
      {
        title: 'Condiciones de Rescisión de Uso',
        description:
          'Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA se reserva el derecho de terminar o suspender tu cuenta y acceso a Frota 162 con efecto inmediato, sin necesidad de notificación previa, en caso de que se identifique alguna violación de los Términos de Servicio aquí establecidos. Esta acción de rescisión o suspensión puede llevarse a cabo sin perjuicio de cualquier otro derecho o recurso legal disponible para Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA según la ley. La suspensión o terminación de la cuenta implicará la cesación del acceso al servicio y, en determinadas circunstancias, puede incluir la eliminación de tu perfil y contenido asociado. Es importante comprender que la violación de los Términos de Servicio también puede conllevar consecuencias legales adicionales. Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA se compromete a actuar de manera justa y razonable en tales circunstancias, considerando la naturaleza de la violación.'
      },
      {
        title: 'Jurisdicción y Legislación Aplicable',
        description:
          'Estos Términos de Servicio y la relación entre tú y Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, con respecto a Frota 162, se regirán e interpretarán de acuerdo con las leyes de la República Federativa del Brasil. Aceptas de manera inequívoca que cualquier disputa legal o reclamación que surja o esté relacionada con estos Términos o el uso de Frota 162 será resuelta exclusivamente por los tribunales de Brasil. Esta disposición establece la jurisdicción exclusiva de los tribunales brasileños para tales cuestiones, y renuncias a cualquier objeción a dicha jurisdicción o lugar. La adopción de esta cláusula tiene como objetivo asegurar una resolución legal coherente y previsible para ambas partes.'
      },
      {
        title: 'Integridad y Divisibilidad del Acuerdo',
        description:
          'Este documento representa el acuerdo completo y exclusivo entre tú, el usuario, y Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, con respecto al uso de Frota 162, y reemplaza todas las comunicaciones, propuestas y acuerdos anteriores, tanto escritos como orales, relacionados con el objeto aquí tratado. Si alguna disposición de estos Términos de Servicio se considera inválida, ilegal o inaplicable por un tribunal de jurisdicción competente, la validez y aplicabilidad de las demás disposiciones permanecerán en pleno vigor y efecto. La disposición inválida o inaplicable será reemplazada por una disposición válida que se aproxime lo más posible a la intención original de la disposición no aplicable, manteniendo así la intención general y la integridad de estos Términos.'
      }
    ]
  },
  'pt-BR': {
    title: 'Termos de Serviço da FROTA 162 Tecnologia e Intermediação Ltda',
    terms: [
      {
        title: 'Aceitação dos Termos de Serviço',
        description:
          'Ao acessar, utilizar ou se inscrever no Frota 162, você, de forma afirmativa, aceita e concorda em cumprir e estar legalmente vinculado a estes Termos de Serviço, incluindo todas as modificações e revisões futuras. Esta aceitação é efetiva desde o momento do seu primeiro acesso ou uso do serviço. Se, a qualquer momento, você não concordar com alguma parte dos termos aqui estabelecidos, você deve imediatamente cessar todo uso e acesso ao Frota 162.'
      },
      {
        title: 'Descrição Detalhada do Serviço',
        description:
          'Frota 162 é projetado como uma solução SaaS (Software as a Service) integral e especializada na gestão eficiente de frotas. Esta solução engloba um espectro amplo de recursos, que incluem: o gerenciamento avançado de documentos relacionados à frota, o monitoramento proativo e a administração de multas e infrações de trânsito, além do controle e gestão de dados dos condutores. A plataforma busca otimizar a operacionalidade da frota, assegurando conformidade regulatória e maximizando a eficiência administrativa. É importante ressaltar que, embora esta descrição abranja os principais componentes do serviço, as funcionalidades oferecidas podem se expandir além das mencionadas, adaptando-se às necessidades emergentes do mercado e às inovações tecnológicas.'
      },
      {
        title: 'Cadastro do Usuário e Responsabilidades Associadas',
        description:
          'Quando você realiza seu cadastro no Frota 162, assume o compromisso de fornecer informações autênticas, precisas e atuais sobre si mesmo, conforme requisitado no processo de registro. A manutenção da precisão e atualidade dessas informações é de sua responsabilidade contínua. Além disso, você é integralmente responsável por todas as atividades conduzidas sob sua conta, incluindo a proteção e a confidencialidade da sua senha e acesso. Qualquer uso não autorizado ou suspeito de sua conta deve ser notificado imediatamente à Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA. A negligência em manter a segurança e confidencialidade da sua conta pode resultar em responsabilidade direta por quaisquer consequências ou danos que daí possam advir.'
      },
      {
        title: 'Compromisso com a Privacidade e Segurança dos Dados',
        description:
          'A Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, responsável pelo Frota 162, estabelece um firme compromisso com a proteção da privacidade e a segurança dos dados pessoais de seus usuários. Este comprometimento está em total conformidade com as diretrizes e requisitos estipulados pela Lei Geral de Proteção de Dados (LGPD) do Brasil. A nossa Política de Privacidade, disponível para consulta, fornece uma visão detalhada sobre como coletamos, utilizamos, processamos e protegemos os seus dados pessoais. Encorajamos todos os usuários a revisarem essa política para compreenderem plenamente as práticas de privacidade adotadas e seus respectivos direitos em relação ao tratamento de seus dados pessoais.'
      },
      {
        title: 'Diretrizes para o Uso do Serviço',
        description:
          'O uso do Frota 162 deve ser conduzido de maneira ética, responsável e estritamente dentro dos limites da legalidade. Ao utilizar este serviço, você se compromete a não empregá-lo para qualquer propósito que seja ilegal, fraudulento, ou que de alguma forma infrinja ou viole os direitos de terceiros, incluindo, mas não se limitando a, direitos de propriedade intelectual, privacidade e direitos autorais. Esta obrigatoriedade inclui a proibição do uso do serviço para a disseminação de conteúdo malicioso, enganoso ou nocivo de qualquer natureza. O descumprimento destas diretrizes pode resultar em suspensão ou término do seu acesso ao serviço, além de possíveis ações legais.'
      },
      {
        title: 'Resguardo dos Direitos de Propriedade Intelectual',
        description:
          'O conteúdo, incluindo mas não se limitando a textos, gráficos, interfaces de usuário, marcas visuais, logos, fotografias, marcas comerciais, sons, música, arte, expressões de interface e software, bem como toda a funcionalidade e o código subjacente associado ao Frota 162, são de propriedade exclusiva da Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA. Estes elementos estão protegidos pelas leis de direitos autorais, patentes e propriedade intelectual, tanto nacionais quanto internacionais. O uso deste serviço não concede a você nenhum direito de propriedade ou licença sobre qualquer conteúdo, código, dados ou materiais que você possa acessar ou utilizar através do Frota 162. Qualquer reprodução, distribuição, modificação, adaptação, tradução, criação de obras derivadas, exibição pública, transmissão ou exploração comercial destes ativos, sem a permissão prévia e expressa da Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, é estritamente proibida e pode resultar em ações legais.'
      },
      {
        title: 'Estrutura e Condições de Pagamento',
        description:
          'Os serviços providos pelo Frota 162 são tarifados de acordo com uma estrutura de preços transparente e claramente delineada, a qual está disponível para consulta no nosso site. Ao optar por utilizar o serviço, você concorda em efetuar os pagamentos conforme os termos e condições especificados. Isto inclui o cumprimento dos prazos de pagamento, o respeito às modalidades de pagamento aceitas e a aderência a qualquer termo financeiro adicional que possa ser aplicável. A falha no cumprimento destes termos de pagamento pode resultar eminterrupção ou cancelamento do acesso ao serviço. É importante verificar periodicamente o site para se manter atualizado sobre possíveis alterações na estrutura de preços.'
      },
      {
        title: 'Alterações nos Termos de Serviço',
        description:
          'A Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, detentora e operadora do Frota 162, reserva-se o direito de, a seu exclusivo critério, revisar, alterar ou modificar estes Termos de Serviço em qualquer momento. Qualquer alteração ou modificação será efetiva imediatamente após a sua publicação no site do Frota 162. É sua responsabilidade revisar regularmente esta seção para se manter informado sobre quaisquer atualizações. O uso contínuo do serviço após a publicação de tais mudanças constituirá sua aceitação e concordância com as alterações. Caso você não concorde com as novas condições dos Termos, deverá cessar o uso do serviço.'
      },
      {
        title: 'Exoneração de Responsabilidade',
        description:
          'A Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA não assumirá responsabilidade por quaisquer danos indiretos, incidentais, especiais, consequentes ou punitivos, incluindo, mas não se limitando a, perda de lucros, dados, uso, boa-vontade, ou outras perdas intangíveis, resultantes de (i) seu acesso ou uso, ou incapacidade de acessar ou usar o Frota 162; (ii) qualquer conduta ou conteúdo de terceiros no serviço; ou (iii) acesso não autorizado, uso ou alteração de suas transmissões ou conteúdo, mesmo se a possibilidade desses danos for comunicada à Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA. Esta limitação de responsabilidade se aplica na máxima extensão permitida pela lei aplicável e não exclui ou limita a responsabilidade obrigatória da Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA por fraude ou negligência grave, nem qualquer outra responsabilidade que não possa ser excluída ou limitada por lei.'
      },
      {
        title: 'Condições de Rescisão de Uso',
        description:
          'A Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA reserva-se o direito de terminar ou suspender sua conta e acesso ao Frota 162 com efeito imediato, sem necessidade de notificação prévia, caso seja identificada qualquer violação dos Termos de Serviço aqui estipulados. Esta ação de rescisão ou suspensão pode ser empreendida sem prejuízo a quaisquer outros direitos ou recursos legais que estejam à disposição da Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA conforme a lei. A suspensão ou terminação da conta implicará na cessação de acesso ao serviço e, em determinadas circunstâncias, pode incluir a exclusão de seu perfil e conteúdo associado. É importante entender que a violação dos Termos de Serviço pode também acarretar consequências legais adicionais. A Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA compromete-se a agir de forma justa e razoável em tais circunstâncias, considerando a natureza da violação.'
      },
      {
        title: 'Jurisdição e Legislação Regente',
        description:
          'Os presentes Termos de Serviço e a relação entre você e a Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, no que diz respeito ao Frota 162, serão regidos e interpretados em conformidade com as leis da República Federativa do Brasil. Você concorda, inequivocamente, que qualquer disputa ou reivindicação legal que surja ou esteja relacionada a estes Termos ou ao uso do Frota 162 será resolvida exclusivamente pelos tribunais do Brasil. Esta disposição estabelece a jurisdição exclusiva dos tribunais brasileiros para tais questões, e você renuncia a qualquer objeção à tal jurisdição ou local. A adoção desta cláusula visa assegurar uma resolução legal coerente e previsível para ambas as partes.'
      },
      {
        title: 'Integridade e Severabilidade do Acordo',
        description:
          'Este documento representa o acordo completo e exclusivo entre você, o usuário, e a Frota 162 TECNOLOGIA E INTERMEDIAÇÃO LTDA, relativo ao uso do Frota 162, e substitui todas as comunicações, propostas e acordos anteriores, tanto escritos quanto orais, relacionados ao objeto aqui tratado. Caso qualquer disposição destes Termos de Serviço seja considerada inválida, ilegal ou inaplicável por um tribunal de jurisdição competente, a validade e aplicabilidade das demais disposições permanecerão em pleno vigor e efeito. A cláusula inválida ou inaplicável será substituída por uma cláusula válida que mais se aproxime da intenção original da cláusula inaplicável, mantendo-se assim a intenção geral e a integridade destes Termos.'
      }
    ]
  }
};

interface ModalAlertServiceTerms {
  open: boolean;
  onClose: () => void;
}

const ModalTermsOfService: React.FC<ModalAlertServiceTerms> = ({
  open,
  onClose
}) => {
  const { i18n } = useTranslation();

  const { title, terms: translatedTerms } = terms[i18n.language];

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <ContainerHeader>
        <DialogTitleStyled>{title}</DialogTitleStyled>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            padding: 0,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </ContainerHeader>
      <BoxModalTermsOfService dividers>
        {translatedTerms.map((term, index) => (
          <Typography style={{ fontSize: '16px' }} key={index} gutterBottom>
            <div key={index}>
              <h3>{term.title}</h3>
              <p>{term.description}</p>
            </div>
          </Typography>
        ))}
      </BoxModalTermsOfService>
    </BootstrapDialog>
  );
};
export default ModalTermsOfService;
