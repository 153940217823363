import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  styled,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';

export const PlanTableContainer = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const PlanTableContent = styled(TableContainer)(({ theme }) => ({
  boxShadow: 'none',
  overflowX: 'initial',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: 'flex',
    alignSelf: 'center',
    padding: 20,
    marginLeft: `-${theme.spacing(4)}`,
    marginRight: `-${theme.spacing(4)}`
  }
}));

export const TableHeaderRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.common.white
}));

export const PlanTableRow = styled(TableRow)(({ theme }) => ({
  // backgroundcolor: '#f9f9f9',

  '&:nth-of-type(odd)': {
    padding: '8px 10px',
    height: theme.spacing(7),
    backgroundcolor: theme.palette.common.white
  }
}));

export const MobilePlanTableRow = styled(TableRow)(({ theme }) => ({
  backgroundcolor: '#f9f9f9',
  '&:nth-of-type(odd)': {
    padding: '8px 10px',
    height: theme.spacing(7),
    backgroundcolor: theme.palette.common.white
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}));

export const EmptyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderBottom: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const PlanHeaderNameCell = styled(TableCell)<{
  backgroundColor: string;
}>(({ theme, backgroundColor }) => ({
  backgroundColor,
  borderLeft: `1px solid ${backgroundColor}`,
  borderRight: `1px solid ${backgroundColor}`,
  textAlign: 'center',
  fontWeight: 'bold',
  padding: '8px 0px',
  color: theme.palette.common.white,
  position: 'sticky',
  top: '0',
  zIndex: theme.zIndex.appBar + 2,
  borderBottom: 'none',
  [theme.breakpoints.down('sm')]: {
    borderLeft: 'none',
    borderRight: 'none'
  }
}));

export const SpacerCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  minWidth: '10px',
  padding: '8px 0px',
  maxWidth: '20px',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const MobilePlanFunctionalityCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 8px',
  fontWeight: 'bold',
  borderBottom: 'none',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}));

export const PlanFunctionalityCell = styled(TableCell)<{
  backgroundColor?: string;
}>(({ theme, backgroundColor }) => ({
  backgroundColor,
  padding: '8px 8px',
  fontWeight: 'bold',
  borderBottom: 'none',
  minWidth: theme.spacing(30),
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const PlanTooltipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center'
  }
}));

export const PlanTooltipIcon = styled(InfoOutlinedIcon)(() => ({
  color: '#A3A3A3'
}));

export const ModuleNameCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 0px',
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 'bold',
  borderBottom: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const MobileModuleNameCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 0px',
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 'bold',
  borderBottom: 'none',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center'
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}));

export const PlanTableCell = styled(TableCell)<{
  borderColor: string;
  backgroundColor?: string;
}>(({ borderColor, theme, backgroundColor }) => ({
  backgroundColor,
  textAlign: 'center',
  marginLeft: '10px',

  borderLeft: `1px solid ${borderColor}`,
  borderRight: `1px solid ${borderColor}`,
  padding: '8px 0px',
  borderBottom: 'none',
  [theme.breakpoints.down('sm')]: {
    borderLeft: 'none',
    borderRight: 'none'
  }
}));

export const ButtonTableCell = styled(TableCell)<{ borderColor: string }>(
  ({ borderColor, theme }) => ({
    textAlign: 'center',
    marginLeft: '10px',
    borderLeft: `1px solid ${borderColor}`,
    borderRight: `1px solid ${borderColor}`,
    padding: '8px 0px',
    borderBottom: `1px solid ${borderColor}`,
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none'
    }
  })
);

export const NotAvaliableIcon = styled(RemoveIcon)(() => ({
  color: '#9e9e9e'
}));

export const BuyButton = styled(BaseButton)<{
  backgroundColor?: string;
}>(({ backgroundColor }) => ({
  textDecoration: 'underline',
  color: backgroundColor
}));
