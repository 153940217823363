import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { TextFieldProps } from '@mui/material';

import BaseTextField from '../../../../core/presentation/components/base-text-field/base-text-field';

export type CNPJTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CNPJMask = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, _ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='00.000.000/0000-00'
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

const CNPJTextField = (props: CNPJTextFieldProps) => {
  const { t } = useTranslation(['components', 'errors']);
  return (
    <Controller
      name='cnpj'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseTextField
          onChange={onChange}
          value={value || ''}
          error={!!error}
          helperText={
            error ? props.errorText ?? t('errors:cnpjInvalid') : undefined
          }
          id='cnpj'
          name='cnpj'
          label={t('cnpj')}
          variant='filled'
          type='cnpj'
          required
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: CNPJMask as any
          }}
          {...props}
        />
      )}
    />
  );
};

export default CNPJTextField;
