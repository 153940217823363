import React, { useEffect, useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { TextFieldProps } from '@mui/material';
import BaseTextField from '~/app/core/presentation/components/base-text-field/base-text-field';

export type HolderDocumentFieldProps = TextFieldProps & {
  control: Control<any>;
  errorText?: string;
  isLoading?: boolean;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const HolderDocumentMask = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, value, name, ...other } = props;

    const getMask = (value: string) => {
      const cleanedValue = value.replace(/\D/g, '');
      return cleanedValue.length <= 11 ? '000.000.000-000' : '00.000.000/0000-00';
    };

    const mask = getMask(value);

    return (
      <IMaskInput
        mask={mask}
        onAccept={(maskedValue) =>  onChange({ target: { name, value: maskedValue } })}
        overwrite
        {...other}
        ref={ref}
      />
    );
  }
);

const HolderDocumentTextField = (props: HolderDocumentFieldProps) => {
  const { t } = useTranslation(['components', 'errors']);
  const inputElementRef = useRef<HTMLInputElement | null>(null);

  return (
    <Controller
      name='holderDocument'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseTextField
          onChange={onChange}
          value={value || ''}
          error={!!error}
          helperText={
            error ? props.errorText ?? t('errors:holderDocumentInvalid') : undefined
          }
          id='holderDocument'
          name='holderDocument'
          label={t('holderDocument')}
          variant='filled'
          type='text'
          required
          InputProps={{
            inputComponent: HolderDocumentMask as any
          }}
          inputRef={inputElementRef}
          {...props}
        />
      )}
    />
  );
};

export default HolderDocumentTextField;
