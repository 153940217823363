/* eslint-disable @typescript-eslint/no-explicit-any */
import { AUTH_STORAGE_TOKENS } from '../../../application/features/auth/domain/entities/auth-tokens';
import { decoderLoginService } from '../../../application/features/auth/services/decoder-user-token';
import { useAuthStore } from '../../../application/features/auth/store/actions/set-token';
import { cacheStorage } from '../../infra/cache';

type TokenStorage = {
  accessToken?: string;
  refreshToken?: string;
};

export const useAuth = () => {
  const [accessToken, setToken] = useAuthStore((state) => [
    state.authState.accessToken,
    state.setToken
  ]);

  const cacheLocalStorage = cacheStorage;
  const tokenDecoder = decoderLoginService;
  const tokenKey = AUTH_STORAGE_TOKENS.AUTH;

  const useLogin = (response: TokenStorage) => {
    if (response?.accessToken && response?.refreshToken) {
      cacheLocalStorage.set(tokenKey, {
        accessToken: response.accessToken,
        refreshToken: response.refreshToken
      });
    }

    const tokenStorage: any =
      accessToken || cacheLocalStorage.get<string>(tokenKey);

    if (!response?.accessToken || !response?.refreshToken) {
      if (!tokenStorage) return;
      try {
        const decodedToken = tokenDecoder.decode(tokenStorage);
        cacheLocalStorage.set(tokenKey, {
          accessToken: response.accessToken,
          refreshToken: response.refreshToken
        });
        setToken(decodedToken);
      } catch (error) {
        return;
      }
    }
  };

  const getLogin = (): TokenStorage => {
    const tokenStorage: TokenStorage =
      cacheLocalStorage.get<TokenStorage>(tokenKey);

    if (
      !tokenStorage ||
      !tokenStorage.accessToken ||
      !tokenStorage.refreshToken
    )
      return;
    return tokenStorage;
  };

  const logout = () => {
    cacheLocalStorage.set(tokenKey, null);
  };

  return { useLogin, getLogin, logout };
};
