import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BoxModalApertPlan,
  ButtonModalAertPlan,
  ContainerButton,
  ModalStyled
} from './modal-alert-plan-styles';

interface ModalAlertPlanProps {
  open: boolean;
  onClose: () => void;
  onUseStarter: () => void;
  onChooseProEnterprise: () => void;
}

const ModalAlertPlan: React.FC<ModalAlertPlanProps> = ({
  open,
  onClose,
  onUseStarter,
  onChooseProEnterprise
}) => {
  const { t } = useTranslation('modal-lert-plan');

  return (
    <ModalStyled
      open={open}
      onClose={onClose}
      aria-labelledby='parent-modal-title'
      aria-describedby='parent-modal-description'
    >
      <BoxModalApertPlan>
        <h2>{t('attention')}</h2>
        <p>{t('message')}</p>
        <ContainerButton>
          <ButtonModalAertPlan onClick={onUseStarter}>
            {t('useStarter')}
          </ButtonModalAertPlan>
          <ButtonModalAertPlan onClick={onChooseProEnterprise}>
            {t('chooseProEnterprise')}
          </ButtonModalAertPlan>
        </ContainerButton>
      </BoxModalApertPlan>
    </ModalStyled>
  );
};

export default ModalAlertPlan;
