import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextFieldProps } from '@mui/material';

import BaseTextField from '../../../../core/presentation/components/base-text-field/base-text-field';

export type MessageTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
};

const MessageTextField = (props: MessageTextFieldProps) => {
  const { t } = useTranslation(['components']);
  return (
    <Controller
      name='message'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseTextField
          onChange={onChange}
          value={value || ''}
          error={!!error}
          helperText={
            error ? props.errorText ?? t('messageInvalid') : undefined
          }
          id='message'
          name='message'
          label={t('message')}
          variant='filled'
          type='message'
          multiline={true}
          rows={4}
          inputProps={{ maxLength: 256 }}
          required
        />
      )}
    />
  );
};

export default MessageTextField;
