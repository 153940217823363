import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { AddVehiclesResponseModel } from '../../domain/models/add-vehicles-response-model';
import { PaginationQueryParamsModel } from '../../domain/models/pagination-query-params-models';

export class GetVehicles implements ServiceCommand<GetVehicles.Response> {
  constructor(
    private readonly httpClient: HttpClient<GetVehicles.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: GetVehicles.Params
  ): Promise<ServiceCommand.Response<GetVehicles.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.GET,
      url: this.url,
      queryParams: params
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace GetVehicles {
  export type Params = PaginationQueryParamsModel & { companyId?: string };
  export type Response = AddVehiclesResponseModel[];
}
