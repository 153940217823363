import { ServiceCommand } from '../../../../../../core/domain/command/service-command';
import { httpClient } from '../../../../../../core/infra/http/axios-http-client-adapter';
import { AUTH_API_ROUTES } from '../../../api/routes';
import { GetPlans } from './get-plans';

export const getPlansService: ServiceCommand<GetPlans.Response> = new GetPlans(
  httpClient,
  AUTH_API_ROUTES.GET_PLANS
);

export type { GetPlans };
