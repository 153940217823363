import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@mui/material';
import { useFeatureFlags } from '~/app/core/presentation/hooks/use-feature-flags';

import {
  BoxModal,
  ButtonChoose,
  ContainerButton,
  ModalStyled
} from './modal-detect-mobile-styles';
import SendWhatsappOrEmail from './send-whatsapp-or-email/send-whatsapp-or-email';

interface ModalAlertPlanProps {
  open: boolean;
  onClose: () => void;
}

const ModalDetectMobile: React.FC<ModalAlertPlanProps> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation('modal-detect-mobile');
  const { isFlagEnabled } = useFeatureFlags();
  const useButtonWhatsapp = isFlagEnabled('use_button_send_link_whatsapp');
  const [showButtonWhatsapp, setShowButtonWhatsapp] = useState(false);

  const [openSendLink, setOpenSendLink] = useState(false);
  const [sendMethod, setSendMethod] = useState<'whatsapp' | 'email' | null>(
    null
  );

  // const onChooseSendLink = () => {
  //   setOpenSendLink(true);
  // };

  const onSendMethod = (method: 'whatsapp' | 'email') => {
    setSendMethod(method);
  };

  const handleClose = () => {
    setOpenSendLink(false);
    setSendMethod(null);
  };

  const closeAll = () => {
    setOpenSendLink(false);
    setSendMethod(null);
    setOpenSendLink(false);
    onClose();
  };

  useEffect(() => {
    setShowButtonWhatsapp(useButtonWhatsapp);
  }, [useButtonWhatsapp]);

  return (
    <>
      <ModalStyled
        open={open || openSendLink}
        onClose={onClose}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <BoxModal>
          {openSendLink ? (
            <>
              <h2>{t('forward')}</h2>
              <p>{t(`receiveEmail`)}</p>
              <ContainerButton>
                {showButtonWhatsapp && (
                  <ButtonChoose onClick={() => onSendMethod('whatsapp')}>
                    {t('whatsapp')}
                  </ButtonChoose>
                )}
                <ButtonChoose onClick={() => onSendMethod('email')}>
                  {t('e-mail')}
                </ButtonChoose>
              </ContainerButton>
            </>
          ) : (
            <>
              <h2>{t('deliveryChannel')}</h2>
              <p>{t('chooseDesktopOrMobile')}</p>
              <ContainerButton>
                <ButtonChoose onClick={onClose}>{t('continue')}</ButtonChoose>
                {/* Change onClick to open modal choose method send link */}
                <ButtonChoose onClick={() => onSendMethod('email')}>
                  {t('goWithLink')}
                </ButtonChoose>
              </ContainerButton>
            </>
          )}
        </BoxModal>
      </ModalStyled>

      <Dialog fullScreen open={sendMethod !== null} onClose={handleClose}>
        <SendWhatsappOrEmail
          close={closeAll}
          sendMethod={sendMethod}
          goBack={handleClose}
        />
      </Dialog>
    </>
  );
};

export default ModalDetectMobile;
