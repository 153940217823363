import { Box, Icon, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: theme.spacing(1)
}));

export const Itens = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(0.5),
  color: theme.palette.text.primary
}));

export const CheckIconStyle = styled(Icon)(({ theme }) => ({
  color: theme.palette.success.main
}));

export const CloseIconStyle = styled(Icon)(({ theme }) => ({
  color: theme.palette.warning.main
}));
