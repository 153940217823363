import { useEffect, useState } from 'react';

import { AUTH_STORAGE_TOKENS } from '~/app/application/features/auth/domain/entities/auth-tokens';

import { cacheStorage } from '../../infra/cache';
import { tokenDecoder } from '../../infra/decoder/jwt-token-decoder';

interface StoredTokens {
  accessToken: string;
  refreshToken: string;
}

interface JwtPayload {
  _id: string;
  email: string;
  iat: number;
  exp: number;
}

const useJwtInfo = () => {
  const [jwtInfo, setJwtInfo] = useState<JwtPayload | null>(null);

  useEffect(() => {
    const tokens = cacheStorage.get<StoredTokens>(AUTH_STORAGE_TOKENS.AUTH);
    if (tokens) {
      try {
        const decoded = tokenDecoder.decode<JwtPayload>(tokens.accessToken);
        setJwtInfo(decoded);
      } catch (error) {
        console.error('Invalid JWT token:', error);
        setJwtInfo(null);
      }
    } else {
      setJwtInfo(null);
    }
  }, []);

  return jwtInfo;
};

export default useJwtInfo;
