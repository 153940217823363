import { MenuItem, Select, styled } from '@mui/material';

export const BaseSelectInputStyle = styled(Select)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  backgroundColor: theme.palette.background.paper
}));

export const BaseMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  '&.MuiMenuItem-root': {
    paddingTop: 0,
    padding: theme.typography.pxToRem(10),
    '&.Mui-selected': {
      backgroundColor: theme.palette.grey[100]
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  }
}));
