import { Box, styled } from '@mui/material';

export const CreditCardFormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(4),
  border: `${theme.typography.pxToRem(1)} solid #A3A3A3`,
  borderRadius: theme.typography.pxToRem(8),
  padding: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(4)
  }
}));

export const CreditCardTitle = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24)
}));

export const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(5)
}));
