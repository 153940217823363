import { useEffect, useRef, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Add } from '@mui/icons-material';
import PlateTextField from '~/app/application/features/components/plate-text-field/plate-text-field';
import RenavamTextField from '~/app/application/features/components/renavam-text-field/renavam-text-field';
import BaseAlert from '~/app/core/presentation/components/base-alert/base-alert';
import { useFeatureFlags } from '~/app/core/presentation/hooks/use-feature-flags';

import { ManualVehicleData } from '../vehicle-add-manual';
import {
  AddMoreVehiclesButton,
  AddPlatesBox,
  CleanVehicleButton,
  VehicleLine
} from './add-plates-list-styles';

type AddPlatesListProps = UseFormReturn<ManualVehicleData> & {
  displayError: boolean;
  displayDuplicateRenavamError: boolean;
};

export const AddPlatesList = ({
  control,
  displayError,
  displayDuplicateRenavamError,
  getValues
}: AddPlatesListProps) => {
  const { t } = useTranslation('register-vehicle-step');
  const { isFlagEnabled } = useFeatureFlags();
  const [hasDublicatePlate, setHasDuplicatePlate] = useState(false);
  const useFieldRenavam = isFlagEnabled('use_field_renavam');
  const [showFieldRenavam, setShowFieldRenavam] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'vehicles'
  });

  const hasDuplicatePlate = (isDuplicatePlate: boolean) => {
    setHasDuplicatePlate(isDuplicatePlate);
  };

  const handleAddField = () => {
    append({ plate: '', renavam: '' });
  };

  const handleRemoveField = (index: number) => {
    remove(index);
  };
  useEffect(() => {
    setShowFieldRenavam(useFieldRenavam);
  }, [useFieldRenavam]);

  useEffect(() => {
    const numFieldsToAdd = Math.max(0, 5 - fields.length);
    for (let i = 0; i < numFieldsToAdd; i++) {
      append({ plate: '', renavam: '' });
    }
  }, [fields, append]);

  const firstPlateInputRef = useRef(null);

  useEffect(() => {
    if (fields.length > 0 && firstPlateInputRef.current) {
      firstPlateInputRef.current.focus();
    }
  }, [fields]);

  return (
    <AddPlatesBox>
      {displayError && (
        <BaseAlert severity='info'>
          Você precisa informar pelo menos 5 placas de veículos para continuar.
        </BaseAlert>
      )}

      {hasDublicatePlate && (
        <BaseAlert severity='info'>
          Você não pode informar placas duplicadas.
        </BaseAlert>
      )}

      {displayDuplicateRenavamError && (
        <BaseAlert severity='info'>
          Você não pode informar renavam duplicados.
        </BaseAlert>
      )}

      {fields.map((field, index) => (
        <VehicleLine key={field.id}>
          <PlateTextField
            id={`plate-${index}`}
            name={`vehicles[${index}].plate`}
            control={control}
            getValues={getValues}
            hasDuplicatePlate={hasDuplicatePlate}
            autoFocus={index === 0}
            ref={index === 0 ? firstPlateInputRef : null}
          />
          {showFieldRenavam && (
            <RenavamTextField
              id={`renavam-${index}`}
              name={`vehicles[${index}].renavam`}
              control={control}
              autoFocus={index === 0 && showFieldRenavam}
            />
          )}
          <CleanVehicleButton
            variant='text'
            id='clear'
            disabled={index < 5}
            onClick={() => handleRemoveField(index)}
          >
            {t('manualAddScreen.clear')}
          </CleanVehicleButton>
        </VehicleLine>
      ))}
      <AddMoreVehiclesButton
        id='add-more-plates'
        variant='text'
        onClick={handleAddField}
      >
        <Add />
        {t('manualAddScreen.addMorePlates')}
      </AddMoreVehiclesButton>
    </AddPlatesBox>
  );
};

export default AddPlatesList;
