import { Box, styled, Typography } from '@mui/material';

export const ContactEnterpriseContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 50,
  gap: theme.spacing(5)
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

export const ContactDescriptionText = styled('p')(({ theme }) => ({
  color: theme.palette.common.black
}));

export const ContactTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(33),
  fontWeight: 'bold',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(24)
  }
}));
