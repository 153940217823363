import { styled } from '@mui/material';

export const CardCurrentPlanContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  background: '#EFEFEF',
  width: '100%',
  padding: '10px 15px'
}));

export const CardCurrentPlanTitle = styled('p')(() => ({
  fontSize: '14px',
  fontWeight: 700,
  color: '#6A6A6A',
  margin: 0,
  gap: 0
}));

export const CardCurrentPlanDescription = styled('span')(() => ({
  fontSize: '18',
  color: '#6A6A6A'
}));
