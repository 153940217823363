import { styled, Button as MuiButton } from '@mui/material';

export const Button = styled(MuiButton)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  textTransform: 'none',
  fontWeight: 'bold',
  width: '100%',
  height: theme.spacing(7),
  borderRadius: theme.spacing(1),
  alignItems: 'center'
}));
