import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IdentifierTextField from '~/app/application/features/components/identifier-text-field/identifier-text-field';
import { HttpErrorsType } from '~/app/core/application/protocols/http-client';
import { useFeatureFlags } from '~/app/core/presentation/hooks/use-feature-flags';

import Logo from '../../../../../../core/presentation/components/logo/logo';
import PasswordTextField from '../../../../components/password-text-field/password-text-field';
import { LoginForm } from '../../../domain/models/login-models';
import { AuthRoutesPath } from '../../../routes/routes';
import { storeEmail } from '../../../services/email-login/email-login';
import LoginOrRegisterWithGoogle from '../../access-google/access-google';
import {
  FormBox,
  TextAccessAccount,
  ForgotPassword,
  ButtonLogin,
  AlignBox,
  DividerStyle,
  TextDivider,
  LogoContainer,
  LoginAlertBox
} from './form-login-styles';
import { LoginFormValidation } from './form-validation/form-validation';
import FormsOfAccess from './forms-of-access/forms-of-access';

const FormLogin = ({ onSubmit, apiError, isLoading }) => {
  const { t } = useTranslation(['login']);
  const [blockedUser, setBlockedUser] = useState(false);
  const { isFlagEnabled } = useFeatureFlags();

  const useGoogleLogin = isFlagEnabled('use_google_auth');

  const [showButtonLoginGoogle, setShowButtonLoginGoogle] = useState(false);

  const { control, handleSubmit, getValues } = useForm<LoginForm>({
    defaultValues: {
      identifier: '',
      password: ''
    },
    resolver: LoginFormValidation
  });

  const saveEmail = () => {
    const email = getValues('identifier');
    storeEmail(email);
  };

  useEffect(() => {
    if (apiError?.message === HttpErrorsType.BLOCKED_USER_EXCEPTION) {
      setBlockedUser(true);
      return;
    }
    setBlockedUser(false);
  }, [apiError]);

  useEffect(() => {
    setShowButtonLoginGoogle(useGoogleLogin);
  }, [useGoogleLogin]);

  return (
    <FormBox component='form' onSubmit={handleSubmit(onSubmit)}>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <TextAccessAccount>{t('accessYourAccount')}</TextAccessAccount>

      <LoginOrRegisterWithGoogle showButton={showButtonLoginGoogle} />

      {showButtonLoginGoogle && (
        <DividerStyle>
          <TextDivider>{t('or')}</TextDivider>
        </DividerStyle>
      )}

      {blockedUser && (
        <LoginAlertBox severity='error'>{t('blockedUserError')}</LoginAlertBox>
      )}

      <AlignBox>
        <IdentifierTextField {...{ control }} />
        <PasswordTextField enablePaste {...{ control, apiError }} />
        <ForgotPassword
          onClick={saveEmail}
          to={AuthRoutesPath.RECOVER_PASSWORD}
        >
          {t('forgotPassword')}
        </ForgotPassword>
      </AlignBox>
      <ButtonLogin
        {...{ isLoading }}
        type='submit'
        variant='contained'
        disableElevation
      >
        {t('login')}
      </ButtonLogin>
      <FormsOfAccess />
    </FormBox>
  );
};

export default FormLogin;
