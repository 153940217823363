import { Box, Typography, styled } from '@mui/material';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';

export const VehicleConfirmationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3)
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.typography.pxToRem(30)
}));

export const PlatesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  height: '30vh',
  overflow: 'hidden',
  overflowY: 'auto',
  [theme.breakpoints.up('md')]: {
    height: '25vh'
  }
}));

export const StatusPlateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2)
}));

export const PlateText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.typography.pxToRem(16)
}));

export const ResendVehiclesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2)
}));

export const ManualAddVehiclesButton = styled(BaseButton)(() => ({
  flex: 1,
  display: 'flex',
  width: 'auto',
  minWidth: 'min-content',
  maxWidth: 'max-content',
  justifyContent: 'flex-start',
  '&:hover': {
    background: 'none'
  }
}));

export const ResendVehiclesButton = styled(BaseButton)(() => ({
  flex: 1,
  display: 'flex',
  width: 'auto',
  minWidth: 'min-content',
  maxWidth: 'max-content',
  justifyContent: 'flex-end',
  '&:hover': {
    background: 'none'
  }
}));
