import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { TextFieldProps } from '@mui/material';
import BaseTextField from '~/app/core/presentation/components/base-text-field/base-text-field';

export type CardNumberTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: any;
  errorText?: string;
  isLoading?: boolean;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CardNumberMask = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, _) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        mask='0000 0000 0000 0000'
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        {...other}
      />
    );
  }
);

const identifyCardBrand = (cardNumber: string): string | undefined => {
  if (cardNumber.startsWith('4')) {
    return 'visa';
  } else if (cardNumber.startsWith('5')) {
    return 'mastercard';
  } else if (cardNumber.startsWith('34') || cardNumber.startsWith('37')) {
    return 'american_express';
  }
  return;
};

const cardBrandImageSrc = {
  visa: '/assets/images/card_brands/visa.png',
  mastercard: '/assets/images/card_brands/mastercard.png',
  american_express: '/assets/images/card_brands/amex.svg'
};

const CardNumberTextField = (props: CardNumberTextFieldProps) => {
  const { t } = useTranslation(['components', 'errors']);
  const [cardBrand, setCardBrand] = React.useState<string | undefined>();
  return (
    <Controller
      name='cardNumber'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseTextField
          onChange={(e) => {
            const cardBrand = identifyCardBrand(e.target.value);
            onChange(e);
            setCardBrand(cardBrand);
            props.setValue('cardBrand', cardBrand);
          }}
          value={value || ''}
          error={!!error}
          helperText={
            error ? props.errorText ?? t('errors:cardNumberInvalid') : undefined
          }
          id='cardNumber'
          name='cardNumber'
          label={t('cardNumber')}
          variant='filled'
          type='cardNumber'
          required
          inputProps={{ maxLength: 256, shrink: value }}
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: CardNumberMask as any,
            endAdornment: cardBrand ? (
              <img width={35} src={cardBrandImageSrc[cardBrand]} />
            ) : undefined
          }}
          {...props}
        />
      )}
    />
  );
};

export default CardNumberTextField;
