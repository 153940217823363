/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { type AxiosInstance } from 'axios';
import { AUTH_API_ROUTES } from '~/app/application/features/auth/api/routes';
import { AUTH_STORAGE_TOKENS } from '~/app/application/features/auth/domain/entities/auth-tokens';
import { LoginByLinkResponse } from '~/app/application/features/auth/domain/models/login-by-link-response.model';
import { AuthRoutesPath } from '~/app/application/features/auth/routes/routes';
import { APP_ENV } from '~/env';

import {
  HttpClient,
  HttpError,
  HttpRequest,
  HttpResponse
} from '../../../application/protocols/http-client';
import { cacheStorage } from '../../cache';

export class AxiosAuthHttpClientAdapter implements HttpClient {
  private readonly axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: APP_ENV.API_URL,
      timeout: 30000
    });
  }

  async request({
    method,
    url,
    body,
    headers,
    queryParams
  }: HttpRequest): Promise<HttpResponse> {
    const tokenKey = AUTH_STORAGE_TOKENS.AUTH;
    const authTokens:
      | { accessToken?: string; refreshToken?: string }
      | undefined = cacheStorage.get<{
      accessToken?: string;
      refreshToken?: string;
    }>(tokenKey);
    if (!authTokens || !authTokens.accessToken || !authTokens.refreshToken) {
      cacheStorage.set(tokenKey, undefined);
      window.location.replace(AuthRoutesPath.LOGIN);
      return;
    }
    try {
      const axiosResponse = await this.axiosInstance.request({
        url,
        data: body,
        headers: {
          Authorization: `Bearer ${authTokens.accessToken}`,
          ...headers
        },
        method,
        params: queryParams
      });
      return {
        statusCode: axiosResponse.status,
        body: axiosResponse.data
      };
    } catch (error: any) {
      if (error.response?.status === 401) {
        try {
          const axiosRefreshTokenResponse =
            await this.axiosInstance.request<LoginByLinkResponse>({
              url: AUTH_API_ROUTES.REFRESH_TOKEN,
              data: { refreshToken: authTokens.refreshToken },
              method: 'POST'
            });
          cacheStorage.set(tokenKey, {
            accessToken: axiosRefreshTokenResponse.data.accessToken,
            refreshToken: axiosRefreshTokenResponse.data.refreshToken
          });
          const axiosResponse = await this.axiosInstance.request({
            ...error.config,
            headers: {
              Authorization: `Bearer ${axiosRefreshTokenResponse.data.accessToken}`
            }
          });
          return {
            statusCode: axiosResponse.status,
            body: axiosResponse.data
          };
        } catch (refreshTokenError: any) {
          cacheStorage.set(tokenKey, undefined);
          window.location.replace(AuthRoutesPath.LOGIN);
        }
      }
      return {
        error: error.response?.data,
        statusCode: error.response?.status,
        message: error.response?.statusText
      } as HttpError;
    }
  }
}
