export const APP_ENV = {
  APP_URL: process.env.APP_URL,
  API_URL: process.env.API_URL,
  FRONTEND_V1_REDIRECT_URL: process.env.FRONTEND_V1_REDIRECT_URL,
  GA_TRACKING_ID: process.env.GA_TRACKING_ID,
  EMAIL_TEST: process.env.EMAIL_TEST,
  TIME_INACTIVITY: process.env.TIME_INACTIVITY,
  LINK_DASHBOARD: process.env.LINK_DASHBOARD,
  LINK_ADD_PLATE: process.env.LINK_ADD_PLATE,
  FREE_PLAN_ID: process.env.FREE_PLAN_ID
};
