import React from 'react';
import { Control, Controller, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { TextFieldProps } from '@mui/material';

import BaseTextField from '../../../../core/presentation/components/base-text-field/base-text-field';
import {
  ManualVehicleData,
  VehiclesList
} from '../../auth/presentation/register/form-register/vehicle-step/vehicle-add-manual/vehicle-add-manual';

export type PlateTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
  getValues: UseFormGetValues<ManualVehicleData>;
  hasDuplicatePlate: (isDuplicatePlate: boolean) => void;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const hasEqualPlates = (arr: VehiclesList[]) => {
  const plateCounts: Record<string, number> = {};

  for (const { plate } of arr) {
    const trimmedPlate = plate.trim();

    if (trimmedPlate) {
      plateCounts[trimmedPlate] = (plateCounts[trimmedPlate] || 0) + 1;

      if (plateCounts[trimmedPlate] > 1) {
        return true;
      }
    }
  }

  return false;
};

const PlateMask = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, _ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='AAA0#00'
        min={7}
        definitions={{
          '#': {
            mask: /[a-jA-J0-9]/,
            prepareChar: (char) => char.toUpperCase()
          },
          A: { mask: /[a-zA-Z]/, prepareChar: (char) => char.toUpperCase() }
        }}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

const PlateTextField = (props: PlateTextFieldProps) => {
  const { t } = useTranslation(['components', 'errors']);

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        let hasDuplicate = false;

        if (value !== '') {
          const values = props.getValues();
          const currentIndex = values.vehicles.findIndex(
            (vehicle) => !(vehicle.plate === '') && vehicle.plate === value
          );

          const duplicateIndex = values.vehicles.findIndex(
            (vehicle, index) =>
              index !== currentIndex && vehicle.plate === value
          );

          hasDuplicate = duplicateIndex !== -1;

          props.hasDuplicatePlate(hasEqualPlates(values.vehicles));
        }

        return (
          <BaseTextField
            onChange={onChange}
            value={value || ''}
            error={value !== '' && (hasDuplicate || !!error)}
            helperText={
              value !== '' &&
              ((!hasDuplicate && error
                ? props.errorText ?? t('errors:plateInvalid')
                : undefined) ||
                (hasDuplicate && t('errors:duplicateValues')))
            }
            id='plate'
            name='plate'
            label={t('plate')}
            variant='filled'
            type='text'
            required
            InputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent: PlateMask as any
            }}
            {...props}
          />
        );
      }}
    />
  );
};

export default PlateTextField;
