import { Box, Typography, styled } from '@mui/material';

import BaseButton from '../../../../../../core/presentation/components/base-button/base-button';

export const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  height: '100%',
  // marginLeft: '1%',
  width: '90%',
  gap: theme.spacing(4),
  marginTop: theme.spacing(7)
  // maxWidth: theme.spacing(60)
}));

export const BoxTop = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3)
}));

export const ContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  // alignItems: 'center',
  // justifyContent: 'center',
  maxWidth: theme.spacing(60),
  width: '100%'
}));

export const TextForgotPassword = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(40),
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    width: 'min-content'
  }
}));

export const RecoverPasswordButton = styled(BaseButton)(({ theme }) => ({
  textTransform: 'none',
  maxWidth: theme.spacing(60),
  marginBottom: theme.spacing(8)
}));
