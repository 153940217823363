import { ReactNode } from 'react';

import {
  Container,
  LeftContent,
  RightContent
} from './base-auth-layout-styles';

export type BaseAuthLayoutProps = {
  leftChildren: ReactNode;
  rightChildren: ReactNode;
};

const BaseAuthLayout = (props: BaseAuthLayoutProps) => {
  return (
    <Container>
      {props.leftChildren && props.rightChildren ? (
        <>
          <LeftContent>{props.leftChildren}</LeftContent>
          <RightContent>{props.rightChildren}</RightContent>
        </>
      ) : (
        <LeftContent>{props.leftChildren}</LeftContent>
      )}
    </Container>
  );
};

export default BaseAuthLayout;
