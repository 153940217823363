import { Button, DialogContent, DialogTitle, styled } from '@mui/material';

export const ContainerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.common.white,
  padding: 20
}));

export const DialogTitleStyled = styled(DialogTitle)(() => ({
  display: 'flex',
  width: '93%',
  fontSize: '24px',
  padding: 0
}));

export const BoxModalTermsOfService = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: '100%'
}));

export const ButtonModalTermsOfService = styled(Button)(() => ({
  fontSize: '16px',
  color: '#1976d2'
}));
