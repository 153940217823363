import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { SubscriptionModel } from '../../domain/models/subscriptions/create-subscription-model';

export class GetSubscriptionById
  implements ServiceCommand<GetSubscription.Response>
{
  constructor(
    private readonly httpClient: HttpClient<GetSubscription.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: GetSubscription.Params
  ): Promise<ServiceCommand.Response<GetSubscription.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${this.url}/${params.id}`
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace GetSubscription {
  export type Params = { id: string };

  export type Response = SubscriptionModel;
}
