// Assuming RequestResponse is in the correct path
import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { SubscriptionModel } from '../../domain/models/subscriptions/create-subscription-model';
import { SubscriptionResponseModel } from '../../domain/models/subscriptions/create-subscription-response-model';

export class UpdateSubscription
  implements ServiceCommand<UpdateSubscription.Response>
{
  constructor(
    private readonly httpClient: HttpClient<UpdateSubscription.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: UpdateSubscription.Params
  ): Promise<ServiceCommand.Response<UpdateSubscription.Response>> {
    const { subscriptionId, ...body } = params;

    const httpResponse = await this.httpClient.request({
      method: HttpMethod.PATCH,
      url: `${this.url}/${subscriptionId}`,
      body: body
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export const storeEmail = (email: string) =>
  sessionStorage.setItem('forgot-password-email', email);

export const getStoreEmail = () =>
  sessionStorage.getItem('forgot-password-email');

export namespace UpdateSubscription {
  export type Params = SubscriptionModel;
  export type Response = SubscriptionResponseModel;
}
