import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { cacheStorage } from '../../../../../core/infra/cache';
import { httpClient } from '../../../../../core/infra/http/apolo-http-client-adapter';
import { AUTH_API_ROUTES } from '../../api/routes';
import { AUTH_STORAGE_TOKENS } from '../../domain/entities/auth-tokens';
import { EmailOrUsernameLogin } from './email-login';

export const emailOrUsernameLoginService: ServiceCommand<EmailOrUsernameLogin.Response> =
  new EmailOrUsernameLogin(
    httpClient,
    cacheStorage,
    AUTH_STORAGE_TOKENS.AUTH,
    AUTH_API_ROUTES.LOGIN
  );

export type { EmailOrUsernameLogin };
