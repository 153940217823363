import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import { logEvent } from '~/app/application/shared/lib/tag';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';
import UploadFileButton from '~/app/core/presentation/components/upload-button/upload-button';

import { SignUpFormDataProps } from '../form-register';
import { fileParser } from './parsers/file-parser';
import { licensePlateValidation } from './validations/license-plate-validation';
import { renavamValidation } from './validations/renavam-validation';
import VehicleAddManual from './vehicle-add-manual/vehicle-add-manual';
import VehicleConfirmation, {
  VehicleData as VehicleDataState
} from './vehicle-confirmation/vehicle-confirmation';
import {
  ButtonsContainer,
  SpreadsheetModeLink,
  FullScreenManualInputDialog,
  VehicleStepContainer
} from './vehicle-step-styles';

export type VehicleData = {
  placa: string;
  renavam: string;
  chassis?: string;
};

type VehicleStepProps = {
  formData: SignUpFormDataProps;
};

const VehicleStep = ({ formData }: VehicleStepProps) => {
  const { t } = useTranslation('register-vehicle-step');
  const { control } = useForm();

  const [displayResults, setDisplayResults] = useState(false);
  const [displayManualInput, setDisplayManualInput] = useState(false);
  const [vehicles, setVehicles] = useState<VehicleDataState[]>([]);
  const [displayError, setDisplayError] = useState(false);

  const onFileUpload = async (data: FileList) => {
    logEvent({
      category: 'include_veiculo_planilha/trigger',
      action: 'OnSubmit',
      label: 'step04_include_veiculo_planilha'
    });

    if (data.length > 0) {
      const file = data[0];
      const vehiclesFromFile = await fileParser<VehicleData>(file);
      onAddAndValidateVehicles(vehiclesFromFile);
    }
  };

  const onAddAndValidateVehicles = (vehiclesData: VehicleData[]) => {
    setVehicles(
      vehiclesData.map((vehicle) => {
        const plate = vehicle.placa.toString().toUpperCase();
        const renavam =
          vehicle.renavam &&
          vehicle.renavam.toString().replaceAll(' ', '').padStart(11, '0');
        const chassis = vehicle.chassis?.toString().replaceAll(' ', '');

        const isRepeated =
          vehiclesData.filter(
            (v) =>
              v.placa === vehicle.placa ||
              (v.renavam && vehicle.renavam && v.renavam === vehicle.renavam) ||
              (v.chassis && vehicle.chassis && v.chassis === vehicle.chassis)
          ).length > 1;

        return {
          plate,
          renavam,
          chassis,
          status:
            licensePlateValidation(plate) &&
            (renavam ? renavamValidation(renavam) : true) &&
            !isRepeated
              ? 'success'
              : 'error'
        };
      })
    );
    if (vehiclesData.length < 5) {
      setDisplayError(true);
      return;
    }
    setDisplayResults(true);
  };

  const toogleDisplayResults = () => {
    setDisplayResults(!displayResults);
  };

  const openManualInput = () => {
    logEvent({
      category: 'step04_include_veiculo_manual/trigger',
      action: 'OnSubmit',
      label: 'step04_include_veiculo_manual'
    });

    setDisplayManualInput(true);
  };

  return (
    <VehicleStepContainer>
      {!displayResults && (
        <ButtonsContainer>
          <h1>{t('title')}</h1>
          <div>
            {t('description')}{' '}
            <SpreadsheetModeLink download href='/assets/files/exemplo.xlsx'>
              {t('descriptionAction')}
            </SpreadsheetModeLink>
            .
          </div>
          {displayError && (
            <>
              <Alert severity='error'>
                Você precisa informar pelo menos 5 placas de veículos para
                continuar.
              </Alert>
            </>
          )}

          <BaseButton
            id='manual-add'
            variant='contained'
            onClick={openManualInput}
          >
            {t('manualAdd')}
          </BaseButton>

          <UploadFileButton
            control={control}
            label={t('sendFile')}
            name='file'
            variant='outlined'
            maxFileSize={5}
            acceptedMimeTypes={[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'text/csv',
              'application/vnd.ms-excel'
            ]}
            onFileUpload={onFileUpload}
          />
        </ButtonsContainer>
      )}
      {displayResults && (
        <VehicleConfirmation
          manualAddCallback={openManualInput}
          resendCallback={toogleDisplayResults}
          vehicles={vehicles}
          // handleNextStep={handleNextStep}

          formData={formData}
        />
      )}
      <FullScreenManualInputDialog
        fullScreen
        hideBackdrop
        disableEscapeKeyDown
        open={displayManualInput}
        onClose={() => setDisplayManualInput(false)}
      >
        <VehicleAddManual
          vehicles={vehicles}
          onManualInput={onAddAndValidateVehicles}
          handleClose={() => setDisplayManualInput(false)}
        />
      </FullScreenManualInputDialog>
    </VehicleStepContainer>
  );
};
export default VehicleStep;
