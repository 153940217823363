import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ArrowBack } from '@mui/icons-material';
import { SubscriptionModel } from '~/app/application/features/auth/domain/models/subscriptions/create-subscription-model';
import { useRegisterUserDataStore } from '~/app/application/features/auth/store/slice/register-user-data-slice';
import CreditCardForm from '~/app/application/features/components/credit-card-form/credit-card-form';
import PaymentResumeBox from '~/app/application/features/components/payment-resume-box/payment-resume-box';
import RadioPlanLabel from '~/app/application/features/components/radio-plan-label/radio-plan-label';
import SuccessSetting from '~/app/application/features/components/success-setting/success-setting';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';
import BaseRadioButton from '~/app/core/presentation/components/base-radio-button/base-radio-button';
import Logo from '~/app/core/presentation/components/logo/logo';

import { APP_ENV } from '../../../../../../../../../env';
import { PlanResponseModel, Price } from '../../../../../domain/models/plans/plan-response-model';
import {
  useAddVehiclesMutation,
  useCreateSubscriptionMutation,
  useGetPlansMutation,
  useUpdateSubscriptionMutation
} from '../../../../../store/hooks';
import {
  BackAndLogoBox,
  ContentBox,
  LogoBox,
  Title,
  PaymentPlanBox,
  HorizontalContainer,
  CreditCardContainer,
  RootBox,
  PaymentPlanContainer,
  IconButtonStyled
} from './payment-plan-styles';
import { PaymentPlanValidator } from './validators/payment-plan.validator';

type PaymentPlanFormData = {
  cardBrand: 'visa' | 'mastercard' | 'american_express';
  cardNumber: string;
  holderName: string;
  holderDocument: string;
  price: string;
  expirationDate: string;
  cvv: string;
};

const parseDateFromMMYY = (dateString: string): Date => {
  const parts = dateString.split('/');
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[1], 10) + 2000; 
  return new Date(year, month - 1, 1);
};

const PaymentPlan = () => {
  const { t } = useTranslation('payment-plan');
  const { registerState } = useRegisterUserDataStore();
  const [createSubscription, { isLoading }] = useCreateSubscriptionMutation();
  const [updateSubscription, { isLoading: isLoadingUpdate }] =
    useUpdateSubscriptionMutation();
  const [plan, setPlan] = useState<PlanResponseModel>(registerState.plans[3]);
  const [monthlyPrice, setMonthlyPrice] = useState<Price>();
  const [yearlyPrice, setYearlyPrice] = useState<Price>();
  const [selectedPrice, setSelectedPrice] = useState<Price>();
  const [code, setCode] = useState('');
  const [getPrice, setGetPrice] = useState('0,00');
  const [displayPaymentSuccess, setdisplayPaymentSuccess] = useState(false);
  const [displayPaymentProcessing, setDisplayPaymentProcessing] = useState(false);
  const [displayPaymentError, setDisplayPaymentError] = useState(false);
  

  const [addVehicles] = useAddVehiclesMutation();
  const navigate = useNavigate();

  const handleSavePrice = (priceValue: string) => {
    setGetPrice(priceValue);
  };

  const addVehiclesRequest = async () => {
    addVehicles(registerState.vehicles)
      .unwrap()
      .catch((error) => {
        console.error(error);
      });
  };

  const updateSubscriptionRequest = (values: SubscriptionModel) => {
    updateSubscription({ ...values, isSelfOnboarding: true })
      .unwrap()
      .then((response) => {
        if (registerState.vehicles.vehicleList.length > 0) addVehiclesRequest();
        setDisplayPaymentProcessing(true);
        setCode(response.code);
      })
      .catch(() => {
        setDisplayPaymentError(true);
        console.error('error in update payment');
      });
  };

  const createSubscriptionRequest = (values: SubscriptionModel) => {
    createSubscription({ ...values, isSelfOnboarding: true })
      .unwrap()
      .then((response) => {
        if (registerState.vehicles.vehicleList.length > 0) addVehiclesRequest();
        setDisplayPaymentProcessing(true);
        setCode(response.code);
      })
      .catch(() => {
        setDisplayPaymentError(true);
        console.error('error in create company');
      });
  };

  useEffect(() => {
    plan.products[0].prices.forEach((price) => {
      if (price.frequency === 'yearly') {
        setYearlyPrice(price);
      } else {
        setMonthlyPrice(price);
      }
    });
  }, [plan]);

  const onSubmit = (data: PaymentPlanFormData) => {
    const company = registerState.companies[0];

    const commonRequestParams = {
      companyId: registerState?.companies[0]?._id,
      planId: plan._id,
      paymentMethodType: 'credit_card',
      productItems: [
        {
          productId: plan.products[0]._id,
          quantity: registerState?.vehiclesCount,
          priceId: data.price,
          discounts: []
        }
      ],
      paymentProfile: {
        holderName: data.holderName,
        holderDocument: data.holderDocument,
        cardExpiration: parseDateFromMMYY(data.expirationDate),
        cardNumber: data.cardNumber.replaceAll(' ', ''),
        cardCVV: data.cvv,
        cardBrand: data.cardBrand
      },
      invoiceSplit: false,
      subscriptionAffiliates: [],
      metaData: []
    };
    if (company?.subscriptions && company?.subscriptions[0]) {
      return updateSubscriptionRequest({
        subscriptionId: company?.subscriptions[0],
        ...commonRequestParams
      });
    } else {
      return createSubscriptionRequest(commonRequestParams);
    }
  };

  const formData = useForm<PaymentPlanFormData>({
    resolver: PaymentPlanValidator
  });

  const { handleSubmit, setValue, control, formState } = formData;

  const selectedPriceId = useWatch({ control, name: 'price' });
  useEffect(() => {
    const price = plan?.products[0]?.prices?.find(
      (price) => price?._id === selectedPriceId
    );
    setSelectedPrice(price);
  }, [selectedPriceId]);

  const displayPayment = !(displayPaymentSuccess || displayPaymentProcessing || displayPaymentError);

  return (
    <PaymentPlanContainer>
      {displayPayment && (
        <IconButtonStyled onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButtonStyled>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {displayPayment && (
          <PaymentPlanBox>
            <BackAndLogoBox>
              <LogoBox>
                <Logo />
              </LogoBox>
            </BackAndLogoBox>

            <RootBox>
              <ContentBox>
                <Title>Método de Pagamento</Title>
                <HorizontalContainer>
                  Escolha o Ciclo de Faturamento
                </HorizontalContainer>
                {yearlyPrice && monthlyPrice && (
                  <HorizontalContainer>
                    <BaseRadioButton
                      control={formData.control}
                      row
                      name='price'
                      labelid='price'
                      alignItems='flex-start'
                      values={[
                        {
                          value: yearlyPrice?._id,
                          label: <RadioPlanLabel price={yearlyPrice} />
                        },
                        {
                          value: monthlyPrice?._id,
                          label: <RadioPlanLabel price={monthlyPrice} />
                        }
                      ]}
                    />
                  </HorizontalContainer>
                )}
                <CreditCardContainer>
                  <CreditCardForm
                    setValue={setValue}
                    control={formData.control}
                  />
                </CreditCardContainer>
                <HorizontalContainer>
                  <BaseButton
                    id='come-back'
                    onClick={() => navigate(-1)}
                    variant='text'
                  >
                    Voltar Para Planos
                  </BaseButton>
                  <BaseButton
                    id='create-payment'
                    disabled={!formState.isDirty || !formState.isValid}
                    isLoading={isLoading || isLoadingUpdate}
                    type='submit'
                  >
                    Enviar
                  </BaseButton>
                </HorizontalContainer>
              </ContentBox>
              <PaymentResumeBox handleSavePrice={handleSavePrice} price={selectedPrice} />
            </RootBox>
          </PaymentPlanBox>
        )}
        {displayPaymentSuccess && (
          <SuccessSetting
            status='loading'
            title='Cadastro Concluído com Sucesso!'
            description='Estamos dando os toques finais na sua área de trabalho, que estará pronta muito em breve.'
            redirectLink={APP_ENV.FRONTEND_V1_REDIRECT_URL}
            redirectToAfterTimeout={5}
          />
        )}
        {displayPaymentProcessing && (
          <SuccessSetting
            status='processing'
            title={t('processing.title')}
            description={t('processing.description', {
              price: getPrice,
              code
            })}
            textInfo={t('processing.textInfo')}
            redirectLink={APP_ENV.FRONTEND_V1_REDIRECT_URL}
            redirectToAfterTimeout={5}
          />
        )} 
        {displayPaymentError && (
          <SuccessSetting
            status='error'
            title='Sua transação não foi concluída.'
            description='Parece que houve um problema com o seu pagamento. Por favor, verifique
          os detalhes inseridos e tente novamente.'
            buttonLabel='Tentar Novamente'
            buttonLink={() => setDisplayPaymentError(false)}
          />
        )}
      </form>
    </PaymentPlanContainer>
  );
};

export default PaymentPlan;
