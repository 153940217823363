import { Box, Typography, styled } from '@mui/material';

export const PlanLabelContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const PlanTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  color: theme.palette.common.black,
  fontWeight: 600
}));
