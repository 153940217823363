import { Link } from 'react-router-dom';

import { styled } from '@mui/material';

export const PersonalDataStepFooterContainer = styled('p')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '600px',
  justifyContent: 'center',
  textAlign: 'center',
  gap: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const TermsOfServiceLink = styled('p')(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 'bold',
  textDecoration: 'underline',
  textAlign: 'center',
  cursor: 'pointer'
}));

export const SignInLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer'
}));
