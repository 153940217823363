import { RequestResponse } from '~/app/core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '~/app/core/application/protocols/http-client';
import { ServiceCommand } from '~/app/core/domain/command/service-command';
import { error, success } from '~/app/core/domain/either/either';

import { ConsumablesForm } from '../../domain/models/consumable-model';
import { ConsumablesResponse } from '../../domain/models/consumable-response';
import { RootState } from '../../store/consumables-store';
import { consumablesTypesSlice } from '../../store/slice/consumables-slice';

export class Consumables implements ServiceCommand<Consumables.Response> {
  constructor(
    private readonly httpClient: HttpClient<Consumables.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: Consumables.Params
  ): Promise<ServiceCommand.Response<Consumables.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params,
      queryParams: []
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export const {
  addConsumablesType,
  editConsumablesType,
  deleteConsumablesType
} = consumablesTypesSlice.actions;

export const selectConsumablesTypes = (state: RootState) =>
  state.consumablesTypes.consumablesTypes;

export default consumablesTypesSlice.reducer;

export namespace Consumables {
  export type Params = ConsumablesForm;

  export type Response = ConsumablesResponse;
}
