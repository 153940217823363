import { Alert, Box, styled } from '@mui/material';

export const CompanyDataContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: theme.spacing(60),
  [theme.breakpoints.down('sm')]: {
    minWidth: theme.spacing(20)
  },
  gap: theme.typography.pxToRem(30)
}));

export const InfoAlert = styled(Alert)(() => ({
  color: '#7315E5',
  backgroundColor: '#D7CCE5',
  '& .MuiAlert-icon': {
    color: '#7315E5'
  }
}));

export const CNPJAndSectorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.typography.pxToRem(30),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

export const CompanyDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.typography.pxToRem(30)
}));
