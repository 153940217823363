import { TextFieldProps } from '@mui/material';

import { BaseTextFieldStyle } from './base-text-field-styles';

export type BaseTextFieldProps = TextFieldProps;

const BaseTextField = (props: BaseTextFieldProps) => {
  return <BaseTextFieldStyle {...props} />;
};

export default BaseTextField;
