import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import { CacheStorage } from '../../../../../core/application/protocols/cache-storage';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { LoginForm } from '../../domain/models/login-models';
import { TokenModel } from '../../domain/models/token-model';

export class EmailOrUsernameLogin
  implements ServiceCommand<EmailOrUsernameLogin.Response>
{
  constructor(
    private readonly httpClient: HttpClient<EmailOrUsernameLogin.Response>,
    private readonly cacheStorage: CacheStorage,
    private readonly tokenKey: string,
    private readonly url: string
  ) {}

  async execute(
    params: EmailOrUsernameLogin.Params
  ): Promise<ServiceCommand.Response<EmailOrUsernameLogin.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params,
      queryParams: ['accessToken', 'accessToken', 'email']
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    const { accessToken } = response;
    this.cacheStorage.set(this.tokenKey, accessToken);

    return success(response);
  }
}

export const storeEmail = (email: string) =>
  sessionStorage.setItem('forgot-password-email', email);

export const getStoreEmail = () =>
  sessionStorage.getItem('forgot-password-email');

export namespace EmailOrUsernameLogin {
  export type Params = LoginForm;

  export type Response = TokenModel;
}
