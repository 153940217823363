import { makeApiUrl } from '../../../../core/presentation/hooks/make-api-url';

export const AUTH_API_ROUTES = {
  LOGIN: makeApiUrl('auth/login'),
  REFRESH_TOKEN: makeApiUrl('auth/refresh-token'),
  FORGOT_PASSWORD: makeApiUrl('accounts/request-reset-password'),
  RESET_PASSWORD: makeApiUrl('accounts/reset-password'),
  CONSUMABLES: makeApiUrl('/consumables'),
  AUTHORIZATION_URL: makeApiUrl('oauth/authorization-url'),
  GOOGLE_LOGIN: makeApiUrl('oauth/login'),
  LOGIN_BY_LINK: makeApiUrl('auth/validate-login-token'),
  ACCESS_CODE: makeApiUrl('accounts/request-login-by-email'),
  VALIDATE_ACCESSCODE: makeApiUrl('auth/validate-login-token'),
  SIGN_UP: makeApiUrl('accounts/sign-up'),
  VALIDATE_EMAIL_CODE: makeApiUrl('accounts/code/validate'),
  RESEND_VALIDATE_CODE: makeApiUrl('accounts/code/resend'),
  SEND_EMAIL_LINK_FLOW: makeApiUrl('accounts/send-link-flow'),
  CNPJ_LOOKUP: makeApiUrl('companies/cnpj-lookup'),
  CNPJ_VALIDATE: makeApiUrl('companies/verify'),
  CREATE_COMPANY: makeApiUrl('companies'),
  GET_OWNERS: makeApiUrl('companies'),
  ADD_VEHICLES: makeApiUrl('vehicles/batch'),
  CONTACT_SALES: makeApiUrl('enterprise-contact'),
  GET_PLANS: makeApiUrl('plans'),
  GET_SUBSCRIPTION: makeApiUrl('subscriptions'),
  CREATE_SUBSCRIPTION: makeApiUrl('subscriptions'),
  UPDATE_SUBSCRIPTION: makeApiUrl('subscriptions'),
  GET_SUBSCRIPTION_BY_COMPANY_ID: makeApiUrl('subscriptions/company'),
  UPDATE_ME: makeApiUrl('users/me'),
  ME: makeApiUrl('users/me'),
  GET_VEHICLES: makeApiUrl('vehicles/search')
};
