import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';
import Logo from '~/app/core/presentation/components/logo/logo';

import EmailTextField from '../../../../components/email-text-field/email-text-field';
import { ForgotPasswordForm } from '../../../domain/models/forgot-password-model';
import { AuthRoutesPath } from '../../../routes/routes';
import { getStoreEmail } from '../../../services/email-login/email-login';
import {
  BoxTop,
  ContainerBox,
  FormBox,
  RecoverPasswordButton,
  TextForgotPassword
} from './forgot-password-form-styles';

const FormForgotPassword = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation(['recover-password']);
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<ForgotPasswordForm>({
    defaultValues: {
      email: getStoreEmail() || ''
    }
  });

  const returnToPreviousPage = () => {
    navigate(AuthRoutesPath.LOGIN);
  };

  return (
    <FormBox component='form' onSubmit={handleSubmit(onSubmit)}>
      <BoxTop>
        <ArrowBackIcon onClick={returnToPreviousPage} />
        <Logo />
      </BoxTop>
      <ContainerBox>
        <TextForgotPassword>{t('recoverPassword')}</TextForgotPassword>
        <Typography>{t('emailWithInstructions')}</Typography>
        <EmailTextField {...{ control }} />
        <RecoverPasswordButton
          {...{ isLoading }}
          type='submit'
          variant='contained'
          disableElevation
        >
          {t('sendEmail')}
        </RecoverPasswordButton>
      </ContainerBox>
    </FormBox>
  );
};

export default FormForgotPassword;
