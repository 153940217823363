import { memo, Suspense, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';
import { useRegisterUserDataStore } from '~/app/application/features/auth/store/slice/register-user-data-slice';
import { logPageView } from '~/app/application/shared/lib/tag';

import { theme } from '../../../application/shared/styles/theme';
import ToastContainer from '../../../core/presentation/components/toast-container/toast-container';
import { store } from '../../../core/store/store';
import { appRoutes } from '../../config/routes-config';
import { makeRoutes } from '../factory/router-factory';

const config: IConfig = {
  environment: process.env.FEATURE_FLAG_ENV,
  url: process.env.FEATURE_FLAG_PROXY_URL,
  clientKey: process.env.FEATURE_FLAG_CLIENT_KEY,
  refreshInterval: Number(process.env.FEATURE_FLAG_REFRESH_INTERVAL) ?? 15,
  appName: process.env.FEATURE_FLAG_APP_NAME
};

declare global {
  interface Window {
    user_id?: string | null;
    account?: string | null;
  }
}

const Router = () => {
  const { registerState } = useRegisterUserDataStore();

  useEffect(() => {
    logPageView(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    const company = registerState?.companies?.[0];
    window.user_id = undefined;
    window.account = undefined;
    if (company) {
      window.user_id = company?.oldUserId || undefined;
      window.account = company?.oldAccountId || undefined;
    }
  }, [registerState]);

  return (
    <Suspense fallback={<></>}>
      <Helmet>
        <script
          type='text/javascript'
          src='//script.crazyegg.com/pages/scripts/0120/9524.js'
          async={true}
        ></script>
      </Helmet>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <FlagProvider config={config}>
            <BrowserRouter>
              <CssBaseline />
              <ToastContainer />
              {makeRoutes(appRoutes)}
            </BrowserRouter>
          </FlagProvider>
        </ThemeProvider>
      </Provider>
    </Suspense>
  );
};

export default memo(Router);
