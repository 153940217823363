import { useRef } from 'react';
import { Control, Controller } from 'react-hook-form';

import { ButtonProps } from '@mui/material';

import BaseButton from '../base-button/base-button';

type MimeType =
  | 'image/jpeg'
  | 'image/png'
  | 'application/pdf'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'text/csv';

const KILOBYTE = 1024;

type UploadButtonProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  acceptedMimeTypes: MimeType[];
  maxFileSize: number;
  label: string;
  variant?: ButtonProps['variant'];
  onFileUpload?: (data: FileList) => void;
  multiple?: boolean;
};

function UploadFileButton({
  control,
  name,
  acceptedMimeTypes,
  maxFileSize,
  label,
  multiple,
  variant = 'contained',
  onFileUpload
}: UploadButtonProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const maxFileSizeInBytes = maxFileSize * KILOBYTE * KILOBYTE;

  const accept = `${acceptedMimeTypes.join(
    ','
  )}; max-size=${maxFileSizeInBytes}`;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onFileUpload === undefined) return;
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      if (!acceptedMimeTypes.includes(file.type as MimeType)) {
        console.error('Invalid file type. Please select a valid file.');
        return;
      }

      onFileUpload(files);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      render={({ field }) => (
        <label>
          <input
            type='file'
            accept={accept}
            style={{ display: 'none' }}
            multiple={multiple}
            {...field}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <BaseButton
            id='upload-vehicles'
            variant={variant}
            onClick={handleButtonClick}
          >
            {label}
          </BaseButton>
        </label>
      )}
    />
  );
}

export default UploadFileButton;
