import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../application/shared/toast/actions/add-toast';
import { removeToast } from '../../../application/shared/toast/actions/remove-toast';
import { ToastTypes } from '../../../application/shared/toast/types/toast-types';
import { useAppDispatch } from '../../store/store';
import { ToastType } from '../common/types/toast-types';

type PropsToast = {
  text: string;
  type: ToastTypes;
};
export const useToast = () => {
  const { t } = useTranslation(['errors']);
  const dispatch = useAppDispatch();

  const addNewToast = useCallback(
    ({ text, type }: PropsToast) => {
      const toast = {
        type,
        text: t(text)
      };

      dispatch(addToast(toast));
    },
    [dispatch]
  );

  const showSuccess = useCallback(
    (text: string) => addNewToast({ type: ToastType.SUCCESS, text }),
    []
  );

  const showError = useCallback((error: unknown | string) => {
    const text =
      typeof error === 'string'
        ? error
        : (error as { message: string }).message;
    return addNewToast({ type: ToastType.ERROR, text });
  }, []);

  const showInfo = useCallback(
    (text: string) => addNewToast({ type: ToastType.INFO, text }),
    []
  );

  const deleteToast = useCallback(
    (id: string) => {
      dispatch(removeToast({ id }));
    },
    [dispatch]
  );

  return {
    addToast: addNewToast,
    removeToast: deleteToast,
    showSuccess,
    showError,
    showInfo
  };
};
