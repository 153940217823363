import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { Skeleton, TextFieldProps } from '@mui/material';

import BaseTextField from '../../../../core/presentation/components/base-text-field/base-text-field';

export type WhatsappTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
  isLoading?: boolean;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CellWhatsappMask = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, _) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        mask={['(00) 00000-0000', '(00) 0000-0000']}
        dispatch={(appended, dynamicMasked) => {
          console;
          const number = (dynamicMasked.value + appended).replace(/\D/g, '');
          if (number.length > 10) {
            return dynamicMasked.compiledMasks[0];
          }
          return dynamicMasked.compiledMasks[1];
        }}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        definitions={{
          '#': /[1-9]/
        }}
        {...other}
      />
    );
  }
);

const WhatsappTextField = (props: WhatsappTextFieldProps) => {
  const { t } = useTranslation(['components', 'errors']);
  return (
    <Controller
      name='whatsapp'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {props.isLoading ? (
            <Skeleton variant='rectangular' height={56} />
          ) : (
            <BaseTextField
              onChange={onChange}
              value={value || ''}
              error={!!error}
              helperText={
                error
                  ? props.errorText ?? t('errors:WhatsappInvalid')
                  : undefined
              }
              id='whatsapp'
              name='whatsapp'
              label={t('Whatsapp')}
              variant='filled'
              type='whatsapp'
              required
              inputProps={{ maxLength: 256, shrink: value }}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: CellWhatsappMask as any
              }}
              {...props}
            />
          )}
        </>
      )}
    />
  );
};

export default WhatsappTextField;
