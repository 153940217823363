import { ServiceCommand } from '~/app/core/domain/command/service-command';
import { httpClient } from '~/app/core/infra/http/axios-http-client-adapter';

import { AUTH_API_ROUTES } from '../../api/routes';
import { AccessCode } from './access-code-login';
import { ValidateAccessCode } from './validate-access-code-login';

export const accessCodeService: ServiceCommand<AccessCode.Response> =
  new AccessCode(httpClient, AUTH_API_ROUTES.ACCESS_CODE);

export const validadeAccessCodeService: ServiceCommand<ValidateAccessCode.Response> =
  new ValidateAccessCode(httpClient, AUTH_API_ROUTES.VALIDATE_ACCESSCODE);

export type { AccessCode, ValidateAccessCode };
