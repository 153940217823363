import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { ToastSlice } from '../../application/shared/toast/slice/toast-slice';
import { toastSliceName } from '../../application/shared/toast/types/toast-constants';
import { apiSlice } from './api-slice';
import { type AppDispatch as AppDispatchTypes, type RootState } from './types';

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<(typeof store)['getState']>;

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [toastSliceName]: ToastSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware)
});

setupListeners(store.dispatch);

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatchTypes>();
