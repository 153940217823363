import { Box, Typography, styled } from '@mui/material';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';

export const Badge = styled(Box)<{ backgroundcolor: string }>(
  ({ backgroundcolor: backgroundColor }) => ({
    position: 'absolute',
    backgroundColor,
    top: '-16px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: 'white',
    padding: '4px 32px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    fontWeight: '900',
    borderRadius: '40px'
  })
);

export const PlanCardContainer = styled(Box)<{ borderColor: string }>(
  ({ theme, borderColor }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    border: `${theme.typography.pxToRem(2)} solid ${borderColor}`,
    borderRadius: theme.typography.pxToRem(8),
    maxWidth: theme.typography.pxToRem(384),
    minWidth: theme.typography.pxToRem(340),
    position: 'relative'
  })
);

export const PriceContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

export const Title = styled(Typography)(() => ({
  fontWeight: '700',
  fontStyle: 'normal',
  lineHeight: '100%',
  fontSize: '40px'
}));

export const PriceText = styled(Typography)(() => ({
  fontWeight: '700',
  fontStyle: 'normal',
  lineHeight: '150%',
  fontSize: '24px'
}));

export const Description = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'normal',
  fontStyle: 'normal',
  lineHeight: '150%',
  color: '#525252',
  marginBottom: theme.spacing(2),
  fontSize: '14px'
}));

export const Observation = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'normal',
  fontStyle: 'normal',
  lineHeight: '150%',
  color: '#525252',
  marginBottom: theme.spacing(2),
  fontSize: '14px'
}));

export const PlanButton = styled(BaseButton)<{
  backgroundColor?: string;
}>(({ backgroundColor }) => ({
  color: backgroundColor,
  borderColor: backgroundColor,
  '&:hover': {
    borderColor: backgroundColor
  }
}));
