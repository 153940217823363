import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Logo from '~/app/core/presentation/components/logo/logo';

import { VehicleData } from '../vehicle-step';
import AddPlatesList from './add-plates-list/add-plates-list';
import { AddPlatesButton } from './add-plates-list/add-plates-list-styles';
import { PlateListValidator } from './add-plates-list/validators/plates-list.validator';
import {
  BackAndLogoBox,
  ContentBox,
  LogoBox,
  PlatesBox,
  Title,
  VehicleAddManualBox
} from './vehicle-add-manual-styles';

export type VehiclesList = { plate: string; renavam?: string };

export type ManualVehicleData = {
  vehicles: VehiclesList[];
};

type VehicleAddManualProps = {
  handleClose: () => void;
  onManualInput: (vehiclesData: VehicleData[]) => void;
  vehicles?: VehiclesList[];
};

const VehicleAddManual = ({
  handleClose,
  onManualInput,
  vehicles
}: VehicleAddManualProps) => {
  const { t } = useTranslation('register-vehicle-step');
  const [displayError, setDisplayError] = useState(false);

  const [displayDuplicateRenavamError, setDisplayDuplicateRenavamError] =
    useState(false);

  const handleDataVeicles = () => {
    const data = getValues();
    const mappedVehicles = data.vehicles.map(({ plate, renavam }) => ({
      placa: plate,
      ...(renavam !== '' && { renavam })
    }));

    onManualInput(mappedVehicles);
    handleClose();
  };

  const formData = useForm({
    resolver: PlateListValidator,
    defaultValues: { vehicles },
    mode: 'onChange'
  });
  const { watch, getValues } = formData;

  const watchedVehicles = watch('vehicles');

  const validateDuplicates = useCallback(() => {
    const vehiclePlates = watchedVehicles.map((vehicle) => vehicle.plate);
    const uniquePlates = new Set(vehiclePlates);
    const vehicleRenavams = watchedVehicles.filter(
      (vehicle) => vehicle.renavam !== ''
    );

    const uniqueRenavams =
      new Set(vehicleRenavams).size !== vehicleRenavams.length;

    if (uniqueRenavams) {
      setDisplayDuplicateRenavamError(true);
    } else {
      setDisplayDuplicateRenavamError(false);
    }

    const hasErrors =
      displayError || vehiclePlates.length !== uniquePlates.size;

    return {
      hasErrors
    };
  }, [watchedVehicles]);

  useEffect(() => {
    validateDuplicates();
  }, [validateDuplicates]);

  useEffect(() => {
    setDisplayError(watchedVehicles.length < 5);
  }, [watchedVehicles]);

  return (
    <form>
      <VehicleAddManualBox>
        <BackAndLogoBox>
          <IconButton onClick={handleClose}>
            <ArrowBack />
          </IconButton>
          <LogoBox>
            <Logo />
          </LogoBox>
        </BackAndLogoBox>
        <ContentBox>
          <Title>{t('manualAddScreen.title')}</Title>
          <PlatesBox>
            <AddPlatesList
              displayDuplicateRenavamError={displayDuplicateRenavamError}
              displayError={displayError}
              {...formData}
            />
          </PlatesBox>
          <AddPlatesButton
            id='add-plates'
            disabled={displayError}
            onClick={handleDataVeicles}
          >
            {t('manualAddScreen.addPlates')}
          </AddPlatesButton>
        </ContentBox>
      </VehicleAddManualBox>
    </form>
  );
};

export default VehicleAddManual;
