import { csvParser } from './csv-parser';
import { xlsxParser } from './xlsx-parser';

const XLXS_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const CSV_MIME_TYPE = 'text/csv';

export const fileParser = async <T>(file: File): Promise<T[]> => {
  if (file.type === XLXS_MIME_TYPE) {
    const data = await readFileAsArrayBuffer(file);
    return xlsxParser<T>(data);
  } else if (file.type === CSV_MIME_TYPE) {
    const text = await readFileAsText(file);
    return csvParser<T>(text);
  }
  return [];
};

const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result as ArrayBuffer;
      resolve(data);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsArrayBuffer(file);
  });
};

const readFileAsText = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result as string;
      resolve(text);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsText(file);
  });
};
