import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';

export class SendEmailLinkFlow
  implements ServiceCommand<SendEmailLinkFlow.Response>
{
  constructor(
    private readonly httpClient: HttpClient<SendEmailLinkFlow.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: SendEmailLinkFlow.Params
  ): Promise<ServiceCommand.Response<SendEmailLinkFlow.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }

    const response = responseOrError.value.response;
    return success(response);
  }
}

export namespace SendEmailLinkFlow {
  export type Params = { email: string };
  export type Response = { success: true };
}
