import React from 'react';
import { Controller, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AlertTitle, IconButton, InputAdornment } from '@mui/material';
import Logo from '~/app/core/presentation/components/logo/logo';

import BaseAlert from '../../../../../../core/presentation/components/base-alert/base-alert';
import {
  BoxRequeriments,
  Button,
  FormBox,
  LogoContainer,
  TextFieldStyle,
  TextResetPassword
} from './form-reset-password-styles';
import { PasswordFormValidation } from './form-validation/form-validation';
import PasswordRequirements from './form-validation/password-requirements';

interface Form {
  password: string;
  confirmPassword: string;
}

const FormResetPassword = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation(['reset-password']);
  const [showPassword, setShowPassword] = React.useState(false);
  const { control, handleSubmit, formState } = useForm<Form>({
    defaultValues: {
      password: '',
      confirmPassword: ''
    },
    resolver: PasswordFormValidation
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const notCopyAndPaste = (e) => {
    e.preventDefault();
  };

  const invalid: SubmitErrorHandler<Form> = () => {
    return (
      <BaseAlert severity='error'>
        <AlertTitle>Error</AlertTitle>
        {t('error')}
      </BaseAlert>
    );
  };

  return (
    <FormBox component='form' onSubmit={handleSubmit(onSubmit, invalid)}>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <TextResetPassword>{t('createNewPassword')}</TextResetPassword>
      <Controller
        name='password'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <BoxRequeriments>
            <TextFieldStyle
              onChange={onChange}
              value={value}
              error={!!error}
              id='password'
              name='password'
              label={t('password')}
              variant='standard'
              type={showPassword ? 'text' : 'password'}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label={t('showPassword')}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {value && <PasswordRequirements password={value} />}
          </BoxRequeriments>
        )}
      />

      <Controller
        name='confirmPassword'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextFieldStyle
            onChange={onChange}
            value={value}
            error={!!error}
            id='confirmPassword'
            helperText={error ? t('error') : undefined}
            name='confirmPassword'
            label={t('confirmPassword')}
            variant='standard'
            type={showPassword ? 'text' : 'password'}
            onCopy={notCopyAndPaste}
            onPaste={notCopyAndPaste}
            onCut={notCopyAndPaste}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label={t('showPassword')}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
      />
      <Button
        {...{ isLoading }}
        type='submit'
        disabled={!formState.isDirty || !formState.isValid}
        variant='contained'
        disableElevation
      >
        {t('save')}
      </Button>
    </FormBox>
  );
};

export default FormResetPassword;
