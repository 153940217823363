import {
  authRoutes,
  plansInfosRoutes,
  registerRoutes,
  upgradePlanRoutes
} from '../../application/features/auth/routes/routes';
import { generalRoutes } from '../../application/features/general/routes/routes';

export const appRoutes = [
  ...generalRoutes,
  ...authRoutes,
  ...registerRoutes,
  ...upgradePlanRoutes,
  ...plansInfosRoutes
];
