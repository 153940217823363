import { Alert, styled } from '@mui/material';

export const BaseAlertStyles = styled(Alert)(() => ({
  '&.MuiAlert-standardInfo': {
    color: '#691EDC',
    backgroundColor: '#D5CCE3',
    '& .MuiAlert-icon': {
      color: '#691EDC'
    }
  }
}));
