import React, { SyntheticEvent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextFieldProps, IconButton, InputAdornment } from '@mui/material';

import BaseTextField from '../../../../core/presentation/components/base-text-field/base-text-field';

export type PasswordTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  apiError?: boolean;
  errorText?: string;
  enablePaste?: boolean;
};

const PasswordTextField = (props: PasswordTextFieldProps) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const notCopyAndPaste = (e: SyntheticEvent) => {
    if (!props.enablePaste) {
      e.preventDefault();
      return;
    }
  };
  const { t } = useTranslation(['login']);
  return (
    <Controller
      name='password'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseTextField
          onChange={onChange}
          value={value || ''}
          error={!!error}
          helperText={
            error || props.apiError
              ? props.errorText ?? t('passwordInvalid')
              : undefined
          }
          id='password'
          name='password'
          label={t('password')}
          variant='filled'
          type={showPassword ? 'text' : 'password'}
          onCopy={notCopyAndPaste}
          onPaste={notCopyAndPaste}
          onCut={notCopyAndPaste}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label={t('showPassword')}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};

export default PasswordTextField;
