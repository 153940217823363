import { Link } from 'react-router-dom';

const LinkMailTo = ({ mailto, label }: { mailto: string; label: string }) => {
  return (
    <Link
      to='#'
      onClick={(e) => {
        window.location.href = `mailto:${mailto}`;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export default LinkMailTo;
