import { Link } from "react-router-dom";
import { PlansRoutes } from "../../routes/routes";
import { ContainerPlanInfo, MainContainer,SecondTitle, TableContainer } from "./plan-info-sales-style";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createPlans } from "./plans-list";
import PlanDetailsTable from "../../../components/plan-details-sales-table/plan-details-table";

const PlansInfoSales = () => {
    const { t, i18n } = useTranslation('register-plan');
    const [isLoading, setIsLoading] = useState(false);
    
    const plansForTable = createPlans();

    return (
        <>
            <ContainerPlanInfo>
                <MainContainer>
                    <TableContainer>
                    {!isLoading && <SecondTitle>{t('choose_plan')}</SecondTitle>}
                    <PlanDetailsTable
                        plansData={plansForTable}
                        isLoading={isLoading}
                    />
                    </TableContainer>
                </MainContainer>
            </ContainerPlanInfo>
        </>
    );
};

export default PlansInfoSales;
