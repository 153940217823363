export const carQuantity = [
  {
    value: '5 a 10',
    label: '5 a 10'
  },
  {
    value: '11 a 40',
    label: '11 a 40'
  },
  {
    value: 'Acima de 40',
    label: 'Acima de 40'
  }
];
