import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import Logo from '~/app/core/presentation/components/logo/logo';

import { AuthRoutesPath } from '../../auth/routes/routes';
import {
  LinkStyled,
  ButtonStyled,
  DesktopMenu,
  MobileMenu,
  Container,
  ToolbarStyle,
  InicialLink,
  SigninLink,
  IconButtonMobile,
  LogoLink,
  AuthButtonsContainer,
  CenterLinks
} from './presentation-menu-styles';

const PresentationMenu = () => {
  const homePage = 'https://frota162.com.br';
  const menuItems: { label: string; link: string }[] = [
    { label: 'platform', link: `${homePage}/plataforma/` },
    { label: 'aboutUs', link: `${homePage}/sobre-nos/` },
    { label: 'blog', link: `${homePage}/blog/` },
    { label: 'contact', link: `${homePage}/contato/` }
  ];
  const { t } = useTranslation(['presentation-menu']);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const redirect = (event) => {
    event.preventDefault();
    navigate(AuthRoutesPath.LOGIN);
  };

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <Container>
      <ToolbarStyle>
        <DesktopMenu>
          <LogoLink to='#'>
            <Logo />
          </LogoLink>
          <CenterLinks>
            <InicialLink to={homePage}>{t('home')}</InicialLink>
            {menuItems.map((item) => (
              <LinkStyled key={item.label} to={item.link}>
                {t(item.label)}
              </LinkStyled>
            ))}
          </CenterLinks>
          <AuthButtonsContainer>
            <ButtonStyled variant='contained' disableTouchRipple>
              {t('register')}
            </ButtonStyled>
            <SigninLink onClick={redirect} to={AuthRoutesPath.LOGIN}>
              {t('signIn')}
            </SigninLink>
          </AuthButtonsContainer>
        </DesktopMenu>

        <MobileMenu>
          <LogoLink to='#'>
            <Logo />
          </LogoLink>
          <IconButtonMobile onClick={handleMenuToggle}>
            <MenuIcon />
          </IconButtonMobile>
          {/* TODO: Não foi passado como seria o menu do mobile */}
          {/* <DrawerMobile
            anchor='right'
            open={isMenuOpen}
            onClose={handleMenuToggle}
          >
            <List>
              <ListItem>{t('home')}</ListItem>
              <ListItem>{t('platform')}</ListItem>
              <ListItem>{t('aboutUs')}</ListItem>
              <ListItem>{t('blog')}</ListItem>
              <ListItem>{t('contact')}</ListItem>
              <ListItem>
                <ListItemMenu>{t('register')}</ListItemMenu>
              </ListItem>
              <ListItem>
                <ListItemMenu onClick={redirect}>{t('signIn')}</ListItemMenu>
              </ListItem>
            </List>
          </DrawerMobile> */}
        </MobileMenu>
      </ToolbarStyle>
    </Container>
  );
};

export default PresentationMenu;
