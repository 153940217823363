import {
  CardCurrentPlanContainer,
  CardCurrentPlanDescription,
  CardCurrentPlanTitle
} from './upgrade-plan-styles';

interface Props {
  title: string;
  description: string;
}

const CardCurrentPlan = ({ title, description }: Props) => {
  return (
    <CardCurrentPlanContainer>
      <div>
        <img src='/assets/images/current-plan-icon.svg' />
      </div>
      <div>
        <CardCurrentPlanTitle>{title}</CardCurrentPlanTitle>
        <CardCurrentPlanDescription>{description}</CardCurrentPlanDescription>
      </div>
    </CardCurrentPlanContainer>
  );
};
export default CardCurrentPlan;
