/* eslint-disable @typescript-eslint/no-explicit-any */
export interface HttpClient<R = any, T = any> {
  request: (params: HttpRequest<T, R>) => Promise<HttpResponse<R>>;
}

export type HttpRequest<T = any, R = any> = {
  body?: T;
  headers?: Record<string, string>;
  queryParams?: Record<string, string> | Array<keyof R>;
  method: HttpMethod;
  url: string;
};

export type HttpResponse<T = any> = HttpSuccess<T> | HttpError;

export type HttpSuccess<T = any> = {
  body: T;
  error?: never;
  message?: never;
  statusCode: HttpStatusCode;
};

export type HttpError = {
  body: never;
  error?: any;
  message: string;
  statusCode: HttpStatusCode;
};

export enum HttpMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT'
}

export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  SERVER_ERROR = 500
}

export enum HttpErrorsType {
  INVALID_CREDENTIALS_EXCEPTION = 'Invalid credentials',
  USER_ALREADY_EXISTS_EXCEPTION = 'User already exists.',
  BLOCKED_USER_EXCEPTION = 'Blocked user!'
}
