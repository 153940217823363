import { RequestResponse } from '../../../../../core/application/http-response/http-response';
import {
  HttpClient,
  HttpMethod
} from '../../../../../core/application/protocols/http-client';
import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { error, success } from '../../../../../core/domain/either/either';
import { ForgotPasswordForm } from '../../domain/models/forgot-password-model';
import { ForgotPasswordResponse } from '../../domain/models/forgot-password-response-model';

export class Password implements ServiceCommand<Password.Response> {
  constructor(
    private readonly httpClient: HttpClient<Password.Response>,
    private readonly url: string
  ) {}

  async execute(
    params: Password.Params
  ): Promise<ServiceCommand.Response<Password.Response>> {
    const httpResponse = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.url,
      body: params,
      queryParams: []
    });

    const responseOrError = RequestResponse.handle(httpResponse);

    if (responseOrError.isError()) {
      return error(responseOrError.value);
    }
    const response = responseOrError.value.response;

    return success(response);
  }
}

export namespace Password {
  export type Params = ForgotPasswordForm;

  export type Response = ForgotPasswordResponse;
}
