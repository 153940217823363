import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    marginTop: 20
  }
}));

export const RightContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: theme.spacing(60),
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const LeftContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100%'
}));
